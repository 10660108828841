<template>
  <div id="app-body">
    <div v-if="!isNoData" class="container-fluid">
      <div class="title-header">
        <h2>{{ categoryName }}</h2>
        <p>{{ $t("restaurantBody") }}</p>
      </div>
      <div class="row row-cols-1 row-cols-md-3 g-4 row-card">
        <SpecialOfferCard
          @clickOnCard="pushToRestaurantPage(restaurant.id, restaurant.name)"
          v-for="restaurant in restaurantData"
          :key="restaurant.id"
          :deliveryFee="restaurant.delivery_price"
          :discountUpTo="restaurant.discount_up_to"
          :previewImages="restaurant.preview_images[0].source"
          :logo="restaurant.logo"
          :restaurantName="restaurant.name"
          :address="restaurant.address"
        />
      </div>
    </div>
    <!-- Handle on No data -->
    <div v-else>
      <NoDataFound />
    </div>
  </div>
</template>

<script>
import NoDataFound from "../components/NoDataFound.vue";
import SpecialOfferCard from "../components/SpecialOfferCard.vue";
import PushToRestaurantPage from "../mixins/pushToRestaurantPage.js";
import UserSerivce from "../services/user.service";
export default {
  components: { SpecialOfferCard, NoDataFound },
  name: "RestaurantByCategory",
  data() {
    return {
      restaurantData: [],
      isNoData: false,
      categoryName: "",
    };
  },
  mixins: [PushToRestaurantPage],
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.categoryName = this.$route.params.categoryName;
      this.getRestaurantList(this.$route.params.categoryId);
    }
  },
  methods: {
    getRestaurantList(categoryId) {
      this.$Progress.start();
      UserSerivce.getRestaurantByCategory(categoryId).then((response) => {
        this.restaurantData = response.data.data;
        this.isNoData = false;
        this.$Progress.finish();
      });
    },
  },
};
</script>

<style scoped>
.title-header {
  padding: 20px;
}
.row-card {
  padding: 0 20px;
}
</style>
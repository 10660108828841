import axios from 'axios'
import authHeader from './auth-header'

const API_CUSTOMER_URL = process.env.VUE_APP_BASE_CUSOTMER_API_URL;
const API_URL = process.env.VUE_APP_BASE_API_URL;

class UserService {
    // GET Methods
    getHomeScreen() {
        return axios.get(API_CUSTOMER_URL + 'home', { headers: authHeader() })
    }

    getShoppingCartList() {
        return axios.get(API_CUSTOMER_URL + 'cart-list', { headers: authHeader() })
    }

    getRestaurantList() {
        return axios.get(API_CUSTOMER_URL + 'restaurant', { headers: authHeader() })
    }

    getUserProfile() {
        return axios.get(API_CUSTOMER_URL + 'profile', { headers: authHeader() })
    }

    getAnnouncementList() {
        return axios.get(API_CUSTOMER_URL + 'announcement', { headers: authHeader() })
    }

    getBiddingList(restaurantId) {
        if (restaurantId) {
            return axios.get(API_CUSTOMER_URL + 'bidding?id=' + restaurantId, { headers: authHeader() })
        } else {
            return axios.get(API_CUSTOMER_URL + 'bidding', { headers: authHeader() })
        }
    }

    getPromotionList() {
        return axios.get(API_CUSTOMER_URL + 'promotion', { headers: authHeader() })
    }

    getRestaurantDetail(shopId) {
        return axios.get(API_CUSTOMER_URL + 'restaurant/show/' + shopId, { headers: authHeader() })
    }

    getProductListBySection(shopId) {
        return axios.get(API_CUSTOMER_URL + 'product/' + shopId + '/sections2', { headers: authHeader() })
    }

    getProductDetail(shopId, productId) {
        return axios.get(API_CUSTOMER_URL + 'product/' + shopId + '/show/' + productId, { headers: authHeader() })
    }

    getSavedAddressList() {
        return axios.get(API_CUSTOMER_URL + 'address', { headers: authHeader() })
    }

    getOrderStatusList(pageNumber) {
        return axios.get(API_CUSTOMER_URL + 'order?status-where-in=0,1,2&page=' + pageNumber, { headers: authHeader() })
    }

    getOrderHistoryList(pageNumber) {
        return axios.get(API_CUSTOMER_URL + 'order?status-where-in=-1,3&page=' + pageNumber, { headers: authHeader() })
    }

    getOrderDetail(ordreId) {
        return axios.get(API_CUSTOMER_URL + 'order/' + ordreId, { headers: authHeader() })
    }

    getInvoiceDetail(customerId, invoiceNum) {
        return axios.get(API_URL + 'invoice?customer_id=' + customerId + '&invoice_num=' + invoiceNum, { headers: authHeader() })
    }

    getOwnBidded() {
        return axios.get(API_CUSTOMER_URL + 'own_bidded', { headers: authHeader() })
    }

    getOwnBiddedByBidding(biddingId) {
        return axios.get(API_CUSTOMER_URL + 'own_bidded_by_bidding/' + biddingId, { headers: authHeader() })
    }

    getNewArrivalRestaurant() {
        return axios.get(API_CUSTOMER_URL + 'new_arrivals', { headers: authHeader() })
    }

    getMostOrderRestaurant() {
        return axios.get(API_CUSTOMER_URL + 'most_orders', { headers: authHeader() })
    }

    getCategory() {
        return axios.get(API_CUSTOMER_URL + 'categories', { headers: authHeader() })
    }

    getRestaurantByCategory(categoryId) {
        return axios.get(API_CUSTOMER_URL + 'restaurant_by_category?id=' + categoryId, { headers: authHeader() })
    }

    getInvoiceMerchant(orderNumber) {
        return axios.get(API_URL + 'invoice_merchant?order_number=' + orderNumber, { headers: authHeader() })
    }

    // getProductDetailQrCode(code) {
    //     return axios.get(API_URL + 'qr_product?code=' + code, { headers: authHeader() })
    // }

    getProductDetailQrCode(code) {
        return axios.get(API_URL + 'qr_product?code=' + code, { headers: authHeader() })
    }

    getMostOrderProduct() {
        return axios.get(API_CUSTOMER_URL + 'most-order-product/1', { headers: authHeader() })
    }

    getPromotionProduct() {
        return axios.get(API_CUSTOMER_URL + 'promotion-product/1', { headers: authHeader() })
    }

    getLuckyDrawPrice(code, phone) {
        return axios.get(API_CUSTOMER_URL + 'lucky_draw_event/' + code + '?phone_number=' + phone, { headers: authHeader() })
    }

    getProductSection() {
        return axios.get(API_CUSTOMER_URL + 'product_v2/sections?restaurant_id=1', { headers: authHeader() })
    }

    // DEL Methods
    deleteCart(cartItemId) {
        return axios.delete(API_CUSTOMER_URL + 'delete-cart/' + cartItemId, { headers: authHeader() })
    }

    deleteAllCurrentCart() {
        return axios.delete(API_CUSTOMER_URL + 'clear-cart', { headers: authHeader() })
    }

    deleteBidded(biddedId) {
        return axios.delete(API_CUSTOMER_URL + 'bidding/' + biddedId, { headers: authHeader() })
    }

    deleteSavedAddress(addressId) {
        return axios.delete(API_CUSTOMER_URL + 'address/delete/' + addressId, { headers: authHeader() })
    }

    // POST Methods
    postAddToCart(formData) {
        return axios.post(API_CUSTOMER_URL + 'add-to-cart', formData, { headers: authHeader() })
    }

    postUpdateCart() {
        return axios.post(API_CUSTOMER_URL + 'update-cart/', { headers: authHeader() })
    }

    postUpdateQtyCart(cartItemId, formData) {
        return axios.post(API_CUSTOMER_URL + 'update-qty-cart/' + cartItemId, formData, { headers: authHeader() })
    }

    postUpdateProfile(formData) {
        return axios.post(API_CUSTOMER_URL + 'profile-update', formData, { headers: authHeader() })
    }

    postPlaceOrders(formData) {
        return axios.post(API_CUSTOMER_URL + 'place-orders', formData, { headers: authHeader() })
    }

    postGetDeliveryFee(formData) {
        return axios.post(API_CUSTOMER_URL + 'deliveryFee', formData, { headers: authHeader() })
    }

    postCreateBid(formData) {
        return axios.post(API_CUSTOMER_URL + 'bidding', formData, { headers: authHeader() })
    }

    postUpdateBid(biddedId, formData) {
        return axios.post(API_CUSTOMER_URL + 'bidding/' + biddedId + '?_method=PUT', formData, { headers: authHeader() })
    }

    postStoreAddress(formData) {
        return axios.post(API_CUSTOMER_URL + 'address/store', formData, { headers: authHeader() })
    }

    postUpdateAddress(addressId, formData) {
        return axios.post(API_CUSTOMER_URL + 'address/update/' + addressId, formData, { headers: authHeader() })
    }

    postCancelOrder(orderId, formData) {
        return axios.post(API_CUSTOMER_URL + 'order/cancel/' + orderId, formData, { headers: authHeader() })
    }
}

export default new UserService();
import { createI18n } from 'vue-i18n'
import en from '../locale/en.json'
import kh from '../locale/kh.json'

const i18n = createI18n({
    legacy: false,
    locale: (localStorage.getItem('lang') || 'en'),
    globalInjection: true,
    messages: {
        en,
        kh,
    }
})

export default i18n;

<template>
  <div @click="$emit('cardClicked')" class="category-card">
    <div class="background-container">
      <img :src="backgroundImage" alt="" />
    </div>
    <div class="text-overlay">
      <div class="category-name">
        <p v-if="this.$i18n.locale === 'en'">{{ name }}</p>
        <p v-else-if="!nameKh">{{ name }}</p>
        <p v-else>{{ nameKh }}</p>
      </div>
      <p v-if="countRestaurant" class="num-of-restaurant">
        {{ countRestaurant }} {{ $t("restaurantTitle") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryCard",
  props: {
    backgroundImage: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    nameKh: {
      type: String,
      default: "",
    },
    countRestaurant: {
      type: Number,
      default: "",
    },
  },
  emits: ["cardClicked"],
};
</script>

<style scoped>
.category-card {
  margin: 20px 10px;
  padding: 0;
  background-color: #fff;
  border-radius: 16px;
  position: relative;
  transition: 0.1s;
}
.category-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.1s;
  cursor: pointer;
  color: #95C125;
}
.background-container img {
  width: 100%;
  height: 16vh;
  object-fit: cover;
  border-radius: 16px;
}
.text-overlay {
  padding: 15px;
  position: absolute;
  bottom: 0;
}
.text-overlay p {
  margin: 0;
}
.category-name {
  font-size: 20px;
  font-weight: 600;
}
</style>
<template>
  <div class="col">
    <div @click="$emit('cardClicked')" class="product-card">
      <div class="product-img-container">
        <img :src="productImage" alt="" />
        <!-- discount circle badge -->
        <div v-if="discount" class="discount-badge">
          <p>{{ discount }}</p>
        </div>
        <!-- pre order badge -->
        <div v-if="!preOrderBadge" class="pre-ordre-badge">
          <p>{{ $t("preOrderLabel") }}</p>
        </div>
      </div>
      <div class="product-detail">
        <div class="product-name">
          <p v-if="this.$i18n.locale === 'en'">{{ productName }}</p>
          <p v-else-if="!productNameKh">{{ productName }}</p>
          <p v-else>{{ productNameKh }}</p>
        </div>
        <div v-show="quantity">
          <p class="product-qty">{{ quantity }}{{ unitDisplayType }} | Unit</p>
        </div>
        <div class="price-row">
          <p class="discounted-price">$ {{ parseFloat(price).toFixed(2) }}</p>
          <p v-show="fullPrice != price" class="full-price">
            $ {{ parseFloat(fullPrice).toFixed(2) }}
          </p>
        </div>
        <p v-if="preOrderDiscount" class="pre-order-text">
          {{ $t("preOrderLabelText") }} {{ preOrderDiscount }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    productImage: String,
    productName: {
      type: String,
      default: "Product Name",
    },
    productNameKh: {
      type: String,
      default: null,
    },
    price: {
      type: String,
      default: "0.0",
    },
    fullPrice: {
      type: String,
      default: "",
    },
    quantity: {
      type: Number,
      default: "",
    },
    unitDisplayType: {
      type: String,
      default: "",
    },
    preOrderDiscount: {
      type: String,
      default: "",
    },
    discount: {
      type: String,
      default: "",
    },
    preOrderBadge: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cardClicked"],
};
</script>

<style scoped>
.product-card {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 22px;
  transition: 0.3s;
}
.product-card:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.3s;
}
.product-img-container {
  position: relative;
}
.product-img-container img {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: #d5d5d5;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  object-fit: cover;
}
.discount-badge {
  padding: 2px 10px;
  background-color: #95C125;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ffffff;
  position: absolute;
  top: 10px;
  right: 10px;
}
.pre-ordre-badge {
  width: auto;
  padding: 5px 10px;
  border-radius: 22px;
  background-color: #95C125;
  font-size: 14px;
  color: #ffffff;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
p {
  margin: 0;
}
.product-detail {
  margin: 10px;
}
.product-name {
  font-size: 18px;
  font-weight: 600;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.discounted-price {
  font-size: 18px;
  color: #ff5252;
  margin-right: 15px;
}
.pre-order-text {
  color: #006633;
}
.price-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.full-price {
  text-decoration: line-through;
  color: #707070;
}
.product-qty {
  text-transform: lowercase;
}
</style>
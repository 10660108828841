<template>
  <div id="app-body">
    <div class="container-fluid">
      <div class="title-header">
        <h2>{{ $t("restaurantTitle") }}</h2>
        <p>{{ $t("restaurantBody") }}</p>
      </div>
      <div class="row row-cols-1 row-cols-md-3 g-4 row-card">
        <SpecialOfferCard
          v-for="restaurant in restaurants"
          :key="restaurant.id"
          @clickOnCard="pushToRestaurantPage(restaurant.id, restaurant.name)"
          :deliveryFee="restaurant.delivery_price"
          :discountUpTo="restaurant.discount_up_to"
          :previewImages="restaurant.preview_images[0].source"
          :logo="restaurant.logo"
          :restaurantName="restaurant.name"
          :address="restaurant.address"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserSerivce from "../services/user.service";
import PushToRestaurantPage from "../mixins/pushToRestaurantPage.js";
import SpecialOfferCard from "../components/SpecialOfferCard.vue";
export default {
  name: "ShopList",
  components: { SpecialOfferCard },
  mixins: [PushToRestaurantPage],
  data() {
    return {
      restaurants: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      UserSerivce.getRestaurantList().then((response) => {
        this.restaurants = response.data.data;
        console.log(this.restaurants);
      });
    }
  },
  methods: {},
};
</script>

<style scoped>
.title-header {
  padding: 20px;
}
.row-card {
  padding: 0 20px;
}
</style>
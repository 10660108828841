import axios from 'axios';

const API_URL = process.env.VUE_APP_BASE_CUSOTMER_API_URL

class AuthService {

    login(inPhoneNum) {
        return axios.post(API_URL + 'login', {
            phone: inPhoneNum
        }).then((response) => {
            this.responseData = response.data.data
        })
    }

    verifyOtp() {
        return axios.post(API_URL + 'store/pin/' + this.responseData.customer_id, {
            pin: '1111',
        }).then(response => {
            if (response.data.data.access_token) {
                localStorage.setItem('user', JSON.stringify(response.data.data))
            }

            return response.data.data;
        })
    }

    logout() {
        localStorage.removeItem('user')
    }

    register(formData) {
        return axios.post(API_URL + 'register', formData)
    }
}

export default new AuthService();
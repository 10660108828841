<template>
  <nav class="navbar fixed-top">
    <div class="container-fluid nav-wrapper">
      <div class="logo-container">
        <router-link to="/" class="navbar-brand">Davane</router-link>
        <div class="search-bar-container">
          <div class="search-icon">
            <unicon name="search" fill="#707070" />
          </div>
          <form action="">
            <input
              class="search-bar"
              type="text"
              :placeholder="$t('searchPlaceHolder')"
            />
          </form>
        </div>
      </div>
      <div class="action-button-container">
        <!-- change language button -->
        <div @click="changeLocale()" class="action-button">
          <div class="nav-btn">
            <p class="lang-icon">{{ langIcon }}</p>
          </div>
        </div>
        <!-- my bidding button -->
        <router-link to="/my-bidding">
          <div class="action-button">
            <div class="nav-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g
                  id="auction-law-svgrepo-com"
                  transform="translate(11.769 -20.031)"
                >
                  <g
                    id="Group_4"
                    data-name="Group 4"
                    transform="translate(-11.769 20.031)"
                  >
                    <g
                      id="Group_3"
                      data-name="Group 3"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M83.863,21.884,71.384,9.4,73.795,7a1.012,1.012,0,0,0,1.385-1.475L69.983.329a1.009,1.009,0,1,0-1.427,1.428l-.043-.043-6.6,6.6.043.047A1.009,1.009,0,0,0,60.53,9.783l5.192,5.2a1.012,1.012,0,1,0,1.432-1.432l.043.043,2.41-2.406L82.081,23.662a1.259,1.259,0,1,0,1.782-1.778Z"
                        transform="translate(-60.231 -0.03)"
                        fill="#006633"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div></div
        ></router-link>
        <!-- order history button -->
        <router-link to="/order-list">
          <div class="action-button">
            <div class="nav-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="fi-sr-shopping-bag"
                  d="M24,9a3,3,0,0,0-3-3H18A6,6,0,1,0,6,6H3A3,3,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5ZM8,6a4,4,0,0,1,8,0Z"
                  fill="#006633"
                />
              </svg>
            </div></div
        ></router-link>
        <!-- user profile icon and drop down -->
        <div @click="showDropDownMenu()" class="profile-dropdown">
          <div class="button-with-text">
            <img class="user-profile" :src="userInformation.profile_image" />
            <p class="user-name-text">{{ userInformation.first_name }}</p>
            <unicon name="angle-down" fill="#006633" />
            <!-- dropdown menu -->
            <transition name="slide-fade">
              <div v-if="showDropDown" class="modal-wrapper">
                <div class="drop-down-menu">
                  <p class="drop-down-title">{{ $t("accountMenuText") }}</p>
                  <!-- User Profile -->
                  <div
                    @click="$emit('profileClicked')"
                    class="list container-fluid"
                  >
                    <div class="menu-icon">
                      <unicon name="user" fill="#006633" />
                    </div>
                    <p>{{ $t("yourProfileText") }}</p>
                  </div>
                  <!-- Edit User Profile -->
                  <div
                    @click="$emit('editProfileClicked')"
                    class="list container-fluid"
                  >
                    <div class="menu-icon">
                      <unicon name="user" fill="#006633" />
                    </div>
                    <p>{{ $t("editYourProfileText") }}</p>
                  </div>
                  <!-- Saved addresses -->
                  <div
                    @click="$emit('savedAddressesClicked')"
                    class="list container-fluid"
                  >
                    <div class="menu-icon">
                      <unicon name="map-marker" fill="#006633" />
                    </div>
                    <p>{{ $t("savedAddressesText") }}</p>
                  </div>
                  <!-- Order History -->
                  <div
                    @click="$emit('orderHistoryClicked')"
                    class="list container-fluid"
                  >
                    <div class="menu-icon">
                      <unicon name="shopping-bag" fill="#006633" />
                    </div>
                    <p>{{ $t("orderHistoryText") }}</p>
                  </div>
                  <!-- Log Out -->
                  <div
                    @click="$emit('logOutClicked')"
                    class="list container-fluid"
                  >
                    <div class="menu-icon menu-icon-red">
                      <unicon name="sign-out-alt" fill="#F44336" />
                    </div>
                    <p class="red-text">{{ $t("logOutText") }}</p>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import UserService from "../services/user.service";
export default {
  name: "NavigationBar",
  data() {
    return {
      showDropDown: false,
      langIcon: "EN",
      userInformation: [],
    };
  },
  emits: [
    "profileClicked",
    "editProfileClicked",
    "savedAddressesClicked",
    "orderHistoryClicked",
    "logOutClicked",
  ],
  mounted() {
    // get user profile data
    UserService.getUserProfile().then((response) => {
      this.userInformation = response.data.data.customer;
    });
    // setting locale
    if (this.$i18n.locale === "en") {
      this.langIcon = "EN";
    } else {
      this.langIcon = "KH";
    }
  },
  updated() {
    if (this.$i18n.locale === "en") {
      this.langIcon = "EN";
    } else {
      this.langIcon = "KH";
    }
  },
  methods: {
    showDropDownMenu() {
      this.showDropDown = !this.showDropDown;
    },
    changeLocale() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "kh";
        localStorage.setItem("lang", "kh");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: #ffffff;
}
.nav-wrapper {
  margin: 0 20px;
}
.navbar-brand {
  font-size: 32px;
  font-weight: bold;
  color: #95C125;
  padding-right: 30px;
}
.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
}
.search-bar-container {
  position: relative;
  width: 100%;
}
.search-bar {
  border: 1px solid #eeeeee;
  border-radius: 16px;
  padding: 10px 10px 10px 50px;
  width: 100%;
}
.search-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}
.action-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.action-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 102, 51, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}
.action-button:hover {
  cursor: pointer;
}
.lang-icon {
  font-size: 18px;
  font-weight: bold;
  color: #006633;
  margin: 0;
  text-decoration: none;
}
.profile-dropdown {
  position: relative;
  margin: 0 15px;
}
.profile-dropdown:hover {
  cursor: pointer;
}
.user-profile {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #eeeeee;
}
.button-with-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.user-name-text {
  margin: 0 10px;
  color: #006633;
}
.drop-down-menu {
  position: absolute;
  top: 85px;
  right: 15px;
  padding: 20px 0;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 12px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter {
  transform: translateY(-50px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}
.drop-down-title {
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 10px;
}
.menu-icon {
  background: rgba(0, 102, 51, 0.15);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-icon-red {
  background: rgba(244, 67, 54, 0.3);
}
.list {
  display: flex;
  align-items: center;
  padding: 5px 20px;
}
.list:hover {
  background-color: aliceblue;
  cursor: pointer;
}
.list:hover p {
  color: #006633;
}
.list p {
  margin: 0 20px;
  font-size: 18px;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: default;
}
@media only screen and (max-width: 934px) {
  .action-button-container,
  .logo-container {
    width: 100%;
    justify-content: space-between;
  }
  .drop-down-menu {
    top: 135px;
    right: 15px;
    padding: 20px 0;
  }
}
</style>
<template>
  <div class="order-summary-container">
    <p class="title">{{ $t("orderSummaryLabelText") }}</p>
    <div class="vertical-line"></div>
    <div class="row">
      <!-- Summary detail card -->
      <div class="col-lg-12">
        <div class="detail-card">
          <p class="card-label">{{ $t("idLabelText") }}:</p>
          <p>{{ orderNumber }}</p>
        </div>
      </div>
      <!-- Summary detail card -->
      <div class="col-lg-12">
        <div class="detail-card">
          <p class="card-label">{{ $t("dateLabelText") }}:</p>
          <p>{{ moment(dateTime).format("MMM DD, YYYY h:mm A") }}</p>
        </div>
      </div>
      <!-- Summary detail card -->
      <div class="col-lg-12">
        <div class="detail-card">
          <p class="card-label">{{ $t("restauranLabelText") }}:</p>
          <p>{{ restaurantName }}</p>
        </div>
      </div>
      <!-- Summary detail card -->
      <div class="col-lg-12">
        <div class="detail-card">
          <p class="card-label">{{ $t("orderTypeLabelText") }}:</p>
          <p>{{ orderType }}</p>
        </div>
      </div>
      <!-- Summary detail card -->
      <div class="col-lg-12">
        <div class="detail-card">
          <p class="card-label">{{ $t("paymentLabelText") }}:</p>
          <p>{{ paymentMethod }}</p>
        </div>
      </div>
      <!-- Summary detail card -->
      <div class="col-lg-12">
        <div class="detail-card">
          <p class="card-label">{{ $t("statusLabelText") }}:</p>
          <p>{{ paymentStatus }}</p>
        </div>
      </div>
      <!-- Summary detail card -->
      <div v-if="cancelReason" class="col-lg-12">
        <div class="detail-card">
          <p class="card-label">{{ $t("cancelReasonText") }}:</p>
          <p>{{ cancelReason }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "OrderSummaryCard",
  created: function () {
    this.moment = moment;
  },
  props: {
    orderNumber: {
      type: String,
      default: "",
    },
    restaurantName: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    orderType: {
      type: String,
      default: "",
    },
    paymentMethod: {
      type: String,
      default: "",
    },
    paymentStatus: {
      type: String,
      default: "",
    },
    cancelReason: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.order-summary-container {
  padding: 30px;
  background-color: #eeeeee;
  border-radius: 22px;
  height: 100%;
}
.title {
  font-size: 22px;
  margin: 10px 0;
}
.vertical-line {
  width: 230px;
  border-top: 4px solid #95C125;
  border-radius: 12px;
}
.detail-card {
  padding: 10px;
  margin: 5px 0;
  /* background-color: #ffffff; */
  /* border-radius: 12px; */
  display: flex;
  flex-direction: row;
}
.detail-card p {
  margin: 0;
  font-size: 18px;
}
.card-label {
  padding-right: 10px;
  color: #707070;
}
</style>
<template>
  <div id="app-body">
    <!-- popup modal for confirmatio -->
    <transition name="modal">
      <ConfirmPopupModal
        v-if="showModal"
        @onCancelClicked="onCancel()"
        @onConfirmClicked="onConfirm(this.tempAddressId)"
        title="cancelReasonLabelText"
        description="cancelReasonDescText"
      >
        <div class="cancel-opions">
          <div
            v-for="reason in reasonOptions"
            :key="reason"
            class="reason-option"
          >
            <label class="reason-select">
              <input
                type="radio"
                v-model="cancelReason"
                :value="reason.value"
              />
              {{ $t(reason.label) }}
            </label>
          </div>
        </div>
      </ConfirmPopupModal>
    </transition>
    <!-- body -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <BaseCard v-if="!isLoading" :title="'orderDetailLabelText'">
            <div class="row">
              <div class="col-lg-4 col-md-6 card-container">
                <TrackOrderProcessCard
                  :orderType="orderDetails.order_type"
                  :pendingStatus="isPending"
                  :onGoingStatus="isOnGoing"
                  :servedStatus="isServed"
                  :waitingDeliveryStatus="isWaitingDelivery"
                  :DeliveringStatus="isDelivering"
                  :arrivedStatus="isArrived"
                  :cancelStatus="isCancel"
                />
              </div>
              <div class="col-lg-4 col-md-6 card-container">
                <OrderSummaryCard
                  :orderNumber="orderDetails.order_id"
                  :dateTime="orderDetails.datetime"
                  :restaurantName="restaurantDetails.name"
                  :orderType="orderDetails.order_type"
                  :paymentMethod="orderDetails.payment_method"
                  :paymentStatus="orderDetails.payment_status"
                  :cancelReason="orderDetails.remark"
                />
              </div>
              <div class="col-lg-4 col-md-12 card-container">
                <!-- items area -->
                <div class="row">
                  <p class="label">{{ $t("checkOutCardTitle") }}</p>
                  <div class="cart-item-list">
                    <CartItemList
                      v-for="item in orderItemList"
                      :key="item.id"
                      :isEditable="false"
                      :isCheckOut="true"
                      :productImage="item.product.feature_image"
                      :productName="item.product.name"
                      :productNameKh="item.product.name_kh"
                      :subTotal="item.price"
                      :actualTotal="item.actual_price"
                      :qty="item.qty"
                      :productTypeQty="item.product.type.qty"
                      :productType="item.product.type.name"
                      :productId="item.id"
                      :preOrderLabel="item.product.status"
                      :toppings="item.topping"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="total-price-container">
                    <div class="text-row">
                      <p class="label-text">{{ $t("subTotalText") }}</p>
                      <p class="price-label">
                        ${{
                          parseFloat(orderDetails.subtotal_price).toFixed(2)
                        }}
                      </p>
                    </div>
                    <div class="text-row">
                      <p class="label-text">{{ $t("discountText") }}</p>
                      <p class="price-label">
                        ${{
                          parseFloat(orderDetails.total_discount).toFixed(2)
                        }}
                      </p>
                    </div>
                    <!-- delivery and delivery discount price -->
                    <div v-if="shippingData" class="delivery-price">
                      <div class="text-row">
                        <p class="label-text">{{ $t("deliveryFeeText") }}</p>
                        <p class="price-label">
                          ${{
                            parseFloat(shippingData.charge_customer).toFixed(2)
                          }}
                        </p>
                      </div>
                      <div class="text-row">
                        <p class="label-text">
                          {{ $t("deliveryFeeDiscountText") }}
                        </p>
                        <p class="price-label">
                          ${{
                            parseFloat(
                              shippingData.additional_discount
                            ).toFixed(2)
                          }}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div class="text-row">
                      <p class="label-text">{{ $t("totalText") }}</p>
                      <p class="price-label">
                        ${{ parseFloat(orderDetails.total_price).toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <Button
                v-if="hideCancelButton"
                @click="onCancelOrder()"
                class="cancel-btn"
              >
                {{ $t("cancelOrderLabelText") }}
              </Button>
            </div>
          </BaseCard>
          <NoDataFound v-if="isNoData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import Button from "../components/Button.vue";
import CartItemList from "../components/CartItemList.vue";
import ConfirmPopupModal from "../components/ConfirmPopupModal.vue";
import NoDataFound from "../components/NoDataFound.vue";
import OrderSummaryCard from "../components/OrderSummaryCard.vue";
import TrackOrderProcessCard from "../components/TrackOrderProcessCard.vue";
import UserService from "../services/user.service";
import { useToast } from "vue-toastification";
export default {
  components: {
    BaseCard,
    TrackOrderProcessCard,
    OrderSummaryCard,
    CartItemList,
    Button,
    NoDataFound,
    ConfirmPopupModal,
  },
  name: "OrderHistoryDetail",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      orderDetails: [],
      restaurantDetails: [],
      orderItemList: [],
      shippingData: [],
      isPending: false,
      isOnGoing: false,
      isServed: false,
      isWaitingDelivery: false,
      isDelivering: false,
      isArrived: false,
      isLoading: false,
      isNoData: false,
      isCancel: false,
      hideCancelButton: false,
      showModal: false,
      responseMessage: "",
      cancelReason: "Waiting too long",
      reasonOptions: [
        { label: "waitingTooLongText", value: "Waiting too long" },
        { label: "busyNowText", value: "I am busy now" },
        { label: "notEnoughMoneyText", value: "Don't have enough money" },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.$Progress.finish();
      this.getOrderListDetail(this.$route.params.orderId);
    }
  },
  methods: {
    getOrderListDetail(orderId) {
      this.$Progress.start();
      this.isLoading = true;
      UserService.getOrderDetail(orderId)
        .then((response) => {
          this.orderDetails = response.data.data;
          this.restaurantDetails = response.data.data.restaurant;
          this.orderItemList = response.data.data.order_details;
          this.shippingData = response.data.data.shipping;
          // set order process status
          this.setOrderStatus(this.orderDetails.status);
          // set order delivery process status
          if (this.orderDetails.task !== null) {
            this.setDeliveryStatus(this.orderDetails.task.status);
          }
          this.isLoading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.isNoData = true;
        });
    },
    // set order status
    setOrderStatus(status) {
      if (status === "Pending") {
        this.isPending = true;
        this.hideCancelButton = true;
      } else if (status === "Ongoing") {
        this.isOnGoing = this.isPending = true;
        this.hideCancelButton = false;
      } else if (status === "Cancel") {
        this.isCancel = true;
        this.isServed = this.isPending = this.isOnGoing = false;
        this.hideCancelButton = false;
      } else {
        this.isServed = this.isPending = this.isOnGoing = true;
        this.hideCancelButton = false;
      }
    },

    // set delivery status
    setDeliveryStatus(taskStatus) {
      if (taskStatus === "0") {
        this.isWaitingDelivery = true;
      } else if (taskStatus === "1") {
        this.isDelivering = this.isWaitingDelivery = true;
      } else {
        this.isArrived = this.isDelivering = this.isWaitingDelivery = true;
      }
    },
    pushToHomePage() {
      this.$router.push("/home");
    },
    onCancelOrder() {
      this.showModal = true;
    },
    onCancel() {
      this.showModal = false;
    },
    onConfirm() {
      this.$Progress.start();
      this.cancelOrder();
      this.showModal = false;
    },
    cancelOrder() {
      const orderId = this.$route.params.orderId;
      let formData = new FormData();
      formData.append("reason", this.cancelReason);

      UserService.postCancelOrder(orderId, formData)
        .then((response) => {
          this.$Progress.finish();
          this.responseMessage =
            (response && response.data && response.data.message) ||
            response.message ||
            response.toString();
          this.$router.push("/order-history-list");
          this.toast.success(this.responseMessage);
        })
        .catch((error) => {
          this.$Progress.fail();
          this.responseMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.toast.error(this.responseMessage);
        });
    },
  },
};
</script>

<style scoped>
.label {
  font-size: 22px;
}
.product-list {
  margin: 0;
}
.cancel-btn {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.label-text {
  font-size: 18px;
  color: #707070;
}
.price-label {
  font-size: 18px;
}
.text-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.reason-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}
.reason-select {
  display: flex;
  font-size: 18px;
}
.reason-option input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin-right: 20px;
  font: inherit;
  color: currentColor;
  width: 30px;
  height: 30px;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
.reason-option input[type="radio"]::before {
  content: "";
  width: 1em;
  height: 1em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #ffffff;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.reason-option input[type="radio"]:checked::before {
  transform: scale(1);
}
.reason-option input[type="radio"]:checked {
  background-color: #95C125;
  border-color: #95C125;
}
.card-container {
  margin-bottom: 20px;
}
</style>
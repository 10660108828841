<template>
  <div @click.stop="" class="container">
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <div class="text-row">
          <p class="label-text">{{ $t("amountLabelText") }}:</p>
          <p class="lower-case-text">{{ biddedQty }} {{ unitDisplayType }}</p>
        </div>
        <div class="text-row">
          <p class="label-text">{{ $t("dateLabelText") }}:</p>
          <p>{{ moment(dateTime).format("MMM DD, YYYY h:mm A") }}</p>
        </div>
      </div>
      <div v-if="biddedIsActive" class="col-lg-4 col-sm-2">
        <button @click="editButtonClicked()" class="edit-btn">
          <unicon name="edit-alt" fill="currentColor" />
        </button>
        <button @click="$emit('deleteClicked')" class="delete-btn">
          <unicon name="trash-alt" fill="currentColor" />
        </button>
      </div>
    </div>
    <transition name="slide">
      <div v-if="showInputField" class="col-lg-12">
        <hr />
        <BaseInput
          v-model="biddingQty"
          :isWhiteBackground="true"
          :modelValue="biddingQty"
          label="updateBiddingQtyLabelText"
          name="biddingQty"
          type="Number"
          minimumInput="1"
          inputPlaceHolder="updateBiddingQtyLabelText"
        />
        <BaseErrorMessage v-if="!QtyEqaulMaxBid" message="MaxBiddingQtyMsg" />
        <Button
          @click="saveButtonClicked()"
          class="save-btn"
          :disabled="!InputIsValid"
          >{{ $t("saveChangeButton") }}</Button
        >
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import Button from "./Button.vue";
import BaseInput from "./BaseInput.vue";
import BaseErrorMessage from "./BaseErrorMessage.vue";
export default {
  components: { Button, BaseInput, BaseErrorMessage },
  name: "BiddedHisoryCard",
  data() {
    return {
      showInputField: false,
      biddingQty: 0,
    };
  },
  props: {
    biddedQty: {
      type: Number,
      default: "",
    },
    unitDisplayType: {
      typ: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    maxBiddedQty: {
      type: Number,
      default: "",
    },
    currentBiddedQty: {
      type: Number,
      default: "",
    },
    biddedIsActive: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["editClicked", "deleteClicked"],
  created: function () {
    this.moment = moment;
    this.biddingQty = this.biddedQty;
  },
  computed: {
    QtyIsValid() {
      return !!this.biddingQty;
    },

    QtyIsNotZero() {
      if (this.biddingQty >= 1) {
        return true;
      } else {
        return false;
      }
    },

    QtyEqaulMaxBid() {
      let currentMaxBidQty =
        parseInt(this.maxBiddedQty) - parseInt(this.currentBiddedQty);
      if (this.biddingQty > currentMaxBidQty) {
        return false;
      } else {
        return true;
      }
    },

    InputIsValid() {
      return this.QtyIsValid && this.QtyIsNotZero && this.QtyEqaulMaxBid;
    },
  },
  methods: {
    editButtonClicked() {
      this.showInputField = !this.showInputField;
    },

    saveButtonClicked() {
      this.showInputField = !this.showInputField;
      this.$emit("saveBtnClicked", this.biddingQty);
    },
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
  margin: 10px 0;
  background-color: #eeeeee;
  border-radius: 12px;
}
.text-row p {
  font-size: 18px;
  margin: 0;
}
.text-row {
  display: flex;
  flex-direction: row;
}
.label-text {
  padding-right: 10px;
  color: #707070;
}
.lower-case-text {
  text-transform: lowercase;
}
button {
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
}
.delete-btn {
  color: #f44336;
}
.delete-btn:hover {
  background-color: #f44336;
  color: #ffffff;
}
.edit-btn {
  color: #95C125;
}
.edit-btn:hover {
  background-color: #95C125;
  color: #ffffff;
}
.save-btn {
  width: 100%;
  margin: 10px 0;
}
</style>
<template>
  <div id="app-body">
    <NoDataFound v-if="isNoData" />
    <!-- popup modal for confirmatio -->
    <transition name="modal">
      <ConfirmPopupModal
        v-if="showModal"
        @onCancelClicked="onCancel()"
        @onConfirmClicked="onConfirm()"
      />
    </transition>
    <div class="container-fluid">
      <div v-if="!isLoading" class="row">
        <div class="col-lg-8 col-md-12">
          <div class="containter">
            <BiddingDetailCard
              :restaurantLogo="biddedDetails.restaurant.logo"
              :restaurantName="biddedDetails.restaurant.name"
              :restaurantPhone="biddedDetails.restaurant.phone"
              :productImage="biddedDetails.product_id.feature_image"
              :productName="biddedDetails.product_id.name"
              :productNameKh="biddedDetails.product_id.name_kh"
              :startedCost="biddedDetails.cost_to_bid"
              :finalCost="biddedDetails.final_cost"
              :currentBid="biddedDetails.bid_count"
              :maxBid="biddedDetails.max_bid"
              :unitDisplayType="biddedDetails.type_bidding"
              :startDate="biddedDetails.start_datetime"
              :endDate="biddedDetails.end_datetime"
              :highestPrice="biddedDetails.high_bid_range"
              :lowestPrice="biddedDetails.low_bid_range"
            />
            <BaseCard title="descriptionTitleText">
              <p v-if="biddedDetails.description" class="desc-text">
                {{ biddedDetails.description }}
              </p>
              <p v-else class="desc-text">{{ $t("noDescriptionLabelText") }}</p>
            </BaseCard>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 bidded-card-container">
          <BaseCard title="myBiddingTitle">
            <template v-for="bidder in bidders" :key="bidder">
              <BiddedHistoryCard
                @saveBtnClicked="saveBidded($event, bidder.id)"
                @deleteClicked="deleteBidded(bidder.id)"
                :biddedQty="bidder.amount"
                :unitDisplayType="biddedDetails.type_bidding"
                :dateTime="bidder.created_at"
                :currentBiddedQty="biddedDetails.bid_count"
                :maxBiddedQty="biddedDetails.max_bid"
                :biddedIsActive="biddedDetails.active"
              />
            </template>
            <div v-if="!biddedDetails.active" class="end-bidded-msg">
              <div class="text-row">
                <unicon name="check-circle" fill="#95C125" />
                <p class="text-message">{{ $t("endBiddedMessage") }}</p>
              </div>
            </div>
          </BaseCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import BiddedHistoryCard from "../components/BiddedHistoryCard.vue";
import BiddingDetailCard from "../components/BiddingDetailCard.vue";
import UserService from "../services/user.service";
import ConfirmPopupModal from "../components/ConfirmPopupModal.vue";
import { useToast } from "vue-toastification";
import NoDataFound from "../components/NoDataFound.vue";
export default {
  components: {
    BaseCard,
    BiddingDetailCard,
    BiddedHistoryCard,
    ConfirmPopupModal,
    NoDataFound,
  },
  name: "BiddedDetail",
  data() {
    return {
      isLoading: false,
      isNoData: false,
      showModal: false,
      tempBiddedId: "",
      biddedDetails: [],
      bidders: [],
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    }
  },
  created() {
    this.isLoading = true;
    if (localStorage.bidding_detail) {
      this.isNoData = false;
      this.biddedDetails = JSON.parse(localStorage.bidding_detail);
      this.getBiddedData(this.biddedDetails.id);
    } else {
      this.isNoData = true;
    }
    this.$Progress.finish();
  },
  beforeUnmount() {
    localStorage.removeItem("bidding_detail");
  },
  methods: {
    deleteBidded(biddedId) {
      this.showModal = true;
      this.tempBiddedId = biddedId;
    },

    onCancel() {
      this.showModal = false;
      this.tempBiddedId = "";
    },

    onConfirm() {
      UserService.deleteBidded(this.tempBiddedId)
        .then(() => {
          this.getBiddedData(this.biddedDetails.id);
          this.toast.success("Delete bidded successfully.");
        })
        .catch(() => {
          this.toast.error("Something went wrong! Cannot Delete bidded!");
        });
      this.showModal = false;
      this.tempBiddedId = "";
    },

    saveBidded(newBiddedAmount, biddedId) {
      // initialize formdata
      let formData = new FormData();
      formData.append("amount", newBiddedAmount);

      this.$router.push("/my-bidding");

      UserService.postUpdateBid(biddedId, formData)
        .then(() => {
          this.toast.success("Update bidded successfully.");
        })
        .catch(() => {
          this.toast.error(
            "Something went wrong! cannot update bidded amount."
          );
        });
    },

    getBiddedData(biddedId) {
      UserService.getOwnBiddedByBidding(biddedId).then((resposne) => {
        this.bidders = resposne.data.data;
        this.isLoading = false;
      });
    },
  },
};
BaseCard;
BaseCard;
</script>

<style scoped>
.text-message {
  font-size: 18px;
  padding-left: 10px;
  margin: 0;
}
.text-row {
  display: flex;
  flex-direction: row;
}
.end-bidded-msg {
  padding: 20px 10px;
  /* background-color: #83c985; */
  border-radius: 12px;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
<template>
  <div id="app-body">
    <NoDataFound showMessageFor="404NotFound" />
  </div>
</template>

<script>
import NoDataFound from '../components/NoDataFound.vue';
export default {
  components: { NoDataFound },
  name: "Not Found",
};
</script>

<style scoped>
</style>
<template>
  <div id="app-body">
    <div class="container">
      <div class="row">
        <!-- display content loader while fetching data -->
        <profile-content-loading v-if="isLoading" :hasButton="true" />
        <!-- display data after fetching -->
        <EditProfileCard
          v-if="!isLoading"
          :profileImage="userInformation.profile_image"
          :firstName="userInformation.first_name"
          :lastName="userInformation.last_name"
          :phoneNumber="userInformation.phone"
          :email="userInformation.email"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EditProfileCard from "../components/EditProfileCard.vue";
import ProfileContentLoading from "../components/ProfileContentLoading.vue";
import UserService from "../services/user.service";
export default {
  components: { EditProfileCard, ProfileContentLoading },
  name: "EditProfile",
  data() {
    return {
      isLoading: false,
      userInformation: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.isLoading = true;
      UserService.getUserProfile().then((response) => {
        this.userInformation = response.data.data.customer;
        this.isLoading = false;
      });
    }
  },
};
</script>

<style scoped>
</style>
<template>
  <div id="app-body">
    <div class="container">
      <Wheel
        ref="wheel"
        :gift="gift"
        :data="data"
        animDuration='8000'
        @done="done"
        imgParams="logo"
        @click="spinTheWheel"
      />
    </div>
  </div>
</template>

<script>
import { Wheel } from "vue3-fortune-wheel";
// import "vue3-fortune-wheel/style";
export default {
  name: "LuckyDraw",
  components: {
    Wheel,
  },
  data() {
    return {
      price: "",
      code: "",
      gift: 1,
      data: [
        {
          id: 1,
          value: "Gift 1",
          bgColor: "#a0c73b",
          color: "#ffffff",
        },
        {
          id: 2,
          value: "Gift 2",
          bgColor: "#ffffff",
          color: "#000000",
        },
        {
          id: 3,
          value: "Gift 3",
          bgColor: "#1a7547",
          color: "#ffffff",
        },
        {
          id: 4,
          value: "Gift 4",
          bgColor: "#a0c73b",
          color: "#ffffff",
        },
        {
          id: 5,
          value: "Gift 5",
          bgColor: "#ffffff",
          color: "#000000",
        },
        {
          id: 6,
          value: "Gift 6",
          bgColor: "#1a7547",
          color: "#ffffff",
        },
      ],
    };
  },
  mounted() {
    this.code = this.$route.params.luckyCode;
    console.log(this.code);
  },
  methods: {
    done(params) {
      console.log(params);
    },
    spinTheWheel() {
      this.$refs.wheel.spin();
    },
  },
};
</script>

<style scoped>
</style>
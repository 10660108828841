<template>
  <div id="app-body-no-nav">
    <div v-if="hasInvoice">
      <div v-if="!isLoading" class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="invoice-card">
              <p class="title">{{ $t("invoiceLabelText") }}</p>
              <OrderSummaryCard
                :orderNumber="invoiceDetails.order_id"
                :dateTime="invoiceDetails.datetime"
                :restaurantName="restaurantDetails.name"
                :orderType="invoiceDetails.order_type"
                :paymentMethod="invoiceDetails.payment_method"
                :paymentStatus="invoiceDetails.payment_status"
              />
              <div class="item-container">
                <p class="label">{{ $t("checkOutCardTitle") }}</p>
                <CartItemList
                  v-for="item in orderDetails"
                  :key="item"
                  :isEditable="false"
                  :isCheckOut="true"
                  :productImage="item.product.feature_image"
                  :productName="item.product.name"
                  :productNameKh="item.product.name_kh"
                  :subTotal="item.price"
                  :actualTotal="item.actual_price"
                  :qty="item.qty"
                  :productTypeQty="item.product.type.qty"
                  :productType="item.product.type.name"
                  :productId="item.id"
                  :preOrderLabel="item.product.status"
                />
              </div>
              <div class="row price-container">
                <div class="text-row">
                  <p class="label-text">{{ $t("subTotalText") }}</p>
                  <p class="price-label">
                    ${{ parseFloat(invoiceDetails.subtotal_price).toFixed(2) }}
                  </p>
                </div>
                <div class="text-row">
                  <p class="label-text">{{ $t("discountText") }}</p>
                  <p class="discount-price-label">
                    (${{
                      parseFloat(invoiceDetails.total_discount).toFixed(2)
                    }})
                  </p>
                </div>
                <!-- delivery and delivery discount price -->
                <div v-if="shippingData" class="delivery-price">
                  <div class="text-row">
                    <p class="label-text">{{ $t("deliveryFeeText") }}</p>
                    <p class="price-label">
                      ${{ parseFloat(shippingData.charge_customer).toFixed(2) }}
                    </p>
                  </div>
                  <div class="text-row">
                    <p class="label-text">
                      {{ $t("deliveryFeeDiscountText") }}
                    </p>
                    <p class="discount-price-label">
                      (${{
                        parseFloat(shippingData.additional_discount).toFixed(2)
                      }})
                    </p>
                  </div>
                </div>
                <hr />
                <div class="text-row">
                  <p class="label-text">{{ $t("totalText") }}</p>
                  <p class="price-label">
                    ${{ parseFloat(invoiceDetails.total_price).toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <NoDataFound />
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import CartItemList from "../components/CartItemList.vue";
import NoDataFound from "../components/NoDataFound.vue";
import OrderSummaryCard from "../components/OrderSummaryCard.vue";
import userService from "../services/user.service";
export default {
  components: { NoDataFound, BaseCard, OrderSummaryCard, CartItemList },
  name: "Invoice",
  data() {
    return {
      isLoading: false,
      customerId: "",
      invoiceNum: "",
      hasInvoice: false,
      hideNavBar: true,
      invoiceDetails: [],
      orderDetails: [],
      restaurantDetails: [],
      shippingData: [],
    };
  },
  created() {
    this.$emit("onAppRun", this.hideNavBar);
  },
  mounted() {
    this.isLoading = true;
    this.$Progress.start();
    if (this.$route.query.customer_id && this.$route.query.invoice_num) {
      this.customerId = this.$route.query.customer_id;
      this.invoiceNum = this.$route.query.invoice_num;
      this.hasInvoice = true;
      this.getInvoiceDetail(this.customerId, this.invoiceNum);
      this.$Progress.finish();
    } else {
      this.hasInvoice = false;
      this.$Progress.finish();
    }
  },
  methods: {
    getInvoiceDetail(customerId, invoiceNum) {
      userService
        .getInvoiceDetail(customerId, invoiceNum)
        .then((response) => {
          this.invoiceDetails = response.data.data;
          this.orderDetails = response.data.data.order_details;
          this.restaurantDetails = response.data.data.restaurant;
          this.shippingData = response.data.data.shipping;
          this.isLoading = false;
        })
        .catch((error) => {
          this.hasInvoice = false;
          console.log(error);
          this.$Progress.fail();
        });
    },
  },
};
</script>

<style scoped>
.label {
  font-size: 24px;
}
.item-container {
  margin: 40px 0;
}
.label-text {
  font-size: 18px;
  color: #707070;
}
.price-label {
  font-size: 18px;
}
.discount-price-label {
  font-size: 18px;
  color: #ff5252;
}
.text-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.price-container {
  padding: 20px;
  background-color: #eeeeee;
  border-radius: 22px;
}
.invoice-card {
  padding: 30px;
  background: #ffffff;
  margin: 30px 0;
  border-radius: 20px;
}
.title {
  font-size: 24px;
  font-weight: 500;
}
</style>
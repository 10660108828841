<template>
  <div id="app-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <BaseCard title="myBiddingTitle">
            <MyBiddingList :ownBiddedList="ownBidded" />
            <No-Data-Found v-if="isNoData" />
          </BaseCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import MyBiddingList from "../components/MyBiddingList.vue";
import NoDataFound from "../components/NoDataFound.vue";
import UserService from "../services/user.service";
export default {
  components: { BaseCard, MyBiddingList, NoDataFound },
  name: "MyBidding",
  data() {
    return {
      ownBidded: [],
      isNoData: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.$Progress.start();
      this.getOwnBidded();
    }
  },
  methods: {
    getOwnBidded() {
      UserService.getOwnBidded()
        .then((response) => {
          if (response.data.data.length > 0) {
            this.isNoData = false;
            this.ownBidded = response.data.data;
            this.$Progress.finish();
          } else {
            this.isNoData = true;
            this.$Progress.finish();
          }
        })
        .catch(() => {
          this.$Progress.fail();
        });
    },
  },
};

BaseCard;
</script>

<style scoped>
.no-data-container {
  padding-top: 20px;
}
</style>
<template>
  <div>
    <table>
      <!-- tabel header -->
      <tr class="table-header">
        <td class="image-col">{{ $t("imageLabelText") }}</td>
        <td class="product-name-col">{{ $t("productNameLabelText") }}</td>
        <td class="restaurant-col">{{ $t("restaurantLabelText") }}</td>
        <td class="date-col">{{ $t("startDateText") }}</td>
        <td class="date-col">{{ $t("endDateText") }}</td>
        <td class="cost-col">{{ $t("highCostText") }}</td>
        <td class="cost-col">{{ $t("lowCostText") }}</td>
      </tr>
      <!-- tabel body -->
      <template v-for="ownBidded in ownBiddedList" :key="ownBidded">
        <tr @click="pushToBiddedDetail(ownBidded)" class="table-body">
          <td class="image-col">
            <img :src="ownBidded.product_id.feature_image" alt="" />
          </td>
          <td class="product-name-col">
            <div class="product-name">
              <p v-if="this.$i18n.locale === 'en'">
                {{ ownBidded.product_id.name }}
              </p>
              <p v-else-if="!ownBidded.product_id.name_kh">
                {{ ownBidded.product_id.name }}
              </p>
              <p v-else>{{ ownBidded.product_id.name_kh }}</p>
            </div>
          </td>
          <td class="restaurant-col">
            <p>{{ ownBidded.restaurant.name }}</p>
          </td>
          <td class="date-col">
            <p>
              {{
                moment(ownBidded.start_datetime).format("MMM DD, YYYY h:mm A")
              }}
            </p>
          </td>
          <td class="date-col">
            <p>
              {{ moment(ownBidded.end_datetime).format("MMM DD, YYYY h:mm A") }}
            </p>
          </td>
          <td class="cost-col">
            <p>${{ parseFloat(ownBidded.high_bid_range).toFixed(2) }}</p>
          </td>
          <td class="cost-col">
            <p>${{ parseFloat(ownBidded.low_bid_range).toFixed(2) }}</p>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "MyBimagedingList",
  props: {
    ownBiddedList: {
      type: Object,
      default: null,
    },
  },
  methods: {
    pushToBiddedDetail(biddedData) {
      this.$Progress.start();
      localStorage.setItem("bidding_detail", JSON.stringify(biddedData));
      this.$router.push("/bidded-detail");
    },
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  text-align: center;
}
table .image-col {
  width: 5%;
  padding: 10px;
}
table .date-col {
  width: 20%;
  padding: 10px;
}
table .product-name-col {
  width: 15%;
  padding: 10px;
  text-align: left;
}
table .restaurant-col {
  width: 15%;
  padding: 10px;
}
.table-header {
  font-size: 18px;
  font-weight: 500;
  color: #006633;
  border-bottom: 1pt solid #eeeeee;
}
/* Set border-radius on the top-left and bottom-left of the first table data on the table row */
td:first-child,
th:first-child {
  border-radius: 12px 0 0 12px;
}

/* Set border-radius on the top-right and bottom-right of the last table data on the table row */
td:last-child,
th:last-child {
  border-radius: 0 12px 12px 0;
}
tr.table-body {
  background-color: rgba(238, 238, 238, 0.35);
}
table tr:hover:not(tr.table-header) {
  cursor: pointer;
  background-color: #eeeeee;
}
.image-col img {
  width: 10vh;
  height: 10vh;
  object-fit: cover;
  border-radius: 12px;
}
p {
  font-size: 18px;
}
</style>
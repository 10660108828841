<template>
  <button
    :class="[
      'main-btn',
      cancelButton ? 'cancel-btn' : '',
      greyButton ? 'grey-btn' : '',
    ]"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "btn",
  props: {
    cancelButton: {
      type: Boolean,
      default: false,
    },
    greyButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.main-btn {
  padding: 10px 50px 10px 50px;
  margin: 30px 0px 20px 0;
  border: 0;
  border-radius: 24px;
  background-color: #95C125;
  color: #ffffff;
  font-size: 18px;
  transition: 0.5s;
}
.main-btn:disabled {
  background-color: #707070;
  color: #ffffff;
}
.main-btn:hover:not(.main-btn:disabled, .cancel-btn, .grey-btn) {
  cursor: pointer;
  background-color: rgba(149, 193, 37, 0.8);
  transition: 0.5s;
}
.cancel-btn {
  background-color: rgb(244, 67, 54);
}
.cancel-btn:hover {
  background-color: rgba(244, 67, 54, 0.8);
}
.grey-btn {
  background-color: #d5d5d5;
}
</style>
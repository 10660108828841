<template>
  <div class="address-card">
    <div class="row">
      <div class="col-lg-10 col-sm-10">
        <p class="label-text">{{ label }}</p>
        <p class="address-text">{{ address }}</p>
      </div>
      <div class="col-lg-2 col-sm-2 button-container">
        <button @click="$emit('onEditClicked')" class="edit-btn">
          <unicon name="edit-alt" fill="currentColor" />
        </button>
        <button @click="$emit('onDeleteClicked')" class="delete-btn">
          <unicon name="trash-alt" fill="currentColor" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressListCard",
  props: {
    label: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
  },
  emits: ["onEditClicked", "onDeleteClicked"],
};
</script>

<style scoped>
p {
  margin: 0;
}
.address-card {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: rgba(238, 238, 238, 0.35);
  border-radius: 12px;
}
.address-card:hover {
  background-color: #eeeeee;
}
.label-text {
  font-size: 20px;
  font-weight: 500;
}
.address-text {
  color: #707070;
}
button {
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
}
.delete-btn {
  color: #f44336;
}
.delete-btn:hover {
  background-color: #f44336;
  color: #ffffff;
}
.edit-btn {
  color: #95C125;
}
.edit-btn:hover {
  background-color: #95C125;
  color: #ffffff;
}
.save-btn {
  width: 100%;
  margin: 10px 0;
}
.button-container {
  display: flex;
  justify-content: flex-end;
}
</style>
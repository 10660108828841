<template>
  <div class="option-selector">
    <label class="radio-toggle-btn">
      <input
        type="radio"
        v-model="selectedOrderType"
        :value="modelValue"
        :name="name"
        @change="updateInput($event.target.value)"
      />
      <span class="icon-text-column">
        <unicon
          v-if="icon"
          :name="icon"
          fill="currentColor"
          height="52"
          width="52"
        />
        {{ $t(label) }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseRadioButton",
  props: {
    name: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Boolean, Number],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "Label",
    },
  },
  emits: ["update:modelValue"],
  methods: {
    updateInput(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style scoped>
.option-selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.radio-toggle-btn {
  display: flex;
  flex-direction: row;
}
.radio-toggle-btn span {
  padding: 20px 25px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #eeeeee;
  border-radius: 22px;
  cursor: pointer;
}
.radio-toggle-btn input:checked + span {
  background-color: #95C125;
  color: #ffffff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
input[type="radio"] {
  visibility: hidden;
}
.icon-text-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
<template>
  <div class="container-fluid">
    <div class="row">
      <LogoContainer class="col-md-6" />
      <div class="col-md-6 body-container">
        <div class="form-container">
          <div class="title">
            <h1>{{ $t("welcomeText") }}</h1>
            <h1>{{ $t("registerTitle") }}</h1>
          </div>
          <form @submit="handleRegister()" autocomplete="off">
            <div class="input-field">
              <div class="row">
                <div class="col-6">
                  <BaseInput
                    v-model="firstName"
                    name="firstName"
                    label="firstNameTitle"
                    inputPlaceHolder="firstNamePlaceHolder"
                    labelIcon="user"
                    isAutoComplete="off"
                    :isRequired="true"
                  />
                </div>
                <div class="col-6">
                  <BaseInput
                    v-model="lastName"
                    name="lastName"
                    label="lastNameTitle"
                    inputPlaceHolder="lastNamePlaceHolder"
                    labelIcon="user"
                    isAutoComplete="off"
                    :isRequired="true"
                  />
                </div>
              </div>
            </div>
            <div class="input-field">
              <BaseInput
                v-model="phoneNumber"
                name="phoneNumber"
                label="phoneNumberTitle"
                inputPlaceHolder="phoneNumberPlaceHolder"
                labelIcon="phone-volume"
                isAutoComplete="off"
                :isRequired="true"
              />
            </div>
            <div class="input-field">
              <BaseInput
                v-model="email"
                name="email"
                label="emailTitle"
                inputPlaceHolder="emailPlaceHolder"
                labelIcon="envelope"
                isAutoComplete="off"
              />
            </div>
            <BaseErrorMessage v-if="message" :message="message" />

            <Button
              @click="handleRegister()"
              :disabled="!formIsValid || loading"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              {{ $t("registerButton") }}
            </Button>
          </form>
          <p>
            {{ $t("haveAccountText") }}
            <router-link to="/signin">{{ $t("logInLink") }}</router-link>
          </p>
          <button @click="changeLocale()" class="lang-btn">
            {{ $t("changeLangButton") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseErrorMessage from "../components/BaseErrorMessage.vue";
import BaseInput from "../components/BaseInput.vue";
import Button from "../components/Button.vue";
import LogoContainer from "../components/LogoContainer.vue";
import { useToast } from "vue-toastification";

export default {
  name: "SignIn",
  components: {
    LogoContainer,
    Button,
    BaseInput,
    BaseErrorMessage,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      successful: false,
      loading: false,
      message: "",
      hideNavBar: true,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
    };
  },
  created() {
    this.$emit("onAppRun", this.hideNavBar);
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    firstNameIsValid() {
      return !!this.firstName;
    },

    lastNameIsValid() {
      return !!this.lastName;
    },

    phoneNumberIsRequire() {
      return !!this.phoneNumber;
    },

    phoneNumberIsValid() {
      if (
        this.phoneNumber.match(/\d/g).length >= 9 &&
        this.phoneNumber.match(/\d/g).length <= 12
      ) {
        return (this.isPhoneValid = true);
      } else {
        return (this.isPhoneValid = false);
      }
    },
    formIsValid() {
      return (
        this.firstNameIsValid &&
        this.lastNameIsValid &&
        this.phoneNumberIsRequire &&
        this.phoneNumberIsValid
      );
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;

      let formData = new FormData();
      formData.append("first_name", this.firstName);
      formData.append("last_name", this.lastName);
      formData.append("phone", this.phoneNumber);
      formData.append("email", this.email);

      this.$store.dispatch("auth/register", formData).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
          this.toast.success("Registered successfully, login to continue");
          this.$router.push("/signin");
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
    changeLocale() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "kh";
        localStorage.setItem("lang", "kh");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      }
    },
  },
};
</script>

<style scoped>
.body-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
p {
  font-size: 18px;
}
.form-container {
  display: inline-flex;
  flex-direction: column;
  padding: 20px;
  width: 500px;
}
.form-container form {
  display: flex;
  flex-direction: column;
  margin: 30px 0 10px 0;
}
.form-container .title {
  color: #006633;
  line-height: 0.5;
  text-align: start;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}
.form-group {
  display: inline-flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
}
.form-group label {
  text-align: left;
}
.form-group input {
  height: 45px;
  margin-top: 5px;
  padding-left: 55px;
  width: 100%;
  background-color: #eeeeee;
  border: 0;
  border-radius: 16px;
}
.form-container p {
  text-align: center;
}
.input-container {
  position: relative;
}
.input-group {
  display: flex;
  flex-direction: column;
}
.input-icon {
  position: absolute;
  margin: 15px;
}
a {
  text-decoration: none;
  color: #95C125;
}
a:hover {
  text-decoration: underline;
  color: #95C125;
}
h1 {
  font-weight: bold;
}
.input-field {
  margin: 10px 0;
}
.lang-btn {
  background-color: transparent;
  color: #95C125;
  border: none;
  font-size: 18px;
}
.lang-btn:hover {
  color: #95C125;
  text-decoration: underline;
}
</style>
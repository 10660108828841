<template>
  <div class="container-fluid">
    <div v-if="!cancelStatus" class="track-order-card">
      <p class="card-title">{{ $t("orderProcessLabelText") }}</p>
      <div class="process-icon-container">
        <div class="vertical-line"></div>
        <!-- process for delivery order -->
        <div v-if="orderType === 'Delivery'" class="process-icon-col">
          <OrderProcessIcon
            :isDone="pendingStatus"
            icon="clipboard-notes"
            label="pendingLabelText"
          />
          <OrderProcessIcon
            :isDone="onGoingStatus"
            icon="parcel"
            label="prepareOrderLabelText"
          />
          <OrderProcessIcon
            :isDone="waitingDeliveryStatus"
            icon="clock-eight"
            label="waitingForDeliveryLabelText"
          />
          <OrderProcessIcon
            :isDone="DeliveringStatus"
            icon="truck"
            label="deliveringLabelText"
          />
          <OrderProcessIcon
            :isDone="arrivedStatus"
            icon="check-circle"
            label="arrivedLabelText"
          />
        </div>
        <!-- process for booking, dine in and take out order -->
        <div v-else class="process-icon-col">
          <OrderProcessIcon
            :isDone="pendingStatus"
            icon="clipboard-notes"
            label="pendingLabelText"
          />
          <OrderProcessIcon
            :isDone="onGoingStatus"
            icon="parcel"
            label="prepareOrderLabelText"
          />
          <OrderProcessIcon
            :isDone="servedStatus"
            icon="check-circle"
            label="doneLabelText"
          />
        </div>
      </div>
    </div>
    <div v-else class="track-order-card">
      <div class="cancel-order-card">
        <unicon name="times-circle" width="60" height="60" fill="#f44336" />
        <p class="cancel-text">{{ $t("cancelOrderDescText") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import OrderProcessIcon from "./OrderProcessIcon.vue";
export default {
  components: { OrderProcessIcon },
  name: "TrackOrderProcessCard",
  props: {
    orderType: {
      type: String,
      required: true,
    },
    pendingStatus: {
      Type: Boolean,
      default: false,
    },
    onGoingStatus: {
      Type: Boolean,
      default: false,
    },
    servedStatus: {
      Type: Boolean,
      default: false,
    },
    waitingDeliveryStatus: {
      Type: Boolean,
      default: false,
    },
    DeliveringStatus: {
      Type: Boolean,
      default: false,
    },
    arrivedStatus: {
      Type: Boolean,
      default: false,
    },
    cancelStatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>

<style scoped>
.container-fluid {
  padding: 0;
  height: 100%;
}
.track-order-card {
  padding: 30px;
  background-color: #eeeeee;
  border-radius: 22px;
  height: 100%;
}
.card-title {
  font-size: 22px;
}
.process-icon-container {
  position: relative;
}
.process-icon-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.vertical-line {
  border-left: 10px solid #95C125;
  height: 100%;
  position: absolute;
  left: 21px;
  border-radius: 10px;
}
.cancel-text {
  font-size: 20px;
  margin: 10px;
}
</style>
<template>
  <div class="profile-card">
    <!-- user profile and full name section -->
    <div class="profile-with-name-row">
      <img class="rounded-circle profile-img" :src="profileImage" alt="" />
      <div class="user-name-col">
        <p class="user-name">{{ firstName }}</p>
        <p class="user-name">{{ lastName }}</p>
        <p class="sub-detail-text">{{ $t("profileSubText") }}</p>
      </div>
    </div>
    <!-- input data section -->
    <div class="input-group-container">
      <div class="row row-cols-1 row-cols-md-2 g-4 row-container">
        <!-- first name input field -->
        <base-input
          v-model="firstName"
          name="firstName"
          type="text"
          :label="firstNameLabel"
          :inputPlaceHolder="firstNamePlaceHolder"
          labelIcon="user"
          :modelValue="firstName"
          :isDisabled="true"
        />
        <!-- last name input field -->
        <base-input
          v-model="lastName"
          name="lastName"
          type="text"
          :label="lastNameLabel"
          :inputPlaceHolder="firstNamePlaceHolder"
          labelIcon="user"
          :modelValue="lastName"
          :isDisabled="true"
        />
        <!-- phone number input field -->
        <base-input
          v-model="phoneNumber"
          name="phoneNumber"
          type="tel"
          :label="phoneNumberLabel"
          :inputPlaceHolder="phoneNumberPlaceHolder"
          labelIcon="phone-volume"
          :modelValue="phoneNumber"
          :isDisabled="true"
        />
        <!-- email input field -->
        <base-input
          v-model="email"
          name="email"
          type="email"
          :label="emailLabel"
          :inputPlaceHolder="emailPlaceHolder"
          labelIcon="envelope"
          :modelValue="email"
          :isDisabled="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import BaseInput from "./BaseInput.vue";
export default {
  components: { BaseInput, Button },
  name: "EditProfile",
  data() {
    return {
      firstNameLabel: "firstNameTitle",
      firstNamePlaceHolder: "firstNamePlaceHolder",
      lastNameLabel: "lastNameTitle",
      lastNamePlaceHolder: "lastNamePlaceHolder",
      phoneNumberLabel: "phoneNumberTitle",
      phoneNumberPlaceHolder: "phoneNumberPlaceHolder",
      emailLabel: "emailTitle",
      emailPlaceHolder: "emailPlaceHolder",
    };
  },
  props: {
    profileImage: String,
    firstName: String,
    lastName: String,
    phoneNumber: String,
    email: String,
  },
};
</script>

<style scoped>
.profile-card {
  background-color: #ffffff;
  padding: 30px;
  margin-top: 50px;
  border-radius: 22px;
}
.profile-with-name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}
.user-name-col {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.profile-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid #707070;
}
.user-name {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
}
.sub-detail-text {
  font-size: 24px;
  color: #707070;
}
.input-group-container {
  margin: 50px;
}
.save-btn {
  margin-top: 50px;
  float: right;
}
</style>
<template>
  <div id="app-body">
    <transition name="modal">
      <ConfirmPopupModal
        v-if="showModal"
        @onCancelClicked="onCancel()"
        @onConfirmClicked="onConfirm(this.tempAddressId)"
      >
        <p class="desc-text">
          {{ $t("deleteAddressMsg") }} {{ this.tempAddressName }}
        </p></ConfirmPopupModal
      ></transition
    >
    <div class="container-fluid">
      <div class="row">
        <BaseCard>
          <div class="card-header-area">
            <div class="card-title">
              <p class="card-header-text">{{ $t("savedAddressLabelText") }}</p>
              <p class="card-desc-text">{{ $t("savedAddressDescText") }}</p>
            </div>
            <Button @click="pushToAddNewAddress()">{{
              $t("addNewAddressButtonText")
            }}</Button>
          </div>
          <hr />
          <AddressListCard
            @onEditClicked="onEdit(savedAddress)"
            @onDeleteClicked="onDelete(savedAddress.id, savedAddress.label)"
            v-for="savedAddress in savedAddressList"
            :key="savedAddress.id"
            :label="savedAddress.label"
            :address="savedAddress.address"
          />
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script>
import AddressListCard from "../components/AddressListCard.vue";
import BaseCard from "../components/BaseCard.vue";
import Button from "../components/Button.vue";
import ConfirmPopupModal from "../components/ConfirmPopupModal.vue";
import UserService from "../services/user.service";
import { useToast } from "vue-toastification";
export default {
  components: { BaseCard, Button, AddressListCard, ConfirmPopupModal },
  name: "SavedAddress",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showModal: false,
      savedAddressList: [],
      tempAddressId: "",
      tempAddressName: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.$Progress.start();
      this.getSAddressList();
    }
  },
  methods: {
    getSAddressList() {
      UserService.getSavedAddressList().then((response) => {
        this.savedAddressList = response.data.data;
        this.$Progress.finish();
      });
    },
    pushToAddNewAddress() {
      this.$router.push("/add-new-address");
    },
    onEdit(addressData) {
      localStorage.setItem("saved_address", JSON.stringify(addressData));
      this.$router.push("/edit-address");
    },
    onDelete(addressId, addressName) {
      this.showModal = true;
      this.tempAddressId = addressId;
      this.tempAddressName = addressName;
    },
    onCancel() {
      this.showModal = false;
      this.tempAddressId = "";
      this.tempAddressName = "";
    },
    onConfirm(addressId) {
      this.$Progress.start();
      UserService.deleteSavedAddress(addressId)
        .then(() => {
          this.$Progress.finish();
          this.showModal = false;
          this.toast.success("Address deleted successfully.");
          this.getSAddressList();
        })
        .catch(() => {
          this.$Progress.fail();
          this.showModal = false;
          this.toast.error("Something went wrong! Could not delete address.");
        });
      this.tempAddressId = "";
      this.tempAddressName = "";
    },
  },
};
</script>

<style scoped>
.card-header-text {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.card-desc-text {
  font-size: 18px;
  margin: 0;
  color: #707070;
}
.card-header-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.desc-text {
  font-size: 18px;
  margin: 0;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
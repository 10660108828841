import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as firebase from 'firebase/app'
import i18n from './plugins/i18n'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Unicon from 'vue-unicons'
// import { uniUser, uniPhoneVolume, uniEnvelope, uniDialpadAlt, uniExclamationTriangle, uniFavoriteMonochrome, uniMapMarker, uniClockEight, uniPercentage, uniTruck, uniShoppingCartAlt, uniPlus, uniMinus, uniTrashAlt, uniSearch, uniAngleDown, uniShoppingBag, uniSignOutAlt, uniImageV, uniInfoCircle, uniExclamation, uniCrockery, uniCalendarAlt, uniWallet, uniAngleRightB, uniAngleLeftB, uniParcel, uniPackage, uniClipboardNotes, uniCheckCircle, uniEditAlt, uniMapMarkerInfo, uniEstate, uniMap, uniTimesCircle } from 'vue-unicons/dist/icons'

// Unicon.add([uniUser, uniPhoneVolume, uniEnvelope, uniDialpadAlt, uniExclamationTriangle, uniFavoriteMonochrome, uniMapMarker, uniClockEight, uniPercentage, uniTruck, uniShoppingCartAlt, uniPlus, uniMinus, uniTrashAlt, uniSearch, uniAngleDown, uniShoppingBag, uniSignOutAlt, uniImageV, uniInfoCircle, uniExclamation, uniCrockery, uniCalendarAlt, uniWallet, uniAngleRightB, uniAngleLeftB, uniParcel, uniPackage, uniClipboardNotes, uniCheckCircle, uniEditAlt, uniMapMarkerInfo, uniEstate, uniMap, uniTimesCircle])

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_yFAM-XbbdziBoeopPFVFVrJpZHCioRE",
  authDomain: "davane-customer.firebaseapp.com",
  projectId: "davane-customer",
  storageBucket: "davane-customer.appspot.com",
  messagingSenderId: "99033636218",
  appId: "1:99033636218:web:89cdfa79e34d1ba94ecac3",
  measurementId: "G-TRYCGYVZ3X"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// configuration for vue3-progressbar
const progressBarBptions = {
  color: "#95C125",
  failedColor: "#FF5252",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

// configuariton for vue 3 toastification
const toastOption = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
}

const app = createApp(App)

app.use(router)
app.use(store)
// app.use(Unicon)
app.use(i18n)
app.use(VueProgressBar, progressBarBptions)
app.use(Toast, toastOption)
app.mount('#app')

<template>
  <div class="col">
    <div @click="$emit('clickOnCard')" class="card h-100">
      <div class="row">
        <div class="col-4">
          <img
            class="rounded-circle restaurant-logo"
            :src="restaurantLogo"
            alt=""
          />
        </div>
        <div class="col-8 card-detail">
          <p class="restaurant-name">{{ restaurantName }}</p>
          <div class="text-with-icon">
            <unicon name="clock-eight" fill="#19B2AF" />
            <p>{{ timeConverter(openAt) }} - {{ timeConverter(closeAt) }}</p>
          </div>
          <div class="text-with-icon">
            <unicon name="favorite" fill="#FF9529" icon-style="monochrome" />
            <p>{{ rating }}</p>
          </div>
          <div class="text-with-icon">
            <unicon name="map-marker" fill="#19B2AF" />
            <p>{{ address }}</p>
          </div>
        </div>
      </div>
      <div class="promotion-container">
        <div v-if="discountUpTo" class="text-with-icon">
          <unicon name="percentage" fill="#e95c30" />
          <p class="orange-text">
            {{ $t("discountUpToText") }} {{ discountUpTo }}%
          </p>
        </div>
        <div v-if="deliveryStartFrom" class="text-with-icon">
          <unicon name="truck" fill="#19b2af" />
          <p class="blue-text">
            {{ $t("deliveryFeeText") }} ${{
              parseFloat(deliveryStartFrom).toFixed(2)
            }}
          </p>
        </div>
        <div v-if="!deliveryStartFrom" class="text-with-icon">
          <unicon name="truck" fill="#19b2af" />
          <p class="blue-text">
            {{ $t("freeDeliveryText") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopCard",
  props: {
    restaurantLogo: String,
    restaurantName: String,
    openAt: String,
    closeAt: String,
    rating: Number,
    address: String,
    discountUpTo: Number,
    deliveryStartFrom: Number,
  },
  emits: ["clickOnCard"],
  methods: {
    timeConverter(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 22px;
  border: none;
  transition: 00.3s;
  margin-top: auto;
}
.card:hover {
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.3s;
}
.card p {
  margin: 0;
}
.card-detail {
  padding: 20px;
}
.restaurant-logo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: 20px;
  border: 2px solid #eeeeee;
}
.restaurant-name {
  font-size: 22px;
  margin-top: 10px;
}
.text-with-icon {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}
.text-with-icon p {
  font-size: 18px;
  margin-left: 10px;
  margin-top: 10px;
}
.promotion-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #d5d5d5;
  padding: 10px;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  margin-top: auto;
}
.orange-text {
  color: #e95c30;
}
.blue-text {
  color: #006633;
}
</style>
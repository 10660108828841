<template>
  <div id="app-body">
    <!-- popup modal for confirmatio -->
    <transition name="modal">
      <ConfirmPopupModal
        v-if="showConfirmModal"
        @onCancelClicked="onCancel()"
        @onConfirmClicked="onConfirm(this.tempAddressId)"
        description="removeItemDescMsg"
      />
    </transition>
    <div class="container-fluid">
      <!-- show on have data -->
      <div v-if="!isLoading" class="row">
        <!-- menu card -->
        <div class="col-2">
          <div class="category-card">
            <div class="menu-card-header">
              <p class="menu-title-label">{{ $t("menuLabelText") }}</p>
              <hr />
            </div>
            <div class="menu-card-body">
              <div
                v-for="productSection in productSections"
                :key="productSection"
              >
                <div
                  @click="goto(productSection.name)"
                  v-if="
                    productSection.products &&
                    productSection.products.length > 0
                  "
                  class="menu-button"
                >
                  <div>
                    <p v-if="this.$i18n.locale === 'en'">
                      {{ productSection.name }}
                    </p>
                    <p v-else-if="!productSection.name_kh">
                      {{ productSection.name }}
                    </p>
                    <p v-else>{{ productSection.name_kh }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 shop-detail-container">
          <!-- display content loading while fetching data  -->
          <div v-if="isLoading" class="shop-content-loading">
            <div class="img-and-text-skeleton">
              <div class="img-skeleton"></div>
              <div class="row-text-skeleton">
                <div class="text-skeleton-1"></div>
                <div class="text-skeleton-2"></div>
              </div>
            </div>
          </div>
          <!-- shop info detail card -->
          <BaseShopDetailCard
            v-if="!isLoading"
            :logo="shopDetail.logo"
            :name="shopDetail.name"
            :openTime="shopDetail.open_time"
            :closeTime="shopDetail.close_time"
            :phone="shopDetail.phone"
            :address="shopDetail.address"
            :featureImages="previewImages"
          />
          <!-- display content loader when fetching product data -->
          <!-- <div>
            <div v-if="isLoading" class="product-card-loader">
              <div class="product-img-loader"></div>
              <div class="text-skeleton-1"></div>
              <div class="text-skeleton-2"></div>
            </div>
          </div> -->
          <!-- product section -->
          <div v-for="productSection in productSections" :key="productSection">
            <div
              v-if="
                productSection.products && productSection.products.length > 0
              "
              :id="productSection.name"
              :ref="productSection.name"
            >
              <div class="product-section-label">
                <p v-if="this.$i18n.locale === 'en'">
                  {{ productSection.name }}
                </p>
                <p v-else-if="!productSection.name_kh">
                  {{ productSection.name }}
                </p>
                <p v-else>{{ productSection.name_kh }}</p>
              </div>
              <div
                class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 row-card"
              >
                <template
                  v-for="product in productSection.products"
                  :key="product.id"
                >
                  <ProductCard
                    v-if="
                      product.prices &&
                      product.prices.length > 0 &&
                      product.prices[0].actual_price
                    "
                    @cardClicked="pushToProductPage(product.id)"
                    :productImage="product.feature_image"
                    :productName="product.name"
                    :productNameKh="product.name_kh"
                    :price="product.prices[0].actual_price"
                    :fullPrice="product.prices[0].price"
                    :discount="product.prices[0].discount"
                    :preOrderDiscount="product.prices[0].pre_order_discount"
                    :preOrderBadge="product.status"
                    :quantity="product.prices[0].qty"
                    :unitDisplayType="product.prices[0].name"
                /></template>
              </div>
            </div>
          </div>
          <!-- modal card -->
          <add-to-cart-modal
            v-if="showModal"
            @closeModal="onCloseModal()"
            @updateCart="updateCart()"
            :productImage="productDetail.feature_image"
            :productName="productDetail.name"
            :productNameKh="productDetail.name_kh"
            :prices="productDetail.prices"
            :preOrderLabel="productDetail.status"
            :discount="productDetail.prices[0].discount"
            :productId="productDetail.id"
            :pricePerUnit="productDetail.price_per_unit"  
            :isFood="productDetail.is_food"
            :toppings="productDetail.toppings"
          />
        </div>
        <!-- shopping cart -->
        <div class="col-4 shopping-cart-container">
          <ShoppingCartCard
            @checkOut="pushToCheckOut()"
            :subTotalPrice="shoppingCart.subtotal"
            :discountPrice="shoppingCart.total_discount"
            :totalPrice="shoppingCart.total"
            :countItemInCart="cartItems.length"
          >
            <CartItemList
              v-for="cartItem in cartItems"
              :key="cartItem.id"
              @removeCartItem="deleteCartItem(cartItem.id)"
              @qtyUpdate="updateCart()"
              :productImage="cartItem.product.feature_image"
              :productName="cartItem.product.name"
              :productNameKh="cartItem.product.name_kh"
              :subTotal="cartItem.prices.price"
              :actualTotal="cartItem.prices.actual_price"
              :qty="cartItem.qty"
              :productTypeQty="cartItem.prices.qty"
              :productType="cartItem.prices.name"
              :productId="cartItem.id"
              :preOrderLabel="cartItem.product.status"
              :inventoriesId="cartItem.inventories_id"
              :toppings="cartItem.topping"
            />
          </ShoppingCartCard>
        </div>
      </div>
      <!-- show no data found svg and message -->
      <NoDataFound v-if="isNoData" showMessageFor="noRestaurantFound" />
    </div>
  </div>
</template>

<script>
import AddToCartModal from "../components/AddToCartModal.vue";
import BaseShopDetailCard from "../components/BaseShopDetailCard.vue";
import Button from "../components/Button.vue";
import CartItemList from "../components/CartItemList.vue";
import ProductCard from "../components/ProductCard.vue";
import ShoppingCartCard from "../components/ShoppingCartCard.vue";
import UserService from "../services/user.service";
import PushToCheckOut from "../mixins/pushToCheckOut.js";
import NoDataFound from "../components/NoDataFound.vue";
import ConfirmPopupModal from "../components/ConfirmPopupModal.vue";
import { useToast } from "vue-toastification";
export default {
  components: {
    BaseShopDetailCard,
    ShoppingCartCard,
    CartItemList,
    ProductCard,
    Button,
    AddToCartModal,
    NoDataFound,
    ConfirmPopupModal,
  },
  name: "ProductInShop",
  setup() {
    const toast = useToast();
    return { toast };
  },
  mixins: [PushToCheckOut],
  data() {
    return {
      showModal: false,
      isLoading: true,
      isNoData: false,
      showConfirmModal: false,
      tempItemId: "",
      errorMessage: "",
      shopDetail: [],
      previewImages: [],
      shoppingCart: [],
      cartItems: [],
      productSections: [],
      productDetail: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.$Progress.start();
      this.isLoading = true;
      const shopId = "shopId_" + this.$route.params.id;
      const shopData = localStorage.getItem(shopId);

      // checking if localstorage has data or not
      if (shopData) {
        this.$Progress.finish();
        this.productSections = JSON.parse(shopData);
      } else {
        UserService.getProductListBySection(this.$route.params.id).then(
          (response) => {
            this.productSections = response.data.data;
            this.$Progress.finish();
            // store data to localstorage
            localStorage.setItem(shopId, JSON.stringify(this.productSections));
          }
        );
      }

      UserService.getShoppingCartList().then((response) => {
        if (response.data.data) {
          this.shoppingCart = response.data.data;
          this.cartItems = response.data.data.details;
        } else {
          this.shoppingCart = {};
          this.cartItems = {};
        }
      });
      UserService.getRestaurantDetail(this.$route.params.id)
        .then((response) => {
          this.shopDetail = response.data.data;
          this.previewImages.push(response.data.data.preview_images);
          this.isLoading = false;
        })
        .catch(() => {
          this.isNoData = true;
          this.$Progress.finish();
        });
    }
  },
  methods: {
    onCloseModal() {
      this.showModal = false;
    },
    pushToProductPage(productId) {
      UserService.getProductDetail(this.$route.params.id, productId).then(
        (response) => {
          this.productDetail = response.data.data;
          this.showModal = true;
        }
      );
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop - 90;

      window.scrollTo(0, top);
    },
    deleteCartItem(cartItemId) {
      this.showConfirmModal = true;
      this.tempItemId = cartItemId;
    },
    onCancel() {
      this.showConfirmModal = false;
      this.tempItemId = "";
    },
    onConfirm() {
      this.$Progress.start();
      UserService.deleteCart(this.tempItemId)
        .then(() => {
          this.updateCart();
          this.toast.success("Item removed successfully!");
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.fail();
          this.errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.toast.error(this.errorMessage);
        });
      this.showConfirmModal = false;
      this.tempItemId = "";
    },
    updateCart() {
      UserService.getShoppingCartList().then((response) => {
        if (response.data.data) {
          this.shoppingCart = response.data.data;
          this.cartItems = response.data.data.details;
          this.$Progress.finish();
        } else {
          this.shoppingCart = {};
          this.cartItems = {};
          this.$Progress.finish();
        }
      });
    },
  },
};
</script>

<style scoped>
.shop-detail-container {
  margin-top: 35px;
  margin-left: 25px;
}
.shopping-cart-container {
  overflow-y: hidden;
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
}
.shop-content-loading {
  padding: 30px;
  background-color: #fff;
  border-radius: 22px;
}
.img-skeleton {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid #eeeeee;
  background-color: #ecebeb;
}
.text-skeleton-1 {
  width: 220px;
  height: 20px;
  background-color: #ecebeb;
  border-radius: 22px;
  margin: 10px;
}
.text-skeleton-2 {
  width: 120px;
  height: 10px;
  background-color: #ecebeb;
  border-radius: 22px;
  margin: 10px;
}
.row-text-skeleton {
  margin-left: 20px;
}
.img-and-text-skeleton {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* style for product card content loader */
.product-card-loader {
  width: 220px;
  height: 320px;
  background-color: #fff;
  border-radius: 22px;
}
.product-img-loader {
  width: 220px;
  height: 220px;
  background-color: #d5d5d5;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}
.text-skeleton-1 {
  width: 120px;
  height: 20px;
  border-radius: 4px;
  background-color: #d5d5d5;
  margin: 20px 10px;
}
.text-skeleton-2 {
  width: 80px;
  height: 10px;
  border-radius: 4px;
  background-color: #d5d5d5;
  margin-left: 10px;
}
.product-section-label {
  font-size: 24px;
  font-weight: 500;
}
.row-card {
  margin-bottom: 50px;
}
.category-card {
  padding: 30px;
  background-color: #fff;
  margin: 40px 20px;
  border-radius: 22px;
  position: fixed;
  width: 15%;
  height: 85%;
}
.menu-card-body {
  height: 90%;
  overflow-y: auto;
}
.menu-title-label {
  font-size: 24px;
  margin: 0;
  font-weight: 500;
}
.menu-button {
  padding: 10px;
  margin: 5px 10px 5px 0;
  border-radius: 8px;
}
.menu-button:hover {
  background-color: aliceblue;
  cursor: pointer;
}
.menu-button p {
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
<template>
  <div class="container">
    <div class="bidding-card">
      <!-- Card Title -->
      <div class="top-section">
        <div class="text-with-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g
              id="auction-law-svgrepo-com"
              transform="translate(11.769 -20.031)"
            >
              <g
                id="Group_4"
                data-name="Group 4"
                transform="translate(-11.769 20.031)"
              >
                <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M89.771,27.347l-15.6-15.6,3.013-3.008a1.265,1.265,0,0,0,1.731-1.843L72.42.4a1.262,1.262,0,0,0-1.784,1.784l-.053-.053-8.248,8.244.053.059A1.262,1.262,0,1,0,60.6,12.221l6.49,6.5a1.265,1.265,0,1,0,1.79-1.79l.053.053,3.013-3.008,15.593,15.6a1.573,1.573,0,1,0,2.228-2.223Z"
                    transform="translate(-60.231 -0.03)"
                  />
                </g>
              </g>
            </g>
          </svg>

          <p>{{ $t("biddingTitle") }}</p>
        </div>
        <hr />
        <p class="desc-text">{{ $t("biddingDescText") }}</p>
        <div class="row input-container">
          <div class="col-lg-10 col-10">
            <BaseInput
              v-model="biddingQty"
              type="number"
              minimumInput="1"
              name="biddingQty"
              label="biddingQtyLabel"
              inputPlaceHolder="biddingQtyLabel"
            />
          </div>
          <div class="col-lg-2 col-2">
            <p class="unit-display-type">{{ unitDisplayType }}</p>
          </div>
        </div>
        <div>
          <BaseErrorMessage v-if="!QtyIsNotZero" message="biddingQtyErrorMsg" />
          <BaseErrorMessage v-if="!QtyEqaulMaxBid" message="MaxBiddingQtyMsg" />
        </div>
      </div>
      <div class="bidding-btn">
        <Button
          @click="$emit('buttonClicked', this.biddingQty)"
          :disabled="!formIsValid"
          >{{ $t("biddingButtonText") }}</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import BaseErrorMessage from "./BaseErrorMessage.vue";
import BaseInput from "./BaseInput.vue";
import Button from "./Button.vue";
export default {
  components: { Button, BaseInput, BaseErrorMessage },
  name: "BidCard",
  data() {
    return {
      biddingQty: null,
    };
  },
  props: {
    maximumBiddingQty: {
      type: Number,
      default: "",
    },
    unitDisplayType: {
      type: String,
      default: "",
    },
  },
  emits: ["buttonClicked"],
  computed: {
    QtyIsValid() {
      return !!this.biddingQty;
    },

    QtyIsNotZero() {
      if (this.biddingQty >= 1) {
        return true;
      } else {
        return false;
      }
    },

    QtyEqaulMaxBid() {
      if (this.biddingQty > this.maximumBiddingQty) {
        return false;
      } else {
        return true;
      }
    },

    formIsValid() {
      return this.QtyIsValid && this.QtyIsNotZero && this.QtyEqaulMaxBid;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 50px 10px 20px;
  width: 100%;
  height: 100%;
}
.bidding-card {
  background-color: #ffffff;
  height: 100%;
  padding: 30px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 20px;
}
.text-with-icon {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
}
.text-with-icon p {
  margin: 0 0 0 20px;
  font-size: 28px;
  font-weight: bold;
}
.main-btn {
  width: 100%;
}
.desc-text {
  font-size: 18px;
}
.input-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.unit-display-type {
  font-size: 18px;
  text-transform: lowercase;
}
</style>
<template>
  <div class="edit-profile-card">
    <!-- user profile and full name section -->
    <div class="profile-with-name-row">
      <!-- user profile -->
      <div @click="handleFileImport" class="profile-image-container">
        <img
          v-if="!imageFile"
          class="rounded-circle profile-img"
          :src="profileImage"
          alt=""
        />
        <img
          v-if="imageFile"
          class="rounded-circle profile-img"
          :src="imageUrl"
          alt=""
        />
        <div class="icon-button-container">
          <unicon name="image-v" fill="#ffffff" />
        </div>
      </div>
      <div class="user-name-col">
        <p class="user-name">{{ firstName }}</p>
        <p class="user-name">{{ lastName }}</p>
        <p class="sub-detail-text">{{ $t("editProfileSubText") }}</p>
      </div>
    </div>
    <!-- input data section -->
    <div class="input-group-container">
      <div class="row row-cols-1 row-cols-md-2 g-4 row-container">
        <!-- first name input field -->
        <div>
          <base-input
            v-model="firstName"
            name="firstName"
            type="text"
            :label="firstNameLabel"
            :inputPlaceHolder="firstNamePlaceHolder"
            labelIcon="user"
            :modelValue="firstName"
          />
          <base-error-message
            v-if="!firstNameIsValid"
            :message="firstNameErrorMsg"
          />
        </div>
        <!-- last name input field -->
        <div>
          <base-input
            v-model="lastName"
            name="lastName"
            type="text"
            :label="lastNameLabel"
            :inputPlaceHolder="firstNamePlaceHolder"
            labelIcon="user"
            :modelValue="lastName"
          />
          <base-error-message
            v-if="!lastNameIsValid"
            :message="lastNameErrorMsg"
          />
        </div>
        <!-- phone number input field -->
        <div>
          <base-input
            v-model="phoneNumber"
            name="phoneNumber"
            type="tel"
            :label="phoneNumberLabel"
            :inputPlaceHolder="phoneNumberPlaceHolder"
            labelIcon="phone-volume"
            :modelValue="phoneNumber"
          />
          <base-error-message
            v-if="!phoneNumberIsRequire"
            :message="phoneNumberRequireMsg"
          />
          <base-error-message
            v-if="!isPhoneValid"
            :message="phoneNumberInvalideMsg"
          />
        </div>
        <!-- email input field -->
        <div>
          <base-input
            v-model="email"
            name="email"
            type="email"
            :label="emailLabel"
            :inputPlaceHolder="emailPlaceHolder"
            labelIcon="envelope"
            :modelValue="email"
          />
          <!-- <base-error-message
            v-if="!isEmailValide"
            :message="emailInvalidMsg"
          /> -->
        </div>
        <!-- hiddden image file input field that triger when profile image container clicked -->
        <input
          ref="imageFileUploader"
          class="d-none"
          type="file"
          name="profileImage"
          id="profileImage"
          @change="handleFileUpload($event)"
        />
      </div>
      <Button @click="saveChange()" :disabled="!formIsValid" class="save-btn">{{
        $t("saveChangeButton")
      }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import BaseInput from "./BaseInput.vue";
import UserService from "../services/user.service";
import BaseErrorMessage from "./BaseErrorMessage.vue";
import { useToast } from "vue-toastification";
export default {
  components: { BaseInput, Button, BaseErrorMessage },
  name: "EditProfile",
  data() {
    return {
      message: "",
      // getting the translated and setting it to the varaibles
      firstNameLabel: "firstNameTitle",
      firstNamePlaceHolder: "firstNamePlaceHolder",
      lastNameLabel: "lastNameTitle",
      lastNamePlaceHolder: "lastNamePlaceHolder",
      phoneNumberLabel: "phoneNumberTitle",
      phoneNumberPlaceHolder: "phoneNumberPlaceHolder",
      emailLabel: "emailTitle",
      emailPlaceHolder: "emailPlaceHolder",
      firstNameErrorMsg: "firstNameErrorMessage",
      lastNameErrorMsg: "lastNameErrorMessage",
      phoneNumberRequireMsg: "phoneNumberRequireMessage",
      phoneNumberInvalideMsg: "phoneNumberErrorMessage",
      emailInvalidMsg: "emailErrorMessage",
      // declearing variables
      imageFile: "",
      imageUrl: null,
      isPhoneValid: true,
      isEmailValide: true,
      isProfileUpdated: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  props: {
    profileImage: String,
    firstName: String,
    lastName: String,
    phoneNumber: String,
    email: String,
  },
  computed: {
    // validating user data input
    firstNameIsValid() {
      return !!this.firstName;
    },

    lastNameIsValid() {
      return !!this.lastName;
    },

    phoneNumberIsRequire() {
      return !!this.phoneNumber;
    },

    phoneNumberIsValid() {
      if (
        this.phoneNumber.match(/\d/g).length >= 9 &&
        this.phoneNumber.match(/\d/g).length <= 12
      ) {
        return (this.isPhoneValid = true);
      } else {
        return (this.isPhoneValid = false);
      }
    },

    // emailIsValid() {
    //   // start to validate email only when user input
    //   if (this.email.length >= 1) {
    //     if (
    //       this.email.match(
    //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //       )
    //     ) {
    //       return (this.isEmailValide = true);
    //     } else {
    //       return (this.isEmailValide = false);
    //     }
    //   } else {
    //     return (this.isEmailValide = true);
    //   }
    // },

    formIsValid() {
      return (
        this.firstNameIsValid &&
        this.lastNameIsValid &&
        this.phoneNumberIsRequire &&
        this.phoneNumberIsValid
      );
    },
  },
  methods: {
    handleFileImport() {
      this.$refs.imageFileUploader.click();
    },
    handleFileUpload(event) {
      this.imageFile = event.target.files[0];
      this.imageUrl = URL.createObjectURL(this.imageFile);
    },
    saveChange() {
      this.$Progress.start();
      // initialize a formdata
      let formData = new FormData();
      formData.append("first_name", this.firstName);
      formData.append("last_name", this.lastName);
      formData.append("phone", this.phoneNumber);
      formData.append("email", this.email);
      formData.append("profile_image", this.imageFile);
      formData.append("_method", "PUT");

      // making a post request to the api
      UserService.postUpdateProfile(formData)
        .then(() => {
          this.$Progress.finish();
          this.toast.success(
            "Your profile information have been updated successfully."
          );
          this.$router.push("/profile");
          this.isProfileUpdated = true;
          this.$emit("updateProfile", this.isProfileUpdated);
        })
        .catch((error) => {
          this.$Progress.fail();
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.toast.error(this.message);
        });
    },
  },
};
</script>

<style scoped>
.edit-profile-card {
  background-color: #ffffff;
  padding: 30px;
  margin-top: 50px;
  border-radius: 22px;
}
.profile-with-name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}
.user-name-col {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.profile-image-container {
  position: relative;
}
.profile-image-container:hover {
  cursor: pointer;
}
.profile-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid #707070;
}
.icon-button-container {
  bottom: 0px;
  width: 50px;
  height: 50px;
  background-color: #95C125;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
}
.user-name {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  color: #006633;
}
.sub-detail-text {
  font-size: 24px;
  color: #707070;
}
.input-group-container {
  margin: 50px;
}
.save-btn {
  margin-top: 50px;
  float: right;
}
</style>
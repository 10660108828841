<template>
  <div v-if="pageCount > 1" class="paginiation-controller">
    <!-- prev button -->
    <button
      @click="decreasement()"
      :disabled="prevIsDisabled"
      class="controller-btn"
    >
      <unicon name="angle-left-b" fill="currentColor" />
    </button>
    <!-- page number navigator -->
    <button
      @click="navigateTo(page)"
      v-for="page in pageCount"
      :key="page"
      :class="[
        'pagination-btn',
        currentPage == page ? 'pagination-btn-active' : '',
      ]"
    >
      {{ page }}
    </button>
    <!-- next button -->
    <button
      @click="increasement()"
      :disabled="nextIsDisabled"
      class="controller-btn"
    >
      <unicon name="angle-right-b" fill="currentColor" />
    </button>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {
      prevIsDisabled: true,
      nextIsDisabled: false,
      currentPage: 1,
    };
  },
  props: {
    pageCount: {
      type: Number,
      default: 1,
    },
    visiblePageCount: {
      type: Number,
      default: 5,
    },
  },
  emits: ["navigateToPage"],
  watch: {
    currentPage(newValue) {
      if (newValue == 1) {
        this.prevIsDisabled = true;
        this.nextIsDisabled = false;
      } else if (newValue == this.pageCount) {
        this.nextIsDisabled = true;
        this.prevIsDisabled = false;
      } else {
        this.prevIsDisabled = false;
        this.nextIsDisabled = false;
      }
    },
  },
  methods: {
    increasement() {
      this.$Progress.start();
      this.currentPage += 1;
      this.$emit("navigateToPage", this.currentPage).then(() => {
        this.$Progress.finish();
      });
    },
    decreasement() {
      this.$Progress.start();
      this.currentPage -= 1;
      this.$emit("navigateToPage", this.currentPage).then(() => {
        this.$Progress.finish();
      });
    },
    navigateTo(pageNumber) {
      this.$Progress.start();
      this.currentPage = pageNumber;
      this.$emit("navigateToPage", this.currentPage).then(() => {
        this.$Progress.finish();
      });
    },
  },
};
</script>

<style scoped>
.controller-btn {
  padding: 5px;
  margin: 20px 20px 20px 0;
  border: 0;
  border-radius: 50%;
  background-color: #eeeeee;
  font-size: 18px;
  width: 40px;
  height: 40px;
}
.controller-btn:disabled {
  background-color: #707070;
  color: #ffffff;
}
.pagination-btn {
  padding: 5px;
  margin: 20px 20px 20px 0;
  border: 0;
  border-radius: 50%;
  background-color: #eeeeee;
  color: #000000;
  font-size: 18px;
  width: 40px;
  height: 40px;
}
.pagination-btn-active {
  background-color: #95C125;
  color: #ffffff;
  pointer-events: none;
}
.controller-btn:hover:not(.controller-btn:disabled),
.pagination-btn:hover:not(.pagination-btn-active) {
  color: #95C125;
}
</style>
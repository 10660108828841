<template>
  <div>
    <label v-if="!isRequired" class="label-text" :for="name">
      {{ $t(label) }}
    </label>
    <label v-else class="label-text" :for="name">
      {{ $t(label) }}
      <p class="red-text">*</p>
    </label>
    <div v-if="labelIcon" class="input-with-icon-container">
      <div class="label-icon">
        <unicon :name="labelIcon" fill="#006633" />
      </div>
      <div class="vl"></div>
      <input
        :class="[isWhiteBackground ? 'input-field-white' : 'input-field']"
        :name="name"
        :type="type"
        :min="minimumInput"
        :value="modelValue"
        :disabled="isDisabled"
        :autocomplete="isAutoComplete"
        :placeholder="$t(inputPlaceHolder)"
        @input="updateInput($event.target.value)"
      />
    </div>
    <div v-else class="input-container">
      <input
        :class="[isWhiteBackground ? 'input-field-white' : 'input-field']"
        :name="name"
        :type="type"
        :min="minimumInput"
        :value="modelValue"
        :disabled="isDisabled"
        :placeholder="$t(inputPlaceHolder)"
        @input="updateInput($event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TextInputWithLabelIcon",
  data() {
    return {};
  },
  props: {
    name: {
      type: String,
      default() {
        throw new Error("No form field name specified.");
      },
    },
    type: {
      type: String,
      default: "text",
    },
    labelIcon: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Boolean, Number],
      default: null,
    },
    inputPlaceHolder: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    minimumInput: {
      type: String,
      default: null,
    },
    isWhiteBackground: {
      type: Boolean,
      default: false,
    },
    isAutoComplete: {
      type: String,
      default: "on",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    updateInput(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}
.input-with-icon-container {
  position: relative;
}
.input-with-icon-container input {
  padding: 10px 10px 10px 50px;
}
.input-container input {
  padding: 10px;
}
.label-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
.vl {
  position: absolute;
  top: 10px;
  left: 40px;
  border-left: 1px solid #707070;
  height: 25px;
  padding-right: 10px;
}
.label-text {
  font-size: 18px;
  color: #707070;
  margin-bottom: 10px;
  display: flex;
}
.input-field {
  border-radius: 12px;
  border: 0px solid #707070;
  background-color: #eeeeee;
  width: 100%;
  font-size: 18px;
}
.input-field-white {
  border-radius: 12px;
  border: 0px solid #707070;
  background-color: #ffffff;
  width: 100%;
  font-size: 18px;
}
.red-text {
  color: #f44336;
}
</style>
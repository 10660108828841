<template>
  <div class="col">
    <div @click="$emit('clickOnCard')" class="special-offer-card card">
      <span
        ><img class="rounded-circle restaurant-logo" :src="logo" alt=""
      /></span>
      <div class="preview-image-container">
        <img class="preview-image" :src="previewImages" alt="" />
        <span class="badge">
          <div class="card-badge delivery-badge">
            <p v-if="deliveryFee">
              {{ $t("deliveryFeeText") }} ${{
                parseFloat(deliveryFee).toFixed(2)
              }}
            </p>
            <p v-else>
              {{ $t("freeDeliveryText") }}
            </p>
          </div>
          <div v-if="discountUpTo" class="card-badge discount-badge">
            <p>{{ $t("discountUpToText") }} {{ discountUpTo }}%</p>
          </div>
        </span>
      </div>
      <div class="card-detail">
        <p class="restaurant-name">{{ restaurantName }}</p>
        <!-- <div class="text-with-icon">
          <unicon name="favorite" fill="#FF9529" icon-style="monochrome" />
          <p>{{ rating }}</p>
        </div> -->
        <div class="text-with-icon">
          <unicon name="map-marker" fill="#95C125" />
          <p>{{ address }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecialOfferCard",
  props: {
    logo: String,
    previewImages: String,
    restaurantName: String,
    deliveryFee: {
      type: Number,
      default: "",
    },
    discountUpTo: Number,
    rating: Number,
    address: String,
  },
  emits: ["clickOnCard"],
};
</script>

<style scoped>
.card {
  background-color: transparent;
  border: 0px;
}
.special-offer-card {
  margin: 10px;
  position: relative;
  border-radius: 22px;
  transition: 0.3s;
}
.special-offer-card:hover {
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: 0.3s;
}
.special-offer-card:hover .preview-image {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: 0.3s;
}
.special-offer-card:hover .restaurant-name {
  color: #95C125;
}
.special-offer-card p {
  margin: 0px;
}
.restaurant-logo {
  width: 75px;
  height: 75px;
  object-fit: cover;
  position: absolute;
  right: 20px;
  top: 20px;
  border: 2px solid #ffffff;
}
.preview-image-container img {
  width: 100%;
  height: 28vh;
  margin-bottom: 15px;
  border-radius: 22px;
  object-fit: cover;
}
.card-badge {
  padding: 5px;
  color: #ffffff;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  margin-bottom: 10px;
}
.card-badge p {
  margin: 5px 10px 5px 10px;
}
.delivery-badge {
  background-color: #95C125;
}
.discount-badge {
  background-color: #e95c30;
  width: 135px;
}
.badge {
  position: absolute;
  left: 0px;
  top: 20px;
  padding: 0;
}
.badge p {
  font-size: 18px;
  font-weight: normal;
  text-align: left;
}
.card-detail {
  padding: 0px 10px 0px 10px;
  margin-bottom: 30px;
}
.restaurant-name {
  font-size: 22px;
  font-weight: bold;
}
.text-with-icon {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: 10px;
}
.text-with-icon p {
  font-size: 18px;
  margin-left: 10px;
  color: #707070;
}
</style>
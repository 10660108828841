<template>
  <div class="content-loader">
    <div class="profile-with-name-row">
      <div class="profile-img"></div>
      <div class="user-name-col">
        <div class="text-skeleton-1"></div>
        <div class="text-skeleton-2"></div>
      </div>
    </div>
    <div class="input-group-container">
      <div class="skeleton-group-row">
        <div class="input-skeleton-column">
          <div class="text-skeleton-1"></div>
          <div class="input-skeleton"></div>
        </div>
        <div class="input-skeleton-column">
          <div class="text-skeleton-1"></div>
          <div class="input-skeleton"></div>
        </div>
      </div>
      <div class="skeleton-group-row">
        <div class="input-skeleton-column">
          <div class="text-skeleton-1"></div>
          <div class="input-skeleton"></div>
        </div>
        <div class="input-skeleton-column">
          <div class="text-skeleton-1"></div>
          <div class="input-skeleton"></div>
        </div>
      </div>
      <div v-if="isHasButton">
        <Button :disabled="true" class="save-btn">{{
          $t("saveChangeButton")
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
export default {
  components: { Button },
  name: "ProfileContentLoading",
  data() {
    return {
      isHasButton: this.hasButton,
    };
  },
  props: {
    hasButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.content-loader {
  background-color: #ffffff;
  padding: 30px;
  margin-top: 50px;
  border-radius: 22px;
}
.profile-with-name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}
.user-name-col {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.profile-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid #ecebeb;
  background-color: #ecebeb;
  border-radius: 50%;
}
.text-skeleton-1 {
  width: 150px;
  height: 20px;
  border-radius: 8px;
  background-color: #ecebeb;
  margin-bottom: 20px;
}
.text-skeleton-2 {
  width: 100px;
  height: 20px;
  border-radius: 8px;
  background-color: #ecebeb;
  margin-bottom: 20px;
}
.text-skeleton-3 {
  width: 80px;
  height: 10px;
  border-radius: 4px;
  background-color: #ecebeb;
  margin-bottom: 20px;
}
.input-group-container {
  margin: 50px;
}
.input-skeleton {
  width: 100%;
  padding: 25px;
  border-radius: 12px;
  background-color: #ecebeb;
}
.skeleton-group-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
.input-skeleton-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 30px;
}
.save-btn {
  float: right;
}
</style>
 <template>
  <!-- Product list in cart -->
  <div class="product-list">
    <div class="row">
      <!-- Product Image -->
      <div class="col-lg-2 col-md-3 col-2 product-image-container">
        <img class="product-image" :src="productImage" alt="" />
      </div>
      <!-- Product Name and Product Type or more attribute -->
      <div class="col-lg-6 col-md-7 col-6 item-des">
        <div class="product-name">
          <p v-if="this.$i18n.locale === 'en'">{{ productName }}</p>
          <p v-else-if="!productNameKh">{{ productName }}</p>
          <p v-else>{{ productNameKh }}</p>
        </div>
        <!-- pre-order badge -->
        <div v-if="!preOrderLabel" class="pre-order-badge">
          {{ $t("preOrderLabel") }}
        </div>
        <div>
          <p v-if="isCheckOut">
            {{ $t("typeText") }}: {{ productTypeQty }} {{ productType }} x
            {{ qty }}
          </p>
          <p v-else>
            {{ $t("typeText") }}: {{ productTypeQty }} {{ productType }}
          </p>
        </div>
        <div v-if="toppings.length > 0">
          <hr />
          <template v-for="topping in toppings" :key="topping">
            <div class="text-row">
              <p>{{ topping.name }}</p>
              <p>{{ topping.children[0].name }}</p>
            </div>
          </template>
        </div>
      </div>
      <!-- Product Price/Quantity and more actions -->
      <div class="col-lg-4 col-md-2 col-4 qty-and-price">
        <div class="item-price">
          <p v-show="subTotal != actualTotal" class="original-price">
            ${{ parseFloat(subTotal).toFixed(2) }}
          </p>
          <p class="final-price">${{ parseFloat(actualTotal).toFixed(2) }}</p>
        </div>
        <!-- Actions button for Cart Item -->
        <div v-if="isEditable" class="actions-for-item">
          <div @click="$emit('removeCartItem')" class="delete-btn">
            <unicon name="trash-alt" fill="#000000" hover-fill="#ff5252" />
          </div>
          <!-- Quantity Controller -->
          <div v-if="!inventoriesId" class="quantity-controller">
            <div
              @click="decreaseQuantity(productId)"
              :class="{ 'quantity-controller-button-disabled': isDisable }"
              class="quantity-controller-button"
            >
              <unicon name="minus" fill="#ffffff" />
            </div>
            <p>{{ quantity }}</p>
            <div
              @click="increaseQuantity(productId)"
              class="quantity-controller-button"
            >
              <unicon name="plus" fill="#ffffff" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import UserService from "../services/user.service";
export default {
  name: "CartItemList",
  data() {
    return {
      isDisable: true,
      quantity: this.qty,
    };
  },
  props: {
    productId: Number,
    productImage: String,
    productName: String,
    productNameKh: {
      type: String,
      default: null,
    },
    productTypeQty: Number,
    productType: String,
    subTotal: String,
    actualTotal: String,
    totalDiscount: Number,
    qty: Number,
    preOrderLabel: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isCheckOut: {
      type: Boolean,
      default: false,
    },
    inventoriesId: {
      type: String,
      default: null,
    },
    toppings: {
      type: Object,
      default: "",
    },
  },
  emits: ["removeCartItem", "qtyUpdate"],
  methods: {
    increaseQuantity(prdouctId) {
      this.quantity++;
      // Initialize formdata
      let formData = new FormData();
      formData.append("qty", this.quantity);
      formData.append("_method", "PUT");
      this.$Progress.start();

      // make post request
      UserService.postUpdateQtyCart(prdouctId, formData)
        .then(() => {
          this.showModal = false;
          this.$emit("qtyUpdate");
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    decreaseQuantity(prdouctId) {
      if (this.quantity > 1) {
        this.quantity--;
        // Initialize formdata
        let formData = new FormData();
        formData.append("qty", this.quantity);
        formData.append("_method", "PUT");
        this.$Progress.start();

        // make post request
        UserService.postUpdateQtyCart(prdouctId, formData)
          .then(() => {
            this.showModal = false;
            this.$emit("qtyUpdate");
            this.$Progress.finish();
          })
          .catch((error) => {
            console.log(error);
            this.$Progress.fail();
          });
      }
    },
  },
  mounted() {
    if (this.quantity == 1) {
      return (this.isDisable = true);
    } else {
      return (this.isDisable = false);
    }
  },
  watch: {
    quantity(newValue) {
      if (newValue >= 2) {
        this.isDisable = false;
      } else {
        return (this.isDisable = true);
      }
    },
  },
};
</script>

<style scoped>
.row {
  margin-left: 0;
}
.product-list {
  margin-right: 20px;
  margin-bottom: 20px;
}
p {
  font-size: 18px;
  margin: 0;
}
.product-image {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10%;
  object-fit: cover;
}
.product-image-container {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.quantity-controller {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
}
.quantity-controller-button {
  background-color: #95C125;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}
.quantity-controller-button-disabled {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #707070;
  cursor: context-menu;
}
.actions-for-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}
.item-price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}
.final-price {
  font-weight: bold;
  font-size: 24px;
  color: #ff5252;
}
.original-price {
  margin-right: 5px;
  text-decoration: line-through;
}
.delete-btn {
  padding: 4px;
  background-color: #eeeeee;
  border-radius: 50%;
  margin: 10px 0;
}
.delete-btn:hover {
  background-color: rgba(255, 52, 52, 0.3);
  fill: #ffffff;
}
.item-des {
  display: flex;
  flex-direction: column;
}
.pre-order-badge {
  padding: 3px 10px;
  color: #ffffff;
  border-radius: 20px;
  background-color: #f44336;
  margin-right: 10px;
  width: 100px;
}
.product-name {
  display: -webkit-box;
  max-width: inherit;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product-name p {
  font-size: 20px;
  font-weight: bold;
}
.product-qty {
  font-size: 28px;
  font-weight: 500;
  text-align: right;
}
.qty-and-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.quantity-controller p {
  padding: 0 10px;
}
.text-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
<template>
  <div id="app-body">
    <div class="container-fluid">
      <div class="title-header">
        <h2>{{ $t("biddingTitle") }}</h2>
        <p>{{ $t("biddingBodyText") }}</p>
      </div>
      <!-- Bidding Card -->
      <div class="row row-cols-1 row-cols-md-3 g-4 row-card">
        <BiddingCard
          v-for="bidding in biddings"
          :key="bidding.id"
          @clickOnCard="pushToBiddingPage(bidding)"
          :productImage="bidding.product_id.feature_image"
          :productName="bidding.product_id.name"
          :productNameKh="bidding.product_id.name_kh"
          :restaurantName="bidding.restaurant.name"
          :createdAt="bidding.created_at"
          :bidCount="bidding.bid_count"
          :maxBid="bidding.max_bid"
          :unitDisplayType="bidding.product_id.unit_display_type"
          :finalCost="bidding.final_cost"
          :lowBidRange="bidding.low_bid_range"
          :highBidRange="bidding.high_bid_range"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BiddingCard from "../components/BiddingCard.vue";
import UserService from "../services/user.service";
export default {
  name: "BiddingList",
  components: {
    BiddingCard,
  },
  data() {
    return {
      biddings: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      UserService.getBiddingList().then((response) => {
        this.biddings = response.data.data;
      });
    }
  },
  methods: {
    pushToBiddingPage(biddingData) {
      this.$Progress.start();
      localStorage.setItem("bidding_detail", JSON.stringify(biddingData));
      this.$router.push({
        name: "BiddingDetail",
        params: {
          biddingId: biddingData.id,
          productName: biddingData.product_id.name,
        },
      });
    },
  },
};
</script>

<style scoped>
.title-header {
  padding: 20px;
}
.row-card {
  padding: 0 20px;
}
</style>
<template>
  <div class="col">
    <div @click="$emit('clickOnCard')" class="bidding-card">
      <div class="row">
        <div class="col-lg-3 col-sm-2 image-container">
          <img :src="productImage" alt="" />
        </div>
        <div class="col-lg-9 col-sm-10">
          <div class="product-name">
            <p v-if="this.$i18n.locale === 'en'">{{ productName }}</p>
            <p v-else-if="!productNameKh">{{ productName }}</p>
            <p v-else>{{ productNameKh }}</p>
          </div>
          <p class="resturant-name">{{ restaurantName }}</p>
          <p class="grey-text">
            {{ moment(createdAt, "YYYY-MM-DD h:mm:ss").fromNow() }}
          </p>
        </div>
      </div>
      <div class="column-text-wrapping">
        <div class="row-text">
          <p class="grey-text">{{ $t("finalCostText") }}</p>
          <p class="bold-text">${{ parseFloat(finalCost).toFixed(2) }}</p>
        </div>
        <div class="row-text">
          <p class="grey-text">{{ $t("maximumBiddingText") }}</p>
          <p class="bold-text">
            {{ bidCount }}/{{ maxBid }} {{ unitDisplayType }}
          </p>
        </div>
        <div class="row-text">
          <div class="column-text">
            <p class="grey-text">{{ $t("highCostText") }}</p>
            <p class="bid-price">${{ parseFloat(highBidRange).toFixed(2) }}</p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="38.158"
            viewBox="0 0 120 38.158"
          >
            <g id="Arrow" transform="translate(-805 -856.921)">
              <rect
                id="Rectangle"
                width="100"
                height="10"
                rx="5"
                transform="translate(805 871)"
                fill="#ff5252"
              />
              <path
                id="Polygon"
                d="M15.9,4.167a4,4,0,0,1,6.36,0L33.253,18.573A4,4,0,0,1,30.074,25H8.084A4,4,0,0,1,4.9,18.573Z"
                transform="translate(925 856.921) rotate(90)"
                fill="#ff5252"
              />
            </g>
          </svg>

          <div class="column-text">
            <p class="grey-text">{{ $t("lowCostText") }}</p>
            <p class="bid-price">${{ parseFloat(lowBidRange).toFixed(2) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "BiddingCard",
  props: {
    productImage: String,
    productName: String,
    productNameKh: {
      type: String,
      default: "",
    },
    restaurantName: String,
    createdAt: String,
    lowBidRange: Number,
    highBidRange: Number,
    finalCost: Number,
    maxBid: Number,
    bidCount: Number,
    unitDisplayType: String,
  },
  created: function () {
    this.moment = moment;
  },
  emits: ["clickOnCard"],
};
</script>

<style scoped>
/* bidding Card style */
.bidding-row-card {
  flex-wrap: nowrap;
}
.bidding-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 22px;
  transition: 0.3s;
}
.bidding-card:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.3s;
}
.image-container img {
  width: 100%;
  height: auto;
  border-radius: 5%;
}
.product-name p {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0px;
}
.resturant-name {
  font-size: 22px;
  font-weight: bold;
  color: #006633;
}
.column-text-wrapping {
  margin-top: 15px;
}
.row-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.column-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grey-text {
  color: #707070;
}
.bold-text {
  font-weight: bold;
}
p {
  font-size: 18px;
  margin-bottom: 5px;
}
.bid-price {
  font-size: 22px;
  font-weight: bold;
  color: #ff5252;
}
</style>
<template>
  <div class="col">
    <div class="base-card">
      <p v-if="title" class="card-header-text">{{ $t(title) }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.base-card {
  padding: 30px;
  margin: 30px;
  /* height: 100%; */
  border-radius: 22px;
  background-color: #fff;
}
.card-header-text {
  font-size: 24px;
  font-weight: bold;
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import NotFound from '../views/NotFound.vue'
import OtpVerification from '../views/OtpVerification.vue'
import Profile from '../views/Profile.vue'
import EditProfile from '../views/EditProfile.vue'
import RestaurantList from '../views/ShopList.vue'
import BiddingList from '../views/BiddingList.vue'
import SpecialOfferList from '../views/SpecialOfferList.vue'
import ProductInShop from '../views/ProductInShop.vue'
import CheckOut from '../views/CheckOut.vue'
import OrderList from '../views/OrderList.vue'
import OrderHistoryList from '../views/OrderHistoryList.vue'
import OrderDetail from '../views/OrderDetail.vue'
import Invoice from '../views/Invoice.vue'
import BiddingDetail from '../views/BiddingDetail.vue'
import MyBidding from '../views/MyBidding.vue'
import BiddedDetail from '../views/BiddedDetail.vue'
import RestaurantListByCategory from '../views/RestaurantByCategory.vue'
import SavedAddress from '../views/SavedAddress.vue'
import AddNewAddress from '../views/AddNewAddress.vue'
import EditSavedAddress from '../views/EditSavedAddress.vue'
import PrintInvoice from '../views/PrintInvoice.vue'
import ProductTracibility from '../views/ProductTracibility.vue'
import LuckyDraw from '../views/LuckyDraw.vue'
import NewProductTracibility from '../views/NewProductTracibility.vue'

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFound
  },
  {
    path: '/Signin',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/Signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/otpverification',
    name: 'OtpVerification',
    component: OtpVerification
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/restaurant-list',
    name: 'RestaurantList',
    component: RestaurantList
  },
  {
    path: '/bidding-list',
    name: 'BiddingList',
    component: BiddingList,
  },
  {
    path: '/special-offer-list',
    name: 'SpecialOfferList',
    component: SpecialOfferList,
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: EditProfile,
  },
  {
    path: '/shop/:id/:name',
    name: 'ProductInShop',
    component: ProductInShop
  },
  {
    path: '/check-out',
    name: 'CheckOut',
    component: CheckOut,
  },
  {
    path: '/order-list',
    name: 'OrderList',
    component: OrderList
  },
  {
    path: '/order-history-list',
    name: 'OrderHistoryList',
    component: OrderHistoryList
  },
  {
    path: '/ordre-detail/order-id=:orderId',
    name: 'OrderDetail',
    component: OrderDetail
  },
  {
    path: '/invoice',
    name: 'Invoice',
    component: Invoice
  },
  {
    path: '/bidding-detail',
    name: 'BiddingDetail',
    component: BiddingDetail,
  },
  {
    path: '/my-bidding',
    name: 'MyBidding',
    component: MyBidding
  },
  {
    path: '/bidded-detail',
    name: 'BiddedDetail',
    component: BiddedDetail
  },
  {
    path: '/restaurant-list/category_id=:categoryId&category_name=:categoryName',
    name: 'RestaurantListByCategory',
    component: RestaurantListByCategory
  },
  {
    path: '/saved-address-list',
    name: 'SavedAddress',
    component: SavedAddress
  },
  {
    path: '/add-new-address',
    name: 'AddNewAddress',
    component: AddNewAddress
  },
  {
    path: '/edit-address',
    name: 'EditSavedAddress',
    component: EditSavedAddress

  },
  {
    path: '/merchant_print_invoice/invoice/print/order_number=:orderNumber',
    name: 'PrintInvoice',
    component: PrintInvoice
  },
  {
    path: '/qr_product/code=:productCode',
    name: 'productTracibility',
    component: ProductTracibility
  },
  {
    path: '/lucky_draw_event/:luckyCode',
    name: 'luckyDraw',
    component: LuckyDraw
  },
  {
    path: '/qr_product/:productCode',
    name: 'newProductTracibility',
    component: NewProductTracibility
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <div>
    <vue-progress-bar></vue-progress-bar>
    <!-- Navigation Bar -->
    <NavigationBar
      v-if="!isNavBarHidded"
      @profileClicked="onProfileClicked()"
      @editProfileClicked="onEditProfileClicked()"
      @savedAddressesClicked="onSavedAddressesClicked()"
      @orderHistoryClicked="onOrderHistoryClicked()"
      @logOutClicked="onLogOutClicked()"
    />
    <router-view @onAppRun="setNavBarStatus($event)" />
  </div>
</template>

<script>
import NavigationBar from "./components/NavigationBar.vue";
export default {
  components: {
    NavigationBar,
  },
  data() {
    return {
      isNavBarHidded: false,
    };
  },
  created() {
    document.title = "Davane";
  },
  methods: {
    onProfileClicked() {
      this.$router.push("/profile");
    },
    onEditProfileClicked() {
      this.$router.push("/edit-profile");
    },
    onSavedAddressesClicked() {
      this.$router.push("/saved-address-list");
    },
    onOrderHistoryClicked() {
      this.$router.push("/order-history-list");
    },
    onLogOutClicked() {
      this.$store.dispatch("auth/logout");
      this.userInformation = [];
      this.getUserProfile = true;
      this.$router.push("/signin");
    },
    setNavBarStatus(value) {
      this.isNavBarHidded = value;
    },
    updateProfileData(value) {
      if (value == true) {
        alert("update profile");
      }
    },
  },
};
</script>


<style>
/* import google fonts api */
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Suwannaphum:wght@100;300;400;700&display=swap");

/* app style */
#app {
  font-family: "Rubik", "Suwannaphum", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#app-body {
  padding: 0;
  margin-top: 70px;
  width: 100%;
  min-height: calc(100vh - 70px);
  background-color: #eeeeee;
}

#app-body-no-nav {
  width: 100%;
  min-height: 100vh;
  background-color: #eeeeee;
}

@media only screen and (max-width: 934px) {
  #app-body {
    margin-top: 110px;
  }
}
</style>

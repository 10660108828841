<template>
  <div v-if="!isLoading" class="invoice-container">
    <div class="ticket">
      <div class="invoice-desc">
        <div class="store-desc">
          <img class="store-logo" :src="restaurantData.logo" />
          <p class="store-name">{{ restaurantData.name }}</p>
          <p>{{ restaurantData.phone }}</p>
          <p class="store-address">{{ restaurantData.address }}</p>
        </div>
        <div class="customer-data">
          <div class="customer">
            <p v-if="customerData.full_name">{{ customerData.full_name }}</p>
            <p v-else>
              {{ customerData.first_name + " " + customerData.last_name }}
            </p>
            <p>{{ customerData.phone }}</p>
          </div>
          <div class="order-datr">
            <p>
              {{
                moment(invoice.datetime, "YYYY-MM-DD h:mm:ss Z").format(
                  "MMM DD YYYY, LT"
                )
              }}
            </p>
          </div>
        </div>
      </div>
      <table>
        <th>
          <tr class="header-row">
            <th class="description">Items</th>
            <th class="quantity">Qty</th>
            <th class="price">Price</th>
          </tr>
        </th>
        <td>
          <!-- item area -->
          <template v-for="data in invoice.order_details" :key="data">
            <tr>
              <td class="description item-desc">
                {{ data.product.name }} / {{ data.product.name_kh }}
              </td>
              <td class="quantity item-desc">{{ data.qty }}</td>
              <td class="price item-desc">
                ${{ parseFloat(data.price).toFixed(2) }}
              </td>
            </tr>
          </template>
          <!-- subtotal and discount area -->
          <tr class="subTotal-row">
            <td colspan="2">Subtotal</td>
            <td class="price">
              ${{ parseFloat(invoice.subtotal_price).toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td colspan="2">Discount</td>
            <td class="price">
              ${{ parseFloat(invoice.total_discount).toFixed(2) }}
            </td>
          </tr>
          <!-- delivery fee -->
          <template v-if="shippingData">
            <tr>
              <td colspan="2">Delivery Fee</td>
              <td class="price">
                ${{ parseFloat(shippingData.charge_customer).toFixed(2) }}
              </td>
            </tr>
            <tr>
              <td colspan="2">Discount on delivery fee</td>
              <td class="price">
                ${{ parseFloat(shippingData.additional_discount).toFixed(2) }}
              </td>
            </tr>
          </template>
          <!-- Total ara -->
          <tr class="total-row">
            <td colspan="2">Total</td>
            <td class="price">
              ${{ parseFloat(invoice.total_price).toFixed(2) }}
            </td>
          </tr>
        </td>
      </table>
      <div class="payment-qr">
        <qrcode-vue
          :value="this.invoice.payment_list.zpoin"
          size="75"
          level="H"
        />
        <p>ZPOIN QR Payment</p>
      </div>
      <p class="bottom-text">Power by MakCirclr Co., Ltd</p>
    </div>
    <Button @click="printInvoice()" class="hidden-print print-btn"
      >Print</Button
    >
  </div>
</template>

<script>
import UserSerivce from "../services/user.service";
import Button from "../components/Button.vue";
import moment from "moment";
import QrcodeVue from "qrcode.vue";
export default {
  name: "PrintInvoice",
  components: {
    Button,
    QrcodeVue,
  },
  data() {
    return {
      isLoading: true,
      hideNavBar: true,
      orderNum: "",
      invoice: [],
      customerData: [],
      restaurantData: [],
      shippingData: [],
    };
  },
  created() {
    this.$emit("onAppRun", this.hideNavBar);
    this.moment = moment;
  },
  mounted() {
    this.orderNum = this.$route.params.orderNumber;
    this.getInvoice(this.orderNum);
  },
  methods: {
    getInvoice(orderNumber) {
      this.$Progress.start();
      UserSerivce.getInvoiceMerchant(orderNumber)
        .then((response) => {
          this.invoice = response.data.data;
          this.customerData = response.data.data.customer;
          this.restaurantData = response.data.data.restaurant;
          this.shippingData = response.data.data.shipping;
          this.$Progress.finish();
          this.isLoading = false;
          console.log(this.invoice.payment_list.zpoin);
        })
        .catch(() => {
          this.$Progress.fail();
        });
    },
    printInvoice() {
      window.print();
    },
  },
};
</script>

<style scoped>
.invoice-container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ticket {
  font-size: 12px;
  /* font-family: "Times New Roman"; */
}

table {
  display: inline-flex;
  flex-direction: column;
}

td,
th,
tr,
table {
  border-collapse: collapse;
}

td.description,
th.description {
  width: 150px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

tr.header-row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-collapse: collapse;
}

td.quantity,
th.quantity {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
  text-align: center;
}

td.price,
th.price {
  width: 60px;
  max-width: 60px;
  word-break: break-all;
  text-align: right;
}

.centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 250px;
  max-width: 250px;
}

.subTotal-row,
.total-row {
  border-top: 1px solid black;
  border-collapse: collapse;
}

.total-row {
  font-weight: 800;
}

.item-desc {
  padding: 5px 0px;
}

.store-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-address {
  font-size: 9px;
}

.store-logo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.store-desc {
  align-items: center;
}

.store-name {
  font-size: 15px;
}

.bottom-text {
  text-align: center;
  padding: 20px 0;
}

.print-btn {
  position: fixed;
  bottom: 10px;
}

p {
  margin: 0 !important;
}

.customer-data {
  margin: 10px 0;
}

.payment-qr {
  align-items: center;
  display: flex;
  /* justify-content: center; */
  /* align-content: center; */
  flex-direction: column;
}

@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
</style>
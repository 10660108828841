<template>
  <div class="error-message text-danger">
    <div class="label-icon">
      <unicon name="exclamation-triangle" fill="#dc3545" />
    </div>
    {{ $t(message) }}
  </div>
</template>

<script>
export default {
  name: "BaseErrorMessage",
  props: {
    message: {
      type: String,
      default() {
        throw new Error("No message data for BaseErrorMessage Component. Please add message data as a props or delete this component if not used!");
      },
    },
  },
};
</script>

<style scoped>
.error-message {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.label-icon {
  padding-right: 15px;
}
</style>
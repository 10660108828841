<template>
  <div id="app-body">
    <div class="container-fluid">
      <div class="row">
        <BaseCard>
          <!-- title section -->
          <div class="card-title">
            <p class="card-header-text">{{ $t("addNewAddressButtonText") }}</p>
            <p class="card-desc-text">{{ $t("addNewAddressDescText") }}</p>
            <hr />
          </div>
          <!-- body section -->
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="input-field">
                <BaseInput
                  v-model="label"
                  name="label"
                  label="locationNameLabelText"
                  labelIcon="map-marker-info"
                  inputPlaceHolder="locationNameLabelText"
                />
                <BaseErrorMessage
                  v-if="!labelIsValid"
                  message="labelRequiredMsg"
                />
              </div>
              <div class="input-field">
                <BaseInput
                  v-model="address"
                  name="address"
                  label="addressLabelText"
                  labelIcon="map-marker"
                  inputPlaceHolder="addressLabelText"
                />
                <BaseErrorMessage
                  v-if="!addressIsValid"
                  message="addressRequiredMsg"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <GoogleMap
                ref="mapRef"
                api-key="AIzaSyDCjo8FullVMLc9MxpihKZpy4gwhmJP4yA"
                class="map"
                :center="currentPosition"
                :zoom="15"
                :draggable="true"
                :mapTypeControl="false"
                :keyboardShortcuts="false"
                :fullscreenControl="false"
                :streetViewControl="false"
                ><Marker :options="{ position: currentPosition }" />
              </GoogleMap>
            </div>
          </div>
          <Button @click="saveNewAddress()" :disabled="!formIsValid">{{
            $t("saveButtonText")
          }}</Button>
          <!-- bottom section -->
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import BaseInput from "../components/BaseInput.vue";
import Button from "../components/Button.vue";
import { GoogleMap, Marker } from "vue3-google-map";
import { useGeolocation } from "../mixins/useGeolocation";
import { computed } from "@vue/reactivity";
import BaseErrorMessage from "../components/BaseErrorMessage.vue";
import { useToast } from "vue-toastification";
import UserService from "../services/user.service";
export default {
  components: {
    BaseCard,
    BaseInput,
    Button,
    GoogleMap,
    Marker,
    BaseErrorMessage,
  },
  name: "AddNewAddress",
  setup() {
    const toast = useToast();
    const { coords } = useGeolocation();
    const currentPosition = computed(() => ({
      lat: coords.value.latitude,
      lng: coords.value.longitude,
    }));
    return { toast, currentPosition };
  },
  data() {
    return {
      label: "",
      address: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    labelIsValid() {
      return !!this.label;
    },
    addressIsValid() {
      return !!this.address;
    },
    formIsValid() {
      return this.labelIsValid && this.addressIsValid;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    }
  },
  methods: {
    saveNewAddress() {
      this.$Progress.start();
      // initialize form Data
      let formData = new FormData();
      formData.append("lat", this.currentPosition.lat);
      formData.append("lng", this.currentPosition.lng);
      formData.append("label", this.label);
      formData.append("address", this.address);

      // make a post request
      UserService.postStoreAddress(formData)
        .then(() => {
          this.$Progress.finish();
          this.toast.success("New Address have been saved successfully.");
          this.$router.push("/saved-address-list");
        })
        .catch(() => {
          this.$$Progress.fail();
          this.toast.error("Something went wrong! could not save new address.");
        });
    },
  },
};
</script>

<style scoped>
.card-header-text {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.card-desc-text {
  font-size: 18px;
  margin: 0;
  color: #707070;
}
.input-field {
  margin-bottom: 30px;
}
.map {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 20px;
}
@media only screen and (max-width: 767px) {
  .map {
    position: relative;
    width: 100%;
    height: 250px;
    display: inline-block;
    border-radius: 16px;
    overflow: hidden;
    margin-top: 20px;
  }
}
</style>
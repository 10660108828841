<template>
  <div>
    <div v-if="!showConfirmModal" class="modal-mask">
      <div @click="$emit('closeModal')" class="modal-wrapper">
        <div @click.stop="" class="modal-container">
          <div class="top-section">
            <!-- top card section -->
            <div class="top-container">
              <div class="detail-container">
                <!-- product summay detail container -->
                <div class="detail-row">
                  <img class="product-img" :src="productImage" alt="" />
                  <!-- product name/badge container -->
                  <div class="detail-and-badge">
                    <div class="product-name">
                      <p v-if="this.$i18n.locale === 'en'">{{ productName }}</p>
                      <p v-else-if="!productNameKh">{{ productName }}</p>
                      <p v-else>{{ productNameKh }}</p>
                    </div>
                    <!-- discount/pre-order bader container -->
                    <div class="badge-container">
                      <!-- pre-order badge -->
                      <div v-if="!preOrderLabel" class="pre-order-badge">
                        {{ $t("preOrderLabel") }}
                      </div>
                      <!-- discount badge -->
                      <div v-if="discount" class="discount-badge">
                        {{ $t("discountText") }} {{ discount }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- price row container -->
                <div class="prices-row">
                  <p class="actual-price">
                    ${{ parseFloat(prices[0].actual_price).toFixed(2) }}
                  </p>
                  <p
                    v-show="prices[0].actual_price != prices[0].price"
                    class="full-price"
                  >
                    ${{ parseFloat(prices[0].price).toFixed(2) }}
                  </p>
                </div>
              </div>
              <hr />
            </div>
            <!-- body section -->
            <div class="body-section">
              <div class="container">
                <!-- add to cart section -->
                <div v-if="!preOrderLabel" class="pre-order-msg">
                  <div class="icon">
                    <unicon name="exclamation" fill="#ff5252" />
                  </div>
                  <p class="pre-order-text">{{ $t("preOrderText") }}</p>
                </div>
                <!-- price per unit label text -->
                <!-- <div v-if="pricePerUnit" class="d-flex price-label-container">
                  <div class="d-flex higlight-label">
                    <p class="price-per-label">{{ $t("pricePerText") }}</p>
                    <p class="unit-type-label">{{ unitDisplayType }} :</p>
                  </div>
                  <p class="price-and-unit-text">
                    ${{ parseFloat(pricePerUnit).toFixed(2) }}/{{
                      unitDisplayType
                    }}
                  </p>
                </div> -->
                <!-- Product price selection -->
                <div class="option-section">
                  <p class="label-text">{{ $t("priceLabel") }}:</p>
                  <!-- produtct price selector -->
                  <div class="price-selector">
                    <label
                      v-for="priceDetail in prices"
                      :key="priceDetail"
                      class="radio-toggle-btn"
                    >
                      <input
                        type="radio"
                        v-model="selectedPrice"
                        :value="priceDetail.product_type_id"
                        name="price"
                      />
                      <span class="price-label">
                        {{ priceDetail.qty + " " + priceDetail.name }}: ${{
                          parseFloat(priceDetail.actual_price).toFixed(2)
                        }}
                      </span>
                    </label>
                  </div>
                </div>
                <!-- topping selecor for drink -->
                <div v-if="isFood">
                  <div
                    v-for="(topping, toppingIndex) in toppings"
                    :key="toppingIndex"
                    class="topping-selector-group"
                  >
                    <p class="label-text">{{ topping.name }}</p>
                    <div
                      v-for="toppingChild in topping.children"
                      :key="toppingChild"
                      class="topping-selector"
                    >
                      <div class="checkbox-input">
                        <input
                          type="radio"
                          :value="toppingChild.id"
                          v-model="choosedToppings[toppingIndex]"
                        />
                        <label class="checkbox-label">{{
                          toppingChild.name
                        }}</label>
                      </div>
                      <p class="topping-price">
                        ${{ parseFloat(toppingChild.price).toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- bottom card section -->
          <div>
            <hr />
            <div class="bottom-card-section">
              <!-- quantity controller -->
              <div class="quantity-controller">
                <div
                  @click="decreaseQuantity()"
                  :class="{ 'quantity-controller-button-disabled': isDisable }"
                  class="quantity-controller-button"
                >
                  <unicon name="minus" fill="#ffffff" />
                </div>
                <p class="quantity-number">{{ quantity }}</p>
                <div
                  @click="increaseQuantity()"
                  class="quantity-controller-button"
                >
                  <unicon name="plus" fill="#ffffff" />
                </div>
              </div>
              <!-- checkout button -->
              <Button
                @click="addToCart(productId, quantity, selectedPrice)"
                :disabled="!selectedPrice"
                >{{ $t("addToCartText") }}</Button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <ConfirmPopupModal
        @onCancelClicked="onCancel()"
        @onConfirmClicked="onConfirm()"
        :description="this.errorMessage"
      />
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import ConfirmPopupModal from "./ConfirmPopupModal.vue";
import UserService from "../services/user.service";
export default {
  components: { Button, ConfirmPopupModal },
  name: "AddToCartModal",
  data() {
    return {
      isDisable: true,
      showConfirmModal: false,
      errorMessage: "",
      selectedPrice: "",
      quantity: "1",
      choosedToppings: {},
    };
  },
  props: {
    productId: Number,
    productImage: String,
    productName: String,
    productNameKh: {
      type: String,
      default: null,
    },
    discount: {
      type: String,
      default: "",
    },
    preOrderLabel: {
      type: Boolean,
      default: true,
    },
    prices: Array,
    pricePerUnit: {
      type: Number,
      default: "",
    },
    unitDisplayType: String,
    toppings: {
      type: Object,
      default: null,
    },
    isFood: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal", "buttonClicked", "updateCart"],
  methods: {
    increaseQuantity() {
      this.quantity++;
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        return this.quantity--;
      }
    },
    addToCart(productId, quantity, productType) {
      this.$Progress.start();
      // initialize form data
      let formData = new FormData();
      let toppings = [];
      for (var key of Object.keys(this.choosedToppings)) {
        toppings.push(this.choosedToppings[key]);
      }
      formData.append("product_id", productId);
      formData.append("qty", quantity);
      formData.append("product_type", productType);
      formData.append("restaurant_id", '1');

      if (toppings.length > 0) {
        formData.append("topping_id", "[" + toppings + "]");
      }

      // post product ot cart
      UserService.postAddToCart(formData)
        .then(() => {
          this.$emit("updateCart");
          this.$Progress.finish();
          this.$emit("closeModal");
        })
        .catch((error) => {
          this.errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.showConfirmModal = true;
          this.$Progress.fail();
        });
    },
    onCancel() {
      this.$emit("closeModal");
    },
    onConfirm() {
      this.$Progress.start();
      // Delete all item from current cart
      UserService.deleteAllCurrentCart().then(() => {
        // Add new item to cart
        this.addToCart(this.productId, this.quantity, this.selectedPrice);
        this.$Progress.finish();
        this.$emit("closeModal");
      });
    },
  },
  updated() {
    if (this.quantity >= 2) {
      return (this.isDisable = false);
    } else {
      return (this.isDisable = true);
    }
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 45%;
  height: 85vh;
  padding: 30px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top-section {
  display: flex;
  flex-direction: column;
  height: 85%;
}
.detail-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.detail-row {
  display: flex;
}
.detail-and-badge {
  margin-left: 20px;
}
.product-name,
.prices-row {
  font-size: 24px;
  font-weight: 500;
  display: -webkit-box;
  max-width: 350px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product-img {
  width: 15vh;
  height: 15vh;
  object-fit: cover;
  border-radius: 22px;
}
.actual-price {
  color: #ff5252;
  margin-right: 20px;
}
.full-price {
  color: #707070;
  text-decoration: line-through;
}
.prices-row {
  display: flex;
}
.badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.discount-badge {
  padding: 5px 15px;
  color: #ffffff;
  border-radius: 20px;
  background-color: #95C125;
  margin-right: 10px;
}
.pre-order-badge {
  padding: 5px 15px;
  color: #ffffff;
  border-radius: 20px;
  background-color: #f44336;
  margin-right: 10px;
}
.pre-order-msg {
  display: flex;
  background-color: rgba(255, 82, 82, 0.2);
  padding: 10px;
  border-radius: 12px;
}
.pre-order-text {
  font-size: 18px;
  margin: 0;
  color: #f44336;
}
.option-section {
  margin-top: 20px;
}
.label-text {
  font-size: 24px;
}
.radio-toggle-btn span {
  padding: 10px 25px;
  margin-right: 20px;
  background-color: #eeeeee;
  border-radius: 22px;
  cursor: pointer;
}
.radio-toggle-btn input:checked + span {
  background-color: #95C125;
  color: #ffffff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
.option-section input[type="radio"] {
  visibility: hidden;
  display: none;
}
.quantity-controller {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 120px;
}
.quantity-controller-button {
  background-color: #95C125;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}
.quantity-controller-button-disabled {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #707070;
  cursor: default;
}
.bottom-card-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quantity-number {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.unit-type-label {
  margin-left: 5px;
}
.price-label-container p {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
}
.unit-type-label,
.price-and-unit-text {
  margin-right: 10px;
  text-transform: lowercase;
}
.higlight-label {
  color: #95C125;
}
.price-label {
  font-size: 18px;
  font-weight: 500;
  text-transform: lowercase;
}
.uppercase-text {
  text-transform: capitalize;
}
label.radio-toggle-btn {
  margin: 15px 0;
}
.topping-selector-group {
  margin: 20px 0;
}
.topping-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}
.topping-price {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}
.topping-selector input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin-right: 20px;
  font: inherit;
  color: currentColor;
  width: 30px;
  height: 30px;
  border: 0.15em solid currentColor;
  border-radius: 8px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
.topping-selector input[type="radio"]::before {
  content: "";
  width: 1em;
  height: 1em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #ffffff;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.topping-selector input[type="radio"]:checked::before {
  transform: scale(1);
}
.topping-selector input[type="radio"]:checked {
  background-color: #95C125;
  border-color: #95C125;
}
.checkbox-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}
label.checkbox-label {
  margin: 0;
  font-size: 18px;
}
.body-section {
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media only screen and (max-width: 1200px) {
  .modal-container {
    width: 65%;
    height: 85vh;
  }
}
@media only screen and (max-width: 768px) {
  .modal-container {
    width: 85%;
    height: 85vh;
  }
}
</style>
<template>
  <div id="app-body">
    <!-- app body -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-8 col-md-6">
          <!-- order type card -->
          <div class="action-card">
            <p class="action-card-title">{{ $t("orderTypeText") }}</p>
            <div class="order-type-selector-container">
              <!-- take out -->
              <div class="order-type-selector">
                <label class="radio-toggle-btn">
                  <input
                    type="radio"
                    v-model="selectedOrderType"
                    value="1"
                    name="take-out"
                  />
                  <span class="order-type-option">
                    <unicon
                      name="shopping-bag"
                      fill="currentColor"
                      height="52"
                      width="52"
                    />
                    {{ $t("takeOutText") }}
                  </span>
                </label>
                <!-- dine in -->
                <div class="order-type-selector">
                  <label class="radio-toggle-btn">
                    <input
                      type="radio"
                      v-model="selectedOrderType"
                      value="0"
                      name="dine-in"
                    />
                    <span class="order-type-option">
                      <unicon
                        name="crockery"
                        fill="currentColor"
                        height="52"
                        width="52"
                      />
                      {{ $t("dineInText") }}
                    </span>
                  </label>
                </div>
                <!-- booking -->
                <div class="order-type-selector">
                  <label class="radio-toggle-btn">
                    <input
                      type="radio"
                      v-model="selectedOrderType"
                      value="3"
                      name="booking"
                    />
                    <span class="order-type-option">
                      <unicon
                        name="calendar-alt"
                        fill="currentColor"
                        height="52"
                        width="52"
                      />
                      {{ $t("bookingText") }}
                    </span>
                  </label>
                </div>
                <!-- delivery -->
                <div class="order-type-selector">
                  <label class="radio-toggle-btn">
                    <input
                      type="radio"
                      v-model="selectedOrderType"
                      value="2"
                      name="delivery"
                    />
                    <span class="order-type-option">
                      <unicon
                        name="truck"
                        fill="currentColor"
                        height="52"
                        width="52"
                      />
                      {{ $t("deliveryText") }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- select saved address card appear on delivery selected -->
          <div v-if="deliverySelected" class="action-card">
            <div class="row">
              <div class="col-lg-6">
                <p class="action-card-title">{{ $t("deliveryAddressText") }}</p>
                <div class="saved-address-selecte">
                  <p class="desc-text">{{ $t("deliveryAddressDescText") }}</p>
                  <!-- address option selector -->
                  <div class="address-selector">
                    <select v-model="selectedAddress">
                      <option value="" disabled selected hidden>
                        {{ $t("deliveryPlaceHolderText") }}
                      </option>
                      <option
                        v-for="savedAddress in savedAddresses"
                        :key="savedAddress.id"
                        :value="savedAddress"
                      >
                        <p>{{ savedAddress.label }}</p>
                      </option>
                    </select>
                  </div>
                  <!-- error msg -->
                  <base-error-message
                    v-if="!deliveryAddressIsValid"
                    message="deliveryAddressErrorMsg"
                  />
                </div>
                <hr />
                <div>
                  <p class="desc-text">{{ $t("addNewAddressTitleText") }}</p>
                  <Button @click="addNewAddress()" class="new-address-btn">{{
                    $t("addNewAddressButtonText")
                  }}</Button>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- map -->
                <div class="choose-address-on-map">
                  <GoogleMap
                    ref="mapRef"
                    api-key="AIzaSyDCjo8FullVMLc9MxpihKZpy4gwhmJP4yA"
                    class="map"
                    :center="{ lat: lat, lng: lng }"
                    :zoom="15"
                    :draggable="false"
                    :mapTypeControl="false"
                    :keyboardShortcuts="false"
                    :fullscreenControl="false"
                    :streetViewControl="false"
                    ><Marker :options="{ position: { lat: lat, lng: lng } }" />
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
          <!-- pick up time card -->
          <div class="action-card">
            <p class="action-card-title">{{ $t("pickUpTimeTitle") }}</p>
            <p class="desc-text">{{ $t("pickUpTimeDescText") }}</p>
            <div class="row">
              <div class="col-lg-6 input-column">
                <label for="pickUpDate">{{ $t("dateLabelText") }} </label>
                <input
                  v-model="pickUpDate"
                  type="date"
                  name="pickUpDate"
                  id="pickUpDate"
                />
                <!-- error msg -->
                <base-error-message
                  v-if="!pickUpDateIsValid"
                  message="bookingDateErrorMsg"
                />
              </div>
              <div class="col-lg-6 input-column">
                <label for="pickUpTime">{{ $t("timeLabelText") }} </label>
                <input
                  v-model="pickUpTime"
                  type="time"
                  name="pickUpTime"
                  id="pickUpTime"
                />
                <!-- error msg -->
                <base-error-message
                  v-if="!pickUpTimeIsValid"
                  message="bookingTimeErrorMsg"
                />
              </div>
            </div>
          </div>
          <!-- payment method -->
          <div class="action-card">
            <p class="action-card-title">{{ $t("paymentMethodLabel") }}</p>
            <div class="payment-methode-container">
              <!-- cash methode option -->
              <div class="order-type-selector">
                <label class="radio-toggle-btn">
                  <input
                    type="radio"
                    v-model="paymentMethod"
                    value="0"
                    name="cash"
                  />
                  <span class="order-type-option">
                    <unicon
                      name="wallet"
                      fill="currentColor"
                      height="52"
                      width="52"
                    />
                    {{ $t("cashLabelText") }}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <!-- add message to store -->
          <div class="action-card">
            <p class="action-card-title">{{ $t("addMessageToStoreLabel") }}</p>
            <div>
              <textarea
                v-model.lazy="message"
                class="msg-text-area"
                name="add"
                id=""
                :placeholder="$t('writeMessagePlaceHolder')"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 check-out-card-container">
          <CheckOutCard
            @buttonClicked="placeAnOrder()"
            :subTotalPrice="shoppingCart.subtotal"
            :discountPrice="shoppingCart.total_discount"
            :totalPrice="shoppingCart.total"
            :countItemInCart="cartItems.length"
            :isDelivery="deliverySelected"
            :checkOutIsValid="formIsValid"
            :distances="deliveryInfo.distances"
            :deliveryPrice="deliveryInfo.charge_customer"
            :deliveryDiscountPrice="deliveryInfo.total_discount"
            :totalPriceWithDelivery="deliveryInfo.total_price"
          >
            <cart-item-list
              v-for="cartItem in shoppingCart.details"
              :key="cartItem.id"
              :isEditable="false"
              :isCheckOut="true"
              :productImage="cartItem.product.feature_image"
              :productName="cartItem.product.name"
              :productNameKh="cartItem.product.name_kh"
              :subTotal="cartItem.prices.price"
              :actualTotal="cartItem.prices.actual_price"
              :qty="cartItem.qty"
              :productTypeQty="cartItem.prices.qty"
              :productType="cartItem.prices.name"
              :productId="cartItem.id"
              :preOrderLabel="cartItem.product.status"
              :toppings="cartItem.topping"
            />
          </CheckOutCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseErrorMessage from "../components/BaseErrorMessage.vue";
import CartItemList from "../components/CartItemList.vue";
import CheckOutCard from "../components/CheckOutCard.vue";
import UserService from "../services/user.service";
import { GoogleMap, Marker } from "vue3-google-map";
import BaseRadioButton from "../components/BaseRadioButton.vue";
import Button from "../components/Button.vue";
import { useToast } from "vue-toastification";
import { defineComponent, ref, computed, watch } from "vue";
export default defineComponent({
  name: "CheckOut",
  components: {
    CheckOutCard,
    CartItemList,
    BaseErrorMessage,
    GoogleMap,
    Marker,
    BaseRadioButton,
    Button,
  },
  setup() {
    const mapRef = ref(null);
    const center = { lat: 11.5749, lng: 104.9257 };

    const _lng = ref(104.9257);
    const lng = computed({
      get: () => _lng.value,
      set: (v) => {
        if (!Number.isFinite(v)) {
          _lng.value = 0;
        } else if (v > 180) {
          _lng.value = 180;
        } else if (v < -180) {
          _lng.value = -180;
        } else {
          _lng.value = v;
        }
      },
    });

    const _lat = ref(11.5749);
    const lat = computed({
      get: () => _lat.value,
      set: (w) => {
        if (!Number.isFinite(w)) {
          _lat.value = 0;
        } else if (w > 90) {
          _lat.value = 90;
        } else if (w < -90) {
          _lat.value = -90;
        } else {
          _lat.value = w;
        }
      },
    });

    watch((lat, lng), () => {
      if (mapRef.value?.ready) {
        mapRef.value.map.panTo({ lat: lat.value, lng: lng.value });
      }
    });

    const toast = useToast();
    return { mapRef, center, lat, lng, toast };
  },
  data() {
    return {
      errorMessage: "",
      selectedOrderType: "1",
      deliverySelected: false,
      selectedAddress: [],
      lat: "",
      lng: "",
      pickUpDate: "",
      pickUpTime: "",
      paymentMethod: "0",
      message: "",
      shoppingCart: [],
      cartItems: [],
      savedAddresses: [],
      deliveryInfo: [],
    };
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      // get cart iteam and total price
      UserService.getShoppingCartList().then((response) => {
        if (response.data.data) {
          this.shoppingCart = response.data.data;
          this.cartItems = response.data.data.details;
        }
      });
      // get saved address
      UserService.getSavedAddressList().then((response) => {
        this.savedAddresses = response.data.data;
      });
    }
  },
  watch: {
    // update delivery status on select order type == 2
    selectedOrderType(newValue) {
      if (newValue == 2) {
        this.deliverySelected = true;
      } else {
        this.deliverySelected = false;
      }
    },

    // update lat and lng and get delivery fee after updating lat, lng
    selectedAddress(newValue) {
      this.lat = parseFloat(newValue.lat);
      this.lng = parseFloat(newValue.lng);
      // get delivery fee
      this.getDeliveryFee();
    },

    // get delivery fee discount on booking data time update
    pickUpDateTime(newValue) {
      // get delivery fee
      this.getDeliveryFee();
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    // validate user input for selecting booking
    pickUpDateIsValid() {
      if (this.selectedOrderType == 3) {
        return !!this.pickUpDate;
      } else {
        return true;
      }
    },

    pickUpTimeIsValid() {
      if (this.selectedOrderType == 3) {
        return !!this.pickUpTime;
      } else {
        return true;
      }
    },

    deliveryAddressIsValid() {
      if (this.selectedAddress.length === 0) {
        return false;
      } else {
        return true;
      }
    },

    formIsValid() {
      if (this.selectedOrderType == 3) {
        return this.pickUpDateIsValid && this.pickUpTimeIsValid;
      } else if (this.selectedOrderType == 2) {
        return this.deliveryAddressIsValid;
      } else {
        return true;
      }
    },

    pickUpDateTime() {
      return `${this.pickUpDate} ${this.pickUpTime}`;
    },
  },
  methods: {
    // place order
    placeAnOrder() {
      this.$Progress.start();
      // initialize a formdata
      let formData = new FormData();
      formData.append("payment_method", this.paymentMethod);
      formData.append("order_type", this.selectedOrderType);
      formData.append("note", this.message);
      formData.append("booking_date_time", this.pickUpDateTime);
      formData.append("lat", this.selectedAddress.lat);
      formData.append("lng", this.selectedAddress.lng);
      formData.append("address", this.selectedAddress.address);

      // make a post request
      UserService.postPlaceOrders(formData)
        .then(() => {
          this.toast.success("Your order have been placed!");
          this.$router.push("/home");
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.fail();
          this.errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.toast.error(this.errorMessage);
        });
    },

    // get delivery fee
    getDeliveryFee() {
      // initialize formData
      let formData = new FormData();
      formData.append("cus_lat", this.selectedAddress.lat);
      formData.append("cus_lng", this.selectedAddress.lng);
      // formData.append("address", this.location.address)
      if (!!this.pickUpDate && !!this.pickUpTime) {
        formData.append("booking_date", this.pickUpDateTime);
      } else {
        formData.append("total_price", this.shoppingCart.total);
      }
      UserService.postGetDeliveryFee(formData)
        .then((response) => {
          this.deliveryInfo = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addNewAddress() {
      this.$router.push("/add-new-address");
    },
  },
});
</script>

<style scoped>
.check-out-card-container {
  overflow-y: hidden;
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
}
/* order type card style */
.action-card {
  margin: 35px 20px 5px 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 22px;
}
.action-card-title {
  font-size: 22px;
  font-weight: 500;
}
.radio-toggle-btn {
  display: flex;
  flex-direction: row;
}
.radio-toggle-btn span {
  padding: 20px 25px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #eeeeee;
  border-radius: 22px;
  cursor: pointer;
}
.radio-toggle-btn input:checked + span {
  background-color: #95C125;
  color: #ffffff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
input[type="radio"] {
  visibility: hidden;
}
.order-type-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.order-type-selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.desc-text {
  font-size: 18px;
  color: #707070;
}
.address-selector select {
  width: 100%;
  height: 45px;
  border-radius: 12px;
}
.input-column {
  display: flex;
  flex-direction: column;
}
.input-column input {
  height: 45px;
  border: 1px solid;
  border-radius: 12px;
  padding: 10px;
}
.msg-text-area {
  width: 100%;
  border-radius: 12px;
  padding: 10px;
}
.mapdiv {
  border-radius: 12px;
}
.new-address-btn {
  margin: 10px 0;
}
@media only screen and (max-width: 768px) {
  .check-out-card-container {
    overflow-y: hidden;
    position: relative;
    top: 0;
    margin: 20px 0;
  }
  .card-container {
    padding: 0;
    margin: 0;
  }
}
.map {
  position: relative;
  width: 100%;
  height: 280px;
  display: inline-block;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 20px;
}
</style>
<template>
  <div class="shop-detail-card">
    <!-- shop summary detail -->
    <div>
      <div class="detail-row">
        <div class="shop-logo">
          <img class="rounded-circle" :src="logo" alt="" />
        </div>
        <div class="column-text">
          <div class="row-text">
            <p class="shop-name">{{ name }}</p>
            <p class="open-time">
              {{ timeConverter(openTime) }} - {{ timeConverter(closeTime) }}
            </p>
          </div>
          <div @click="isShowDetail = !isShowDetail" class="icon-button">
            <div class="icon">
              <unicon name="info-circle" fill="#707070" />
            </div>
          </div>
        </div>
      </div>
      <!-- shop deatil -->
      <div v-if="isShowDetail">
        <hr />
        <div class="detail">
          <p class="grey-text label-text">{{ $t("shopInformationLabel") }}</p>
          <!-- two detail cards row -->
          <div class="row row-cols-1 row-cols-md-2 g-4">
            <!-- single detail card -->
            <div class="col">
              <div class="detail-card">
                <p class="grey-text label-text">
                  {{ $t("contactNumberLabel") }}
                </p>
                <div class="row-text-with-icon">
                  <div class="icon">
                    <unicon name="phone-volume" fill="#006633" />
                  </div>
                  <p class="contact-number">{{ phone }}</p>
                </div>
              </div>
            </div>
            <!-- signgle detail card -->
            <div class="col">
              <div class="detail-card">
                <p class="grey-text label-text">{{ $t("openLabelText") }}</p>
                <div class="row-text-with-icon">
                  <div class="icon">
                    <unicon name="clock-eight" fill="#000000" />
                  </div>
                  <p class="detail-text">
                    {{ timeConverter(openTime) }} -
                    {{ timeConverter(closeTime) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- single detail card -->
          <div class="detail-card">
            <p class="grey-text label-text">{{ $t("addressLabelText") }}</p>
            <div class="row-text-with-icon">
              <div class="icon">
                <unicon name="map-marker" fill="#000000" />
              </div>
              <p class="detail-text">
                {{ address }}
              </p>
            </div>
          </div>
        </div>
        <!-- shop photo gallery -->
        <div class="shop-photo-gallery">
          <p class="grey-text label-text">{{ $t("photoLabelText") }}</p>
          <div class="photo-carousel">
            <swiper
              :slides-per-view="1"
              :space-between="30"
              :speed="800"
              :autoplay="autoplay"
              :breakpoints="slideBreakPoint"
            >
              <swiper-slide
                v-for="featureImage in featureImages[0]"
                :key="featureImage"
              >
                <img class="carousel-img" :src="featureImage.source" />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeConverterMixin from "../mixins/timeConverter";
import Button from "./Button.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export default {
  name: "BaseShopDetailCard",
  components: {
    Button,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isShowDetail: false,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slideBreakPoint: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
      },
    };
  },
  props: {
    logo: String,
    name: String,
    openTime: {
      type: String,
      default: "00:00:00",
    },
    closeTime: {
      type: String,
      default: "00:00:00",
    },
    phone: String,
    address: String,
    featureImages: Object,
    rating: String,
  },
  mixins: [TimeConverterMixin],
};
</script>

<style scoped>
.shop-detail-card {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 22px;
  margin-bottom: 50px;
}
.shop-logo img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #eeeeee;
}
.detail-row {
  display: flex;
  align-items: center;
}
.row-text {
  margin-left: 25px;
}
.shop-name {
  font-size: 32px;
  font-weight: 500;
}
.open-time {
  font-size: 18px;
}
.icon-button {
  width: 40px;
  height: 40px;
  background-color: #d5d5d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.column-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.grey-text {
  color: #707070;
}
.label-text {
  font-size: 24px;
  margin: 0;
}
.row-text-with-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.contact-number {
  font-size: 22px;
  color: #006633;
  margin: 0 0 0 20px;
}
.detail-text {
  font-size: 22px;
  color: #000000;
  margin: 0 0 0 20px;
}
.detail-card {
  background: #eeeeee;
  border-radius: 22px;
  padding: 10px 20px;
  margin-top: 20px;
}
.shop-photo-gallery {
  margin: 20px 0;
}
.carousel__item img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 22px;
}
.photo-carousel {
  margin-top: 20px;
}
.write-review-container {
  margin: 20px 0;
}
.review-input-box textarea {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  height: 80px;
  border-radius: 22px;
  border: none;
  background-color: #eeeeee;
}
.submit-btn {
  display: flex;
  justify-content: flex-end;
}
.carousel-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  object-fit: cover;
}
</style>
<template>
  <div class="container-fluid">
    <div class="restaurant-info">
      <div class="img-container">
        <img class="restaurant-logo" :src="restaurantLogo" alt="" />
      </div>
      <div class="restaurant-name-phone">
        <p class="restaurant-name">{{ restaurantName }}</p>
        <p class="restaurant-phone">{{ restaurantPhone }}</p>
      </div>
    </div>
    <hr />
    <div class="bidding-detail">
      <div class="row">
        <div class="col-lg-3 col-md-5">
          <div class="img-container">
            <img class="product-img" :src="productImage" alt="" />
          </div>
        </div>
        <div class="col-lg-9 col-md-7">
          <div class="product-name">
            <p v-if="this.$i18n.locale === 'en'">{{ productName }}</p>
            <p v-else-if="!productNameKh">{{ productName }}</p>
            <p v-else>{{ productNameKh }}</p>
          </div>
          <div>
            <div class="row-text">
              <p class="label-text">{{ $t("startPriceText") }}</p>
              <p class="bold-text">${{ parseFloat(startedCost).toFixed(2) }}</p>
            </div>
            <div class="row-text">
              <p class="label-text">{{ $t("finalCostText") }}</p>
              <p class="bold-text">${{ parseFloat(finalCost).toFixed(2) }}</p>
            </div>
            <div class="row-text">
              <p class="label-text">{{ $t("maximumBiddingText") }}</p>
              <p class="bold-text lower-case-text">
                {{ currentBid }} / {{ maxBid }} {{ unitDisplayType }}
              </p>
            </div>
            <div class="row-text">
              <p class="label-text">{{ $t("startDateText") }}</p>
              <p class="bold-text">
                {{ moment(startDate).format("MMM DD, YYYY h:mm A") }}
              </p>
            </div>
            <div class="row-text">
              <p class="label-text">{{ $t("endDateText") }}</p>
              <p class="bold-text">
                {{ moment(endDate).format("MMM DD, YYYY h:mm A") }}
              </p>
            </div>
            <hr />
            <div class="row-text">
              <div class="col-text">
                <p class="label-text">{{ $t("highCostText") }}</p>
                <p class="bold-text price-label">
                  ${{ parseFloat(highestPrice).toFixed(2) }}
                </p>
              </div>
              <!-- svg -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="120"
                height="38.158"
                viewBox="0 0 120 38.158"
              >
                <g id="Arrow" transform="translate(-805 -856.921)">
                  <rect
                    id="Rectangle"
                    width="100"
                    height="10"
                    rx="5"
                    transform="translate(805 871)"
                    fill="#ff5252"
                  />
                  <path
                    id="Polygon"
                    d="M15.9,4.167a4,4,0,0,1,6.36,0L33.253,18.573A4,4,0,0,1,30.074,25H8.084A4,4,0,0,1,4.9,18.573Z"
                    transform="translate(925 856.921) rotate(90)"
                    fill="#ff5252"
                  />
                </g>
              </svg>

              <div class="col-text">
                <p class="label-text">{{ $t("lowCostText") }}</p>
                <p class="bold-text price-label">
                  ${{ parseFloat(lowestPrice).toFixed(2) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "BiddingDetailCard",
  props: {
    restaurantLogo: {
      type: String,
      default: "",
    },
    restaurantName: {
      type: String,
      default: "",
    },
    restaurantPhone: {
      type: String,
      default: "",
    },
    productImage: {
      type: String,
      default: "",
    },
    productName: {
      type: String,
      default: "",
    },
    productNameKh: {
      type: String,
      default: "",
    },
    startedCost: {
      type: Number,
      default: "",
    },
    finalCost: {
      type: Number,
      default: "",
    },
    maxBid: {
      type: Number,
      default: "",
    },
    currentBid: {
      type: Number,
      default: "",
    },
    unitDisplayType: {
      type: String,
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    highestPrice: {
      type: Number,
      default: "",
    },
    lowestPrice: {
      type: Number,
      default: "",
    },
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.container-fluid {
  padding: 30px;
  margin: 30px;
  width: auto;
  background-color: #fff;
  border-radius: 22px;
}
.restaurant-logo {
  width: 10vh;
  height: 10vh;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #eeeeee;
}
.restaurant-name {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
.restaurant-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.restaurant-name-phone {
  margin-left: 20px;
}
.product-img {
  width: 20vh;
  height: 20vh;
  object-fit: cover;
  border: 2px solid #eeeeee;
  border-radius: 12px;
}
.bidding-detail {
  margin: 40px 0;
}
.product-name {
  font-size: 20px;
  font-weight: bold;
}
.row-text p {
  font-size: 18px;
}
.label-text {
  color: #707070;
}
.row-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bold-text {
  font-weight: bold;
}
.lower-case-text {
  text-transform: lowercase;
}
.price-label {
  font-size: 24px !important;
  color: #ff5252;
}
.col-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<template>
  <div class="modal-mask">
    <div @click="$emit('onCancelClicked')" class="modal-wrapper">
      <div @click.stop="" class="modal-container">
        <div class="header">
          <p class="title">{{ $t(title) }}</p>
        </div>

        <div class="body">
          <p class="desc-text">{{ $t(description) }}</p>
          <slot></slot>
        </div>

        <div class="footer">
          <Button
            @click="$emit('onCancelClicked')"
            :greyButton="true"
            class="action-button"
            >{{ $t("cancelButtonText") }}</Button
          >
          <Button
            @click="$emit('onConfirmClicked')"
            :cancelButton="true"
            class="action-button"
            >{{ $t("confirmButtonText") }}</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
export default {
  components: { Button },
  name: "ConfirmPopupModal",
  props: {
    title: {
      type: String,
      default: "confirmLabelText",
    },
    description: {
      type: String,
      default: "confirmDescText",
    },
  },
  emits: ["onCancelClicked", "onConfirmClicked"],
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 450px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
.modal-header h3 {
  margin-top: 0;
  color: #95C125;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  display: block;
  margin-top: 1rem;
}
.title {
  font-size: 24px;
  color: #95C125;
}
.desc-text {
  font-size: 18px;
}
.action-button {
  margin: 20px 5px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<template>
  <div id="app-body">
    <NoDataFound v-if="isNoData" />
    <div v-else class="container-fluid">
      <div class="row">
        <div class="col-lg-8 col-md-6">
          <div class="containter">
            <BiddingDetailCard
              :restaurantLogo="restaurantDetails.logo"
              :restaurantName="restaurantDetails.name"
              :restaurantPhone="restaurantDetails.phone"
              :productImage="productDetails.feature_image"
              :productName="productDetails.name"
              :productNameKh="productDetails.name_kh"
              :startedCost="biddingDetails.cost_to_bid"
              :finalCost="biddingDetails.final_cost"
              :currentBid="biddingDetails.bid_count"
              :maxBid="biddingDetails.max_bid"
              :unitDisplayType="biddingDetails.type_bidding"
              :startDate="biddingDetails.start_datetime"
              :endDate="biddingDetails.end_datetime"
              :highestPrice="biddingDetails.high_bid_range"
              :lowestPrice="biddingDetails.low_bid_range"
            />
            <BaseCard title="descriptionTitleText">
              <p v-if="biddingDetails.description" class="desc-text">
                {{ biddingDetails.description }}
              </p>
              <p v-else class="desc-text">{{ $t("noDescriptionLabelText") }}</p>
            </BaseCard>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 bid-card-container">
          <BidCard
            @buttonClicked="makeABid($event)"
            :unitDisplayType="biddingDetails.type_bidding"
            :maximumBiddingQty="availableBiddingQty"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import BidCard from "../components/BidCard.vue";
import BiddingDetailCard from "../components/BiddingDetailCard.vue";
import { useToast } from "vue-toastification";
import UserService from "../services/user.service";
import NoDataFound from "../components/NoDataFound.vue";
export default {
  components: { BidCard, BiddingDetailCard, BaseCard, NoDataFound },
  name: "BiddingDetail",
  data() {
    return {
      biddingDetails: [],
      restaurantDetails: [],
      productDetails: [],
      isNoData: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.$Progress.start();
      if (localStorage.bidding_detail) {
        this.isNoData = false;
        this.biddingDetails = JSON.parse(localStorage.bidding_detail);
        this.restaurantDetails = this.biddingDetails.restaurant;
        this.productDetails = this.biddingDetails.product_id;
      } else {
        this.isNoData = true;
      }
      this.$Progress.finish();
    }
  },
  beforeUnmount() {
    localStorage.removeItem("bidding_detail");
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    availableBiddingQty() {
      return (
        parseInt(this.biddingDetails.max_bid) -
        parseInt(this.biddingDetails.bid_count)
      );
    },
  },
  methods: {
    makeABid(biddingQty) {
      // Initialize formData
      let formData = new FormData();
      formData.append("bidding_id", this.biddingDetails.id);
      formData.append("amount", biddingQty);

      // make post request
      UserService.postCreateBid(formData)
        .then(() => {
          this.toast.success("Your bidding was successfully made.");
          this.$router.push("/my-bidding");
        })
        .catch(() => {
          this.toast.error("Something went worng! Cannot make your bid");
        });
    },
  },
};
</script>

<style scoped>
.bid-card-container {
  overflow-y: hidden;
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
}
.desc-text {
  font-size: 18px;
  color: #707070;
}
@media only screen and (max-width: 768px) {
  .bid-card-container {
    overflow-y: hidden;
    position: relative;
    top: 0;
    margin: 20px 0;
  }
}
</style>
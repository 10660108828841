<template>
  <div id="app-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <BaseCard :title="'orderHistoryLabelText'">
            <OrderListCard
              @cardClick="pushToOrderDetail($event)"
              :orderListData="orderHistoryList"
            />
            <NoDataFound class="no-data-message" v-if="isNoData" />
            <Pagination
              @navigateToPage="getOrderHistoryList($event)"
              :pageCount="orderHistoryMeta.last_page"
            />
          </BaseCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import OrderListCard from "../components/OrderListCard.vue";
import Pagination from "../components/Pagination.vue";
import UserService from "../services/user.service";
import pushToOrderDetail from "../mixins/pushToOrderDetail.js";
import NoDataFound from "../components/NoDataFound.vue";
export default {
  name: "OrderHistoryList",
  components: { BaseCard, OrderListCard, Pagination, NoDataFound },
  data() {
    return {
      currentPage: 1,
      orderHistoryList: [],
      orderHistoryMeta: [],
      orderHistoryLinks: [],
      isNoData: false,
    };
  },
  mixins: [pushToOrderDetail],
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.$Progress.start();
      this.getOrderHistoryList(this.currentPage);
    }
  },
  methods: {
    getOrderHistoryList(pageNumber) {
      this.$Progress.start();
      UserService.getOrderHistoryList(pageNumber)
        .then((response) => {
          if (response.data.data.length > 0) {
            this.orderHistoryList = response.data.data;
            this.orderHistoryMeta = response.data.meta;
            this.orderHistoryLinks = response.data.links;
          } else {
            this.isNoData = true;
          }
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.fail();
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.no-data-container {
  padding-top: 20px;
}
</style>
<template>
  <div class="container-fluid">
    <div class="row">
      <LogoContainer class="col-lg-6 banner-container" />
      <div class="col-lg-6 body-container">
        <div class="form-container">
          <div class="title">
            <h1>{{ $t("welcomeBackText") }}</h1>
            <h1>{{ $t("logInTitle") }}</h1>
          </div>
          <form @submit="handleLogin()">
            <BaseInput
              v-model="phoneNumber"
              name="phoneNumber"
              label="phoneNumberTitle"
              inputPlaceHolder="phoneNumberPlaceHolder"
              labelIcon="phone-volume"
              isAutoComplete="off"
              :isRequired="true"
            />
            <BaseErrorMessage v-if="message" :message="message" />
            <Button
              @click="handleLogin()"
              :disabled="
                !phoneNumberIsRequire || !phoneNumberIsValid || loading
              "
              id="sing-in-button"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              {{ $t("logInButton") }}
            </Button>
          </form>
          <p>
            {{ $t("noAccountText") }}
            <router-link to="/signup">{{ $t("registerLink") }}</router-link>
          </p>
          <button @click="changeLocale()" class="lang-btn">
            {{ $t("changeLangButton") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import LogoContainer from "../components/LogoContainer.vue";
import BaseInput from "../components/BaseInput.vue";
import BaseErrorMessage from "../components/BaseErrorMessage.vue";

export default {
  name: "SignIn",
  components: {
    LogoContainer,
    Button,
    BaseInput,
    BaseErrorMessage,
  },
  data() {
    return {
      successful: false,
      loading: false,
      message: "",
      hideNavBar: true,
      phoneNumber: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    phoneNumberIsRequire() {
      return !!this.phoneNumber;
    },
    phoneNumberIsValid() {
      if (
        this.phoneNumber.match(/\d/g).length >= 9 &&
        this.phoneNumber.match(/\d/g).length <= 12
      ) {
        return (this.isPhoneValid = true);
      } else {
        return (this.isPhoneValid = false);
      }
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
    this.$emit("onAppRun", this.hideNavBar);
  },
  methods: {
    handleLogin() {
      this.loading = true;

      this.$store.dispatch("auth/login", this.phoneNumber).then(
        () => {
          this.loading = false;
          this.successful = true;
          localStorage.setItem("user_number", this.phoneNumber);
          localStorage.setItem("send_otp", true);
          this.$router.push("/otpverification");
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
          this.phoneNumber = "";
        }
      );
    },
    changeLocale() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "kh";
        localStorage.setItem("lang", "kh");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      }
    },
  },
};
</script>

<style scoped>
.body-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
p {
  font-size: 18px;
}
.form-container {
  display: inline-flex;
  flex-direction: column;
  padding: 20px;
  width: 500px;
}
.form-container form {
  display: flex;
  flex-direction: column;
  margin: 30px 0 10px 0;
}
.form-container .title {
  color: #006633;
  line-height: 0.5;
  text-align: start;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}
.form-group {
  display: inline-flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
}
.form-group label {
  text-align: left;
}
.form-group input {
  height: 45px;
  margin-top: 5px;
  padding-left: 55px;
  width: 100%;
  background-color: #eeeeee;
  border: 0;
  border-radius: 16px;
}
.form-container p {
  text-align: center;
}
.input-container {
  position: relative;
}
.input-group {
  display: flex;
  flex-direction: column;
}
.input-icon {
  position: absolute;
  margin: 15px;
}
a {
  text-decoration: none;
  color: #95C125;
}
a:hover {
  text-decoration: underline;
  color: #95C125;
}
h1 {
  font-weight: bold;
}
.lang-btn {
  background-color: transparent;
  border: none;
  color: #95C125;
  font-size: 18px;
}
.lang-btn:hover {
  color: #95C125;
  text-decoration: underline;
}
</style>
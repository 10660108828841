<template>
  <div id="app-body">
    <div class="container-fluid">
      <div class="row">
        <BaseCard v-if="!isNoData">
          <!-- title section -->
          <div class="card-title">
            <p class="card-header-text">{{ $t("editAddressLabelText") }}</p>
            <p class="card-desc-text">{{ $t("editAddressDescText") }}</p>
            <hr />
          </div>
          <!-- body section -->
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="input-field">
                <BaseInput
                  v-model="label"
                  name="label"
                  label="locationNameLabelText"
                  labelIcon="map-marker-info"
                  inputPlaceHolder="locationNameLabelText"
                />
                <BaseErrorMessage
                  v-if="!labelIsValid"
                  message="labelRequiredMsg"
                />
              </div>
              <div class="input-field">
                <BaseInput
                  v-model="address"
                  name="address"
                  label="addressLabelText"
                  labelIcon="map-marker"
                  inputPlaceHolder="addressLabelText"
                />
                <BaseErrorMessage
                  v-if="!addressIsValid"
                  message="addressRequiredMsg"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <GoogleMap
                ref="mapRef"
                api-key="AIzaSyDCjo8FullVMLc9MxpihKZpy4gwhmJP4yA"
                class="map"
                :center="currentPosition"
                :zoom="15"
                :zoomControl="false"
                :draggable="true"
                :mapTypeControl="false"
                :keyboardShortcuts="false"
                :fullscreenControl="false"
                :streetViewControl="false"
              >
                <CustomControl position="RIGHT_BOTTOM">
                  <Button @click="getCurrentLocation()" class="custom-btn">
                    <img
                      src="https://img.icons8.com/ios-glyphs/30/000000/center-direction.png"
                    />
                  </Button>
                </CustomControl>
                <Marker :options="{ position: currentPosition }" />
              </GoogleMap>
            </div>
          </div>
          <Button @click="saveUpdatedAddress()" :disabled="!formIsValid">{{
            $t("saveButtonText")
          }}</Button>
          <!-- bottom section -->
        </BaseCard>
        <NoDataFound v-else />
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "../components/BaseCard.vue";
import BaseInput from "../components/BaseInput.vue";
import Button from "../components/Button.vue";
import { GoogleMap, Marker, CustomControl } from "vue3-google-map";
import { useGeolocation } from "../mixins/useGeolocation";
import BaseErrorMessage from "../components/BaseErrorMessage.vue";
import { useToast } from "vue-toastification";
import UserService from "../services/user.service";
import NoDataFound from "../components/NoDataFound.vue";
export default {
  components: {
    BaseCard,
    BaseInput,
    Button,
    GoogleMap,
    Marker,
    CustomControl,
    BaseErrorMessage,
    NoDataFound,
  },
  name: "EditSavedAddress",
  setup() {
    const toast = useToast();
    const { coords } = useGeolocation();
    return { toast, coords };
  },
  data() {
    return {
      addressData: [],
      label: "",
      address: "",
      currentPosition: {
        lat: 0,
        lng: 0,
      },
      isNoData: false,
    };
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.isLoading = true;
      if (localStorage.getItem("saved_address")) {
        this.addressData = JSON.parse(localStorage.saved_address);
        this.label = this.addressData.label;
        this.address = this.addressData.address;
        this.currentPosition.lat = parseFloat(this.addressData.lat);
        this.currentPosition.lng = parseFloat(this.addressData.lng);
        this.isNoData = false;
      } else {
        this.isNoData = true;
      }
      this.$Progress.finish();
    }
  },
  beforeUnmount() {
    localStorage.removeItem("saved_address");
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    labelIsValid() {
      return !!this.label;
    },
    addressIsValid() {
      return !!this.address;
    },
    formIsValid() {
      return this.labelIsValid && this.addressIsValid;
    },
  },
  methods: {
    getCurrentLocation() {
      console.log(this.currentPosition);
    },
    saveUpdatedAddress() {
      this.$Progress.start();
      let formData = new FormData();
      formData.append("lat", this.currentPosition.lat);
      formData.append("lng", this.currentPosition.lng);
      formData.append("label", this.label);
      formData.append("address", this.address);
      formData.append("_method", "PUT");

      UserService.postUpdateAddress(this.addressData.id, formData)
        .then(() => {
          this.$Progress.finish();
          this.toast.success("Update saved address successfully!");
          this.$router.push("/saved-address-list");
        })
        .catch(() => {
          this.$Progress.fail();
          this.taost.error("Something went wrong!");
        });
    },
  },
};
</script>

<style scoped>
.card-header-text {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.card-desc-text {
  font-size: 18px;
  margin: 0;
  color: #707070;
}
.input-field {
  margin-bottom: 30px;
}
.map {
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 20px;
}
.custom-btn {
  box-sizing: border-box;
  background: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 0px;
  margin: 10px;
  padding: 0px;
  font-size: 1.25rem;
  text-transform: none;
  appearance: none;
  cursor: pointer;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .map {
    position: relative;
    width: 100%;
    height: 250px;
    display: inline-block;
    border-radius: 16px;
    overflow: hidden;
    margin-top: 20px;
  }
}
</style>
<template>
  <div id="app-body-no-nav">
    <div v-if="!isLoading" class="container-fluid">
      <div class="header-container">
        <img class="logo-img" src="..\assets\customer_no_bg.png" alt="" />
        <p class="header-title">
          ការតាមដានផលិតផល
        </p>
      </div>
      <div class="row">
        <!-- product Detail Card -->
        <div class="col-lg-4 col-md-6">
          <div class="detail-card">
            <div class="header">
              <p class="title">{{ $t("productInfoLabelText") }}</p>
              <div class="vertical-line"></div>
            </div>
            <div class="body">
              <div class="image-container">
                <img class="product-img" :src="product.feature_image" />
              </div>
              <div class="detail-text">
                <!-- product name -->
                <div class="text-col">
                  <p class="label-text">ឈ្មោះ​ផលិតផល:</p>
                  <div v-if="product.name_kh">
                    <p class="data-text">{{ product.name_kh }}</p>
                  </div>
                  <div v-else>
                    <p class="data-text">{{ product.name }}</p>
                  </div>
                </div>
                <!-- MFG date -->
                <div class="text-col">
                  <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                  <p class="data-text">
                    {{
                      moment(data.purchase_date, "YYYY-MM-DD h:mm:ss Z").format(
                        "MMM DD YYYY"
                      )
                    }}
                  </p>
                </div>
                <!-- EXP date -->
                <div class="text-col">
                  <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                  <p class="data-text">
                    {{
                      moment(data.expire_date, "YYYY-MM-DD h:mm:ss Z").format(
                        "MMM DD YYYY"
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- producer Detail Card -->
        <div class="col-lg-4 col-md-6">
          <div class="detail-card">
            <div class="header">
              <p class="title">{{ $t("producerInfoLabelText") }}</p>
              <div class="vertical-line"></div>
            </div>
            <div class="body">
              <div class="detail-text">
                <!-- producer name -->
                <div class="text-col">
                  <p class="label-text">ឈ្មោះអ្នកផលិត:</p>
                  <p class="data-text">{{ supplier.name }}</p>
                </div>
                <!-- producer address -->
                <div class="text-col">
                  <p class="label-text">អាសយដ្ឋាន:</p>
                  <p class="data-text">{{ supplier.address }}</p>
                </div>
                <!-- producer phone -->
                <div class="text-col">
                  <p class="label-text">លេខទូរស័ព្ទ:</p>
                  <p class="data-text">{{ supplier.phone_number }}</p>
                </div>
              </div>
              <div class="image-container">
                <img class="producer-profile" :src="supplier.profile" />
              </div>
            </div>
          </div>
        </div>
        <!-- source detail card -->
        <div class="col-lg-4 col-md-6">
          <div class="detail-card">
            <div class="header">
              <p class="title">ប្រភព</p>
              <div class="short-vertical-line"></div>
            </div>
            <div class="body">
              <div>
                <div class="text-row">
                  <p class="label-text">កសិករ／ប្រភព:</p>
                  <p class="data-text">{{ data.farm_name ?? "-" }}</p>
                </div>
                <div class="text-row">
                  <p class="label-text">អាសយដ្ឋាន:</p>
                  <p class="data-text">{{ data.farm_address ?? "-" }}</p>
                </div>
                <div class="text-row">
                  <p class="label-text">លេខសម្គាល់:</p>
                  <p class="data-text">{{ data.batch_number ?? "-" }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="error-container">
        <div class="column-logo">
          <img class="logo-img" src="..\assets\customer_no_bg.png" alt="" />
          <p class="header-title">
            {{ $t("productTracibilityTitle") }}
          </p>
        </div>
        <p v-if="!hasData">ពុំមានព័ត៍មានផលិតផលដែលអ្នកកំពុងស្វែងរកទេ</p>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
import moment from "moment";
export default {
  name: "ProductTracibility",

  data() {
    return {
      hideNavBar: true,
      isLoading: true,
      hasData: true,
      product_code: "",
      data: [],
      product: [],
      producer: [],
      supplier: [],
      farmer: [],
    };
  },

  methods: {
    getProductDetailByCode(product_code) {
      this.$Progress.start();
      userService
        .getProductDetailQrCode(product_code)
        .then((response) => {
          this.data = response.data.data;
          this.product = response.data.data.product;
          this.producer = response.data.data.producer;
          this.supplier = response.data.data.supplier;
          this.farmer = response.data.data.farmer;
          this.$Progress.finish();
          this.isLoading = false;
        })
        .catch(() => {
          this.hasData = false;
          this.$Progress.fail();
        });
    },
  },

  created() {
    this.$emit("onAppRun", this.hideNavBar);
    this.moment = moment;
  },

  mounted() {
    this.product_code = this.$route.params.productCode;
    this.getProductDetailByCode(this.product_code);
  },
};
</script>

<style scoped>
.detail-card {
  padding: 30px;
  background-color: #fff;
  border-radius: 22px;
  margin: 10px;
}

.vertical-line {
  width: 250px;
  border-top: 4px solid #95c125;
  border-radius: 12px;
}

.detail-card p:not(.title) {
  font-size: 18px;
}

.title {
  font-size: 22px;
  margin-bottom: 10px;
}

.image-container {
  width: 35%;
  margin-right: 15px;
}

.detail-text {
  width: 65%;
}

.product-img {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 12px;
  object-fit: cover;
}

.label-text {
  color: grey;
  font-size: 14px;
  margin-right: 10px;
}

.body {
  display: flex;
  margin-top: 20px;
  align-content: center;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-title {
  margin-left: 15px;
  font-size: 28px;
  color: #95c125;
}

.logo-img {
  aspect-ratio: 1/1;
  width: 85px;
}

.producer-profile {
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 50%;
  margin-left: 15px;
  object-fit: cover;
}

.text-row {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.text-col {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.text-row p {
  margin-bottom: 0;
}

.text-col p {
  margin-bottom: 0;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  align-content: center;
  background-color: white;
}

.logo-text-container {
  display: flex;
  align-items: center;
}

.short-vertical-line {
    width: 75px;
    border-top: 4px solid #95c125;
    border-radius: 12px;
}

.column-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
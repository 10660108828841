<template>
  <div id="app-body">
    <div class="container-fluid">
      <div class="title-header">
        <h2>{{ $t("specialOfferTitle") }}</h2>
        <p>{{ $t("specialOfferBody") }}</p>
      </div>
      <div class="row row-cols-1 row-cols-md-3 g-4 row-card">
        <SpecialOfferCard
          v-for="promotion in promotions"
          :key="promotion.id"
          @clickOnCard="pushToRestaurantPage(promotion.id, promotion.name)"
          :deliveryFee="promotion.delivery_price"
          :discountUpTo="promotion.discount_up_to"
          :previewImages="promotion.preview_images[0].source"
          :logo="promotion.logo"
          :restaurantName="promotion.name"
          :address="promotion.address"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SpecialOfferCard from "../components/SpecialOfferCard.vue";
import UserService from "../services/user.service";
import PushToRestaurantPage from "../mixins/pushToRestaurantPage.js"
export default {
  name: "SpecialOfferList",
  components: { SpecialOfferCard },
  mixins: [PushToRestaurantPage],
  data() {
    return {
      promotions: [],
    };
  },
  mounted() {
    UserService.getPromotionList().then((response) => {
      this.promotions = response.data.data;
    });
  },
};
</script>

<style scoped>
.title-header {
  padding: 20px;
}
.row-card {
  padding: 0 20px;
}
</style>
<template>
  <div class="process-icon-group">
    <div :class="['icon-container', isDone ? 'icon-container-active' : '']">
      <unicon :name="icon" width="25" height="25" fill="currentColor" />
    </div>
    <p :class="['process-label', isDone ? 'bold-green-text' : '']">
      {{ $t(label) }}
    </p>
  </div>
</template>

<script>
export default {
  name: "OrderProcessIcon",
  props: {
    icon: {
      type: String,
      defualt: "",
    },
    label: {
      type: String,
      defualt: "",
    },
    isDone: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.process-icon-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.icon-container {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border: 6px solid #bccdc9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-container-active {
  background-color: #ffffff;
  border: 6px solid #95C125;
  color: #95C125;
}
.process-label {
  margin: 0 0 0 20px;
  font-size: 18px;
}
.bold-green-text {
  font-size: 18px;
  font-weight: 600;
  color: #95C125;
}
</style>
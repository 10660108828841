<template>
  <div id="app-body">
    <!-- popup modal for confirmatio -->
    <transition name="modal">
      <ConfirmPopupModal
        v-if="showConfirmModal"
        @onCancelClicked="onCancel()"
        @onConfirmClicked="onConfirm(this.tempAddressId)"
        description="removeItemDescMsg"
      />
    </transition>
    <!-- Body -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-8 col-sm-12">
          <!-- Placeholder loading component appear will fetching th data -->
          <div v-if="loading" class="carousel-loader"></div>
          <!-- Promotion Carousel -->
          <div v-else class="container promo-container">
            <swiper
              :space-between="30"
              :speed="800"
              :centeredSlides="true"
              :loop="true"
              :autoplay="autoplay"
              :breakpoints="slideBreakPoint"
            >
              <swiper-slide
                v-for="announcement in announcementList"
                :key="announcement.id"
              >
                <img
                  class="carousel-img"
                  :src="announcement.feature_image"
                  alt=""
              /></swiper-slide>
            </swiper>
          </div>

          <!-- Category Card Row -->
          <div class="container-fluid title-header">
            <h2>{{ $t("menuLabelText") }}</h2>
          </div>
          <div class="container-fluid sticky">
            <swiper :breakpoints="menuBreakPoint">
              <swiper-slide
                v-for="productSection in productSections"
                :key="productSection"
              >
                <CategoryCard
                  @click="goto(productSection.name)"
                  :backgroundImage="productSection.image"
                  :name="productSection.name"
                  :nameKh="productSection.name_kh"
                />
              </swiper-slide>
            </swiper>
          </div>

          <!-- Prmotion Product Card Row -->
          <div class="container-fluid">
            <div class="title-header">
              <h2>{{ $t("discountedProductTitle") }}</h2>
            </div>
            <swiper :breakpoints="menuBreakPoint">
              <swiper-slide v-for="product in promotionProducts" :key="product">
                <ProductCard
                  @cardClicked="pushToProductPage(product.id)"
                  :productImage="product.feature_image"
                  :productName="product.name"
                  :productNameKh="product.name_kh"
                  :price="product.prices[0].actual_price"
                  :fullPrice="product.prices[0].price"
                  :discount="product.prices[0].discount"
                  :preOrderBadge="product.status"
                  :quantity="product.prices[0].qty"
                  :unitDisplayType="product.prices[0].name"
                />
              </swiper-slide>
            </swiper>
          </div>

          <!-- Most order Product Card Row -->
          <div class="container-fluid">
            <div class="title-header">
              <h2>{{ $t("mostOrderProductTitle") }}</h2>
            </div>
            <swiper :breakpoints="menuBreakPoint">
              <swiper-slide v-for="product in mostOrders" :key="product">
                <ProductCard
                  @cardClicked="pushToProductPage(product.id)"
                  :productImage="product.feature_image"
                  :productName="product.name"
                  :productNameKh="product.name_kh"
                  :price="product.prices[0].actual_price"
                  :fullPrice="product.prices[0].price"
                  :discount="product.prices[0].discount"
                  :preOrderBadge="product.status"
                  :quantity="product.prices[0].qty"
                  :unitDisplayType="product.prices[0].name"
                />
              </swiper-slide>
            </swiper>
          </div>

          <!-- Show product by section -->
          <div
            v-for="productSection in productBySection"
            :key="productSection"
            class="product-row"
          >
            <div
              v-if="
                productSection.products && productSection.products.length > 0
              "
              :id="productSection.name"
              :ref="productSection.name"
            >
              <div class="title-header sectioln-sticky">
                <h2 v-if="this.$i18n.locale === 'en'">
                  {{ productSection.name }}
                </h2>
                <h2 v-else-if="!productSection.name_kh">
                  {{ productSection.name }}
                </h2>
                <h2 v-else>{{ productSection.name_kh }}</h2>
              </div>
              <div
                class="
                  row
                  row-cols-2
                  row-cols-sm-3
                  row-cols-md-4
                  row-cols-lg-4
                  row-cols-xxl-5
                  g-4
                  row-card
                "
              >
                <template
                  v-for="product in productSection.products"
                  :key="product.id"
                >
                  <ProductCard
                    v-if="
                      product.prices &&
                      product.prices.length > 0 &&
                      product.prices[0].actual_price
                    "
                    @cardClicked="pushToProductPage(product.id)"
                    :productImage="product.feature_image"
                    :productName="product.name"
                    :productNameKh="product.name_kh"
                    :price="product.prices[0].actual_price"
                    :fullPrice="product.prices[0].price"
                    :discount="product.prices[0].discount"
                    :preOrderDiscount="product.prices[0].pre_order_discount"
                    :preOrderBadge="product.status"
                    :quantity="product.prices[0].qty"
                    :unitDisplayType="product.prices[0].name"
                /></template>
              </div>
            </div>
          </div>

          <!-- add to cart modal card -->
          <add-to-cart-modal
            v-if="showModal"
            @closeModal="onCloseModal()"
            @updateCart="updateCart()"
            :productImage="productDetail.feature_image"
            :productName="productDetail.name"
            :productNameKh="productDetail.name_kh"
            :prices="productDetail.prices"
            :preOrderLabel="productDetail.status"
            :discount="productDetail.prices[0].discount"
            :productId="productDetail.id"
            :pricePerUnit="productDetail.price_per_unit"
            :isFood="productDetail.is_food"
            :toppings="productDetail.toppings"
          />
        </div>

        <!-- Shopping Cart Card -->
        <div
          v-if="shoppingCart"
          class="col-lg-4 col-sm-12 shopping-cart-container"
        >
          <ShoppingCartCard
            @checkOut="pushToCheckOut()"
            :subTotalPrice="shoppingCart.subtotal"
            :discountPrice="shoppingCart.total_discount"
            :totalPrice="shoppingCart.total"
            :countItemInCart="cartItems.length"
          >
            <CartItemList
              v-for="cartItem in shoppingCart.details"
              :key="cartItem.id"
              @removeCartItem="deleteCartItem(cartItem.id)"
              @qtyUpdate="updateCart()"
              :productImage="cartItem.product.feature_image"
              :productName="cartItem.product.name"
              :productNameKh="cartItem.product.name_kh"
              :subTotal="cartItem.prices.price"
              :actualTotal="cartItem.prices.actual_price"
              :qty="cartItem.qty"
              :productTypeQty="cartItem.prices.qty"
              :productType="cartItem.prices.name"
              :productId="cartItem.id"
              :preOrderLabel="cartItem.product.status"
              :inventoriesId="cartItem.inventories_id"
              :toppings="cartItem.topping"
            />
          </ShoppingCartCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartItemList from "../components/CartItemList.vue";
import ShopCard from "../components/ShopCard.vue";
import ShoppingCartCard from "../components/ShoppingCartCard.vue";
import SpecialOfferCard from "../components/SpecialOfferCard.vue";
import UserService from "../services/user.service";
import PushToCheckOut from "../mixins/pushToCheckOut.js";
import CategoryCard from "../components/CategoryCard.vue";
import ConfirmPopupModal from "../components/ConfirmPopupModal.vue";
import ProductCard from "../components/ProductCard.vue";
import AddToCartModal from "../components/AddToCartModal.vue";
import { useToast } from "vue-toastification";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export default {
  name: "Home",
  components: {
    SpecialOfferCard,
    ShopCard,
    ShoppingCartCard,
    CartItemList,
    CategoryCard,
    ConfirmPopupModal,
    Swiper,
    SwiperSlide,
    ProductCard,
    AddToCartModal,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mixins: [PushToCheckOut],
  data() {
    return {
      loading: false,
      showModal: false,
      showConfirmModal: false,
      tempItemId: "",
      errorMessage: "",
      shoppingCart: [],
      cartItems: [],
      productSections: [],
      productBySection: [],
      promotionProducts: [],
      mostOrders: [],
      productDetail: [],
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slideBreakPoint: {
        640: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1.4,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 1.8,
          spaceBetween: 30,
        },
      },
      menuBreakPoint: {
        320: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 25,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        1400: {
          slidesPerView: 5,
          spaceBetween: 25,
        },
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    hasNewArrivalRestaurant() {
      return Object.keys(this.newArrivalRestaurants).length ? true : false;
    },
    hasMostOrderedRestaurant() {
      return Object.keys(this.mostOrderRestaurants).length ? true : false;
    },
    hasPromotion() {
      return Object.keys(this.promotions).length ? true : false;
    },
    hasBidding() {
      return Object.keys(this.biddings).length ? true : false;
    },
  },
  mounted() {
    this.$Progress.start();
    this.loading = true;
    if (!this.currentUser) {
      this.$router.push("/signin");
    } else {
      this.getProductSection();
      this.getProductList();
      this.getShoppingCartData();
      this.getAnnouncement();
      this.getMostOrderedProduct();
      this.getPrmotionProduct();
    }
    this.$Progress.finish();
  },
  methods: {
    getProductSection() {
      UserService.getProductSection().then((response) => {
        this.productSections = response.data.data;
      });
    },
    getProductList() {
      const ProductList = localStorage.getItem("product_list");
      // checking if localstorage has data or not
      if (ProductList) {
        this.$Progress.finish();
        this.productBySection = JSON.parse(ProductList);
        console.log("Serve from cache!");
        // re-update cache data
        // UserService.getProductListBySection("1").then((response) => {
        //   this.productBySection = response.data.data;
        //   localStorage.setItem(
        //     "product_list",
        //     JSON.stringify(this.productBySection)
        //   );
        //   console.log("updating cache!");
        // });
      } else {
        console.log("API resquested and save to cache");
        UserService.getProductListBySection("1").then((response) => {
          this.productBySection = response.data.data;
          localStorage.setItem(
            "product_list",
            JSON.stringify(this.productBySection)
          );
        });
      }
    },
    getShoppingCartData() {
      UserService.getShoppingCartList().then((response) => {
        if (response.data.data) {
          this.shoppingCart = response.data.data;
          this.cartItems = response.data.data.details;
        }
      });
    },
    getAnnouncement() {
      UserService.getAnnouncementList().then((response) => {
        this.announcementList = response.data.data;
        this.loading = false;
      });
    },
    getMostOrderedProduct() {
      UserService.getMostOrderProduct().then((response) => {
        this.mostOrders = response.data.data;
      });
    },
    getPrmotionProduct() {
      UserService.getPromotionProduct().then((response) => {
        this.promotionProducts = response.data.data;
      });
    },
    deleteCartItem(cartItemId) {
      this.showConfirmModal = true;
      this.tempItemId = cartItemId;
    },
    onCancel() {
      this.showConfirmModal = false;
      this.tempItemId = "";
    },
    onConfirm() {
      this.$Progress.start();
      UserService.deleteCart(this.tempItemId)
        .then(() => {
          this.updateCart();
          this.toast.success("Item removed successfully!");
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.fail();
          this.errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.toast.error(this.errorMessage);
        });
      this.showConfirmModal = false;
      this.tempItemId = "";
    },
    updateCart() {
      UserService.getShoppingCartList().then((response) => {
        if (response.data.data) {
          this.shoppingCart = response.data.data;
          this.cartItems = response.data.data.details;
          this.$Progress.finish();
        } else {
          this.shoppingCart = {};
          this.cartItems = {};
          this.$Progress.finish();
        }
      });
    },
    onCloseModal() {
      this.showModal = false;
    },
    pushToProductPage(productId) {
      UserService.getProductDetail("1", productId).then((response) => {
        this.productDetail = response.data.data;
        this.showModal = true;
      });
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop - 250;

      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped>
.carousel-loader {
  margin: 20px 20px;
  border-radius: 22px;
  width: 100%;
  aspect-ratio: 21/9;
  background-color: #d5d5d5;
}
.restaurant-card-loader {
  width: 100%;
  height: 28vh;
  margin-bottom: 15px;
  border-radius: 22px;
  background-color: #d5d5d5;
}
.promo-container {
  margin-top: 30px;
}
.carousel-img {
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 22px;
  object-fit: cover;
}
a {
  color: #000000;
  text-decoration: none;
  transition: 0.3s;
}
a:hover {
  color: #95c125;
  text-decoration: underline;
  transition: 0.3s;
}
.title-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
}
.shopping-cart-container {
  overflow-y: hidden;
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
}
.shop-card-grid {
  padding: 20px 0;
}
.product-row {
  padding: 20px;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

/* .sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 74px;
  z-index: 2;
  background-color: #eeeeee;
} */
/* .sectioln-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 249px;
  z-index: 1;
  background-color: #eeeeee;
} */
.category-card {
  margin: 10px;
}
.category-card:hover {
  box-shadow: none;
}
@media only screen and (max-width: 992px) {
  .shopping-cart-container {
    overflow-y: hidden;
    position: relative;
    top: 0;
  }
}
@media only screen and (min-width: 320px) {
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 123px;
    z-index: 2;
    background-color: #eeeeee;
  }
  .sectioln-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 257px;
    z-index: 1;
    background-color: #eeeeee;
  }
}
@media only screen and (min-width: 540px) {
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 123px;
    z-index: 2;
    background-color: #eeeeee;
  }
  .sectioln-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 241px;
    z-index: 1;
    background-color: #eeeeee;
  }
}
@media only screen and (min-width: 720px) {
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 122px;
    z-index: 2;
    background-color: #eeeeee;
  }
  .sectioln-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 256px;
    z-index: 1;
    background-color: #eeeeee;
  }
}
@media only screen and (min-width: 960px) {
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 72px;
    z-index: 2;
    background-color: #eeeeee;
  }
  .sectioln-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 205px;
    z-index: 1;
    background-color: #eeeeee;
  }
}
@media only screen and (min-width: 1140px) {
  .sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 74px;
  z-index: 2;
  background-color: #eeeeee;
}
  .sectioln-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 193px;
    z-index: 1;
    background-color: #eeeeee;
  }
}
@media only screen and (min-width: 1320px) {
  .sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 73px;
  z-index: 2;
  background-color: #eeeeee;
}
  .sectioln-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 243px;
    z-index: 1;
    background-color: #eeeeee;
  }
}
</style>
<template>
  <div class="no-data-container">
    <!-- no restaurant found -->
    <div
      v-if="showMessageFor === 'noRestaurantFound'"
      class="img-and-msg-container"
    >
      <div class="img-container">
        <img class="no-data-img" :src="ShoppingAppSVG" alt="" />
      </div>
      <h2>{{ $t("restaurantNotFoundText") }}</h2>
      <p class="error-desc-text">{{ $t("restaurantNotFoundDescText") }}</p>
    </div>
    <!-- 404 not found -->
    <div
      v-else-if="showMessageFor === '404NotFound'"
      class="img-and-msg-container"
    >
      <div class="img-container">
        <img class="no-data-img" :src="NotFoundSVG" alt="" />
      </div>
      <h2>{{ $t("404Message") }}</h2>
      <p class="error-desc-text">{{ $t("404DescriptionMessage") }}</p>
    </div>
    <div v-else class="img-and-msg-container">
      <div class="img-container">
        <img class="no-data-img" :src="NoDataSVG" alt="" />
      </div>
      <h2>{{ $t("noDataFoundText") }}</h2>
      <p class="error-desc-text">{{ $t("noDataFoundDescText") }}</p>
    </div>
    <Button @click="pushToHomePage()">{{ $t("returnToHomePageText") }}</Button>
  </div>
</template>

<script>
import NoDataSVG from "../assets/svg/undraw_data_processing_yrrv.svg";
import NotFoundSVG from "../assets/svg/undraw_not_found_-60-pq.svg";
import ShoppingAppSVG from "../assets/svg/undraw_shopping_app_flsj.svg";
import Button from "./Button.vue";
export default {
  components: { Button },
  name: "NoDataFound",
  data() {
    return {
      NoDataSVG,
      ShoppingAppSVG,
      NotFoundSVG,
    };
  },
  props: {
    showMessageFor: {
      type: String,
      default: "noDataFound",
    },
  },
  methods: {
    pushToHomePage() {
      this.$router.push("/home");
    },
  },
};
</script>

<style scoped>
.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 100px 50px;
}
.no-data-img {
  width: 200px;
  height: 200px;
}
.error-desc-text {
  margin: 0;
  font-size: 18px;
}
.img-container {
  width: 300px;
  height: 300px;
  padding: 30px;
  margin: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-and-msg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
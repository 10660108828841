<template>
  <div class="container">
    <div class="shopping-cart-card">
      <!-- Card Title -->
      <div class="text-with-icon">
        <svg
          id="fi-sr-shopping-cart"
          xmlns="http://www.w3.org/2000/svg"
          width="29.264"
          height="30"
          viewBox="0 0 29.264 30"
        >
          <path
            id="Path_8"
            data-name="Path 8"
            d="M28.391,5.364a3.669,3.669,0,0,0-2.879-1.417H5.3L5.25,3.486A3.824,3.824,0,0,0,1.528,0H1.25A1.284,1.284,0,0,0,0,1.316,1.284,1.284,0,0,0,1.25,2.632h.278A1.275,1.275,0,0,1,2.769,3.793l1.72,15.395A6.374,6.374,0,0,0,10.7,25H23.75a1.318,1.318,0,0,0,0-2.632H10.7a3.768,3.768,0,0,1-3.525-2.632h14.9a6.348,6.348,0,0,0,6.151-5.412L29.2,8.6a4.09,4.09,0,0,0-.813-3.232Z"
          />
          <circle
            id="Ellipse_5"
            data-name="Ellipse 5"
            cx="2"
            cy="2"
            r="2"
            transform="translate(6.508 26)"
          />
          <circle
            id="Ellipse_6"
            data-name="Ellipse 6"
            cx="2"
            cy="2"
            r="2"
            transform="translate(19.523 26)"
          />
        </svg>
        <p>{{ $t("shoppingCartTitle") }} ({{ countItemInCart }})</p>
      </div>
      <hr />
      <div class="cart-item-list">
        <slot></slot>
      </div>
      <!-- Subtotal and total Area -->
      <div class="total-area">
        <hr />
        <div class="text-row">
          <p>{{ $t("subTotalText") }}</p>
          <p>${{ parseFloat(subTotalPrice).toFixed(2) }}</p>
        </div>
        <div class="text-row">
          <p>{{ $t("discountText") }}</p>
          <p class="red-text">(${{ parseFloat(discountPrice).toFixed(2) }})</p>
        </div>
        <div class="total-text-container">
          <div class="text-row">
            <p>{{ $t("totalText") }}</p>
            <p>${{ parseFloat(totalPrice).toFixed(2) }}</p>
          </div>
        </div>
        <Button @click="$emit('checkOut')" :disabled="!countItemInCart">{{
          $t("checkOutButton")
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
export default {
  components: { Button },
  name: "ShoppingCartCard",
  props: {
    subTotalPrice: {
      type: String,
      default: "0",
    },
    discountPrice: {
      type: String,
      default: "0",
    },
    totalPrice: {
      type: String,
      default: "0",
    },
    countItemInCart: {
      type: Number,
      default: 0,
    },
  },
  emits: ["removeProduct", "checkOut"],
};
</script>

<style scoped>
.container {
  padding: 50px 10px 20px;
  width: 100%;
  height: 100%;
}
.shopping-cart-card {
  background-color: #ffffff;
  height: 100%;
  padding: 30px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
}
.text-with-icon {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
}
.text-with-icon p {
  margin: 0 0 0 20px;
  font-size: 28px;
  font-weight: bold;
}
.total-area {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.text-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.red-text {
  color: #ff5252;
}
.total-text-container {
  background-color: #eeeeee;
  padding: 10px;
  margin-top: 10px;
  border-radius: 22px;
}
.total-text-container p {
  font-size: 22px;
}
p {
  margin: 0;
}
.cart-item-list {
  overflow-x: hidden;
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media only screen and (max-width: 992px) {
  .container {
    padding: 10px 20px;
    width: 100%;
    height: 100%;
  }
}
</style>
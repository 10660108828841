<template>
    <div class="logo-container">
        <h1>Davane</h1>
    </div>
</template>

<script>
    export default {
        name: 'LogoContainer',
    }
</script>

<style scoped>
.logo-container {
    background-image: linear-gradient(to bottom right, #95C125,#86ae21, #95C125);
    color: #ffffff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: start;
}
.logo-container h1 {
    margin-left: 40px;
}
</style>
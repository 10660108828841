<template>
    <div id="app-body-no-nav">
        <div class="container-fluid">
            <div class="header-container">
                <img class="logo-img" src="..\assets\customer_no_bg.png" alt="" />
                <p class="header-title">
                    ការតាមដានផលិតផល
                </p>
            </div>
            <div v-if="this.productId === '16487391199'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_35.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product2.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product2.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product2.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source2.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source2.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source2.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391201'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_36.png" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product3.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product3.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product3.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source2.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source2.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source2.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391823'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_37.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product4.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product4.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product4.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source2.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source2.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source2.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391833'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_37.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product10.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product10.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product10.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source2.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source2.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source2.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391824'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_38.png" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product5.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product5.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product5.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source2.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source2.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source2.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391834'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_38.png" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product11.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product11.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product11.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source2.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source2.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source2.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391203'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_39.png" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product6.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product6.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product6.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source2.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source2.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source2.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391332'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_40.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product7.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product7.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product7.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source3.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source3.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source3.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391204'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_48.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product8.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product8.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product8.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source4.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source4.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source4.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391214'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_48.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product12.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product12.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product12.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source4.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source4.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source4.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391330'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_9.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product9.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product9.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product9.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source4.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source4.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source4.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391655'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_9.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product13.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product13.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product13.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source4.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source4.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source4.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391634'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_14.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product14.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product14.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product14.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source4.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source4.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source4.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391328'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_42.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product15.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product15.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product15.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer2.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer2.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer2.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source4.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source4.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source4.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- unica -->
            <div v-if="this.productId === '16487391387'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_16.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product16.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product16.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product16.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391356'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_17.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product17.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product17.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product17.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391662'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_18.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product18.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product18.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product18.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391645'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_19.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product19.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product19.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product19.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391739'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_20.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product20.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product20.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product20.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391752'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_21.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product21.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product21.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product21.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391353'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_22.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product22.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product22.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product22.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391389'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_23.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product23.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product23.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product23.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source6.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source6.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source6.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391652'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_18.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product30.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product30.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product30.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391382'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_17.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product24.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product24.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product24.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391711'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_19.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product25.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product25.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product25.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391599'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_20.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product26.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product26.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product26.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391759'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_21.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product27.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product27.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product27.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391354'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_22.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product28.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product28.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product28.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source5.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source5.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source5.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391839'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_23.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product29.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product29.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product29.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer3.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer3.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer3.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source6.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source6.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source6.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /new -->
            <div v-if="this.productId === '16487391840'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_31.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product31.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product31.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product31.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer31.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer31.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer31.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source31.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source31.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source31.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.productId === '16487391841'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_32.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product32.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product32.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product32.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer32.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer32.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer32.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/unica_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source32.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source32.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source32.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="this.productId === '16487391842'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_33.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product33.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product33.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product33.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer33.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer33.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer33.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source33.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source33.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source33.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="this.productId === '16487391843'"  class="row">
                <!-- product Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីផលិតផល</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="image-container">
                                <img class="product-img" src="../assets/product_34.jpg" />
                            </div>
                            <div class="detail-text">
                                <!-- product name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះ​ផលិតផល :</p>
                                    <p class="data-text">{{ product34.name }}</p>
                                </div>
                                <!-- MFG date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផលិត:</p>
                                    <p class="data-text">
                                        {{ product34.created_at }}
                                    </p>
                                </div>
                                <!-- EXP date -->
                                <div class="text-row">
                                    <p class="label-text">កាលបរិច្ឆេទផុតកំណត់:</p>
                                    <p class="data-text">
                                        {{ product34.expire_date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ព័ត៌មានលម្អិតអំពីអ្នកផលិត</p>
                            <div class="vertical-line"></div>
                        </div>
                        <div class="body">
                            <div class="detail-text">
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ឈ្មោះអ្នកផលិត :</p>
                                    <p class="data-text">{{ producer34.name }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ producer34.address }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">លេខទូរស័ព្ទ​ :</p>
                                    <p class="data-text">{{ producer34.phone }}</p>
                                </div>
                            </div>
                            <div class="image-container">
                                <img class="producer-profile" src="../assets/ht_food_logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- producer Detail Card -->
                <div class="col-lg-4 col-md-6">
                    <div class="detail-card">
                        <div class="header">
                            <p class="title">ប្រភព</p>
                            <div class="short-vertical-line"></div>
                        </div>
                        <div class="body">
                            <div>
                                <!-- producer name -->
                                <div class="text-row">
                                    <p class="label-text">ប្រភេទ :</p>
                                    <p class="data-text">{{ source34.type }}</p>
                                </div>
                                <!-- producer address -->
                                <div class="text-row">
                                    <p class="label-text">កសិករ :</p>
                                    <p class="data-text">{{ source34.farmer }}</p>
                                </div>
                                <!-- producer phone -->
                                <div class="text-row">
                                    <p class="label-text">អាសយដ្ឋាន :</p>
                                    <p class="data-text">{{ source34.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- new -->
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "NewProductTracibility",
    data() {
        return {
            productId: "",
            product2: {
                name: "ងៀតត្រីហ្វីឡេ",
                created_at: "Feb 08 2023",
                expire_date: "Mar 30 2023",
            },
            producer2: {
                name: "សិប្បកម្មហូបថេសហ្វូត",
                address: "ភូមិតាវៀន សង្កាត់សាលាកំរើក ក្រុងសៀមរាប ខេត្តសៀមរាប",
                phone: "+855-85 669 166",
                logo: "https://www.ardnasidhe.com/image/fit/539x301/images/placeholder.jpg",
            },
            source2: {
                type: "ត្រីកសិដ្ឋាន",
                farmer: "ផាត ឡៃ — អត្តលេខ​​ 4408",
                address: "ភូមិភ្នំក្រោម សង្កាត់សៀមរាប ក្រុងសៀមរាប ខេត្តសៀមរាប",
            },
            product3: {
                name: "ងៀតត្រីអណ្តែងផ្អែម",
                created_at: "Jan 28 2023",
                expire_date: "Mar 19 2023",
            },
            product4: {
                name: "ងៀតត្រីផ្ទោង",
                created_at: "Feb 08 2023",
                expire_date: "Mar 30 2023",
            },
            product5: {
                name: "ងៀតត្រីឆ្លូញ",
                created_at: "Feb 08 2023",
                expire_date: "Mar 30 2023",
            },
            product6: {
                name: "ត្រីប្រាហើម",
                created_at: "Jan 29 2023",
                expire_date: "Mar 10 2023",
            },
            source3: {
                type: "ត្រីកសិដ្ឋាន",
                farmer: "ឆាយ សុធា — អត្តលេខ​​ 1976",
                address: "ភូមិទួលចាន់ ឃុំច្បារអំពៅ ស្រុកបាធាយ ខេត្តកំពង់ចាម",
            },
            product7: {
                name: "ផ្អកត្រីប្រា",
                created_at: "Sep 15 2022",
                expire_date: "Mar 14 2023",
            },
            product8: {
                name: "ផ្អកត្រីឆ្វៀត",
                created_at: "Oct 15 2023",
                expire_date: "Apr 15 2023",
            },
            product9: {
                name: "ប្រហុកត្រីរៀល",
                created_at: "Sep 29 2023",
                expire_date: "Jun 15 2023",
            },
            product10: {
                name: "ងៀតត្រីផ្ទោង",
                created_at: "Jan 15 2023",
                expire_date: "Mar 06 2023",
            },
            product11: {
                name: "ត្រីឆ្លូញងៀត",
                created_at: "Feb 07 2023",
                expire_date: "Mar 29 2023",
            },
            product12: {
                name: "ផ្អកត្រីឆ្វៀត",
                created_at: "Sep 30 2022",
                expire_date: "Mar 29 2023",
            },
            product13: {
                name: "ប្រហុកត្រីរៀល",
                created_at: "Aug 31 2022",
                expire_date: "Feb 27 2023",
            },
            product14: {
                name: "ប្រហុកត្រីកំភ្លាញ",
                created_at: "Sep 06 2022",
                expire_date: "May 09 2023",
            },
            product15: {
                name: "ប្រហុកត្រីរ៉ស់",
                created_at: "Oct 15 2022",
                expire_date: "Apr 13 2023",
            },
            source4: {
                type: "ត្រីកសិដ្ឋាន",
                farmer: "យន់ ស៊ីនួន — អត្តលេខ​​ 9346",
                address: "ភូមិអារញ្ញ សង្កាត់សៀមរាប ក្រុងសៀមរាប ខេត្តសៀមរាប",
            },
            product16: {
                name: "ត្រីចំណិតរសជាតិដើម",
                created_at: "Jan 30 2023",
                expire_date: "Jul 30 2023",
            },
            producer3: {
                name: "Unica",
                address: "ផ្ទះលេខ០៣ ផ្លូវលេខ០៦ គីឡូម៉ែត្រលេខ៦ ឬស្សីកែវ ភ្នំពេញ",
                phone: "+855-17 900 900",
            },
            source5: {
                type: "ត្រីកសិដ្ឋាន",
                farmer: "យន់ សំណាង — អត្តលេខ​​ 001",
                address: "ភូមិអារញ សង្កាត់សៀមរាប ក្រុងសៀមរាប ខេត្តសៀមរាប",
            },
            source6: {
                type: "ត្រីធម្មជាតិ",
                farmer: "កែង មុំ — អត្តលេខ​​ 002",
                address: "អូរអាកុល​ ពោធិ៍សាត់",
            },
            product17: {
                name: "ត្រីចំណិតហិរ",
                created_at: "Oct 29 2022",
                expire_date: "Apr 29 2023",
            },
            product18: {
                name: "ត្រីងៀតពិសេស",
                created_at: "May 12 2023",
                expire_date: "Nov 12 2023",
            },
            product19: {
                name: "ត្រីងៀតពិសេសប្រៃ",
                created_at: "Jan 30 2023",
                expire_date: "Jul 30 2023",
            },
            product20: {
                name: "ត្រីប្រាហើម",
                created_at: "Oct 29 2022",
                expire_date: "Apr 29 2023",
            },
            product21: {
                name: "ត្រីកញ្ចុះប្រលាក់",
                created_at: "Oct 26 2022",
                expire_date: "Apr 26 2023",
            },
            product22: {
                name: "ប្រហុកសាច់ត្រីរ៉ស់",
                created_at: "Aug 27 2022",
                expire_date: "Aug 27 2023",
            },
            product23: {
                name: "ប្រហុកឆ្អឹងត្រីរៀល",
                created_at: "Aug 27 2022",
                expire_date: "Aug 27 2023",
            },
            product24: {
                name: "ត្រីចំណិតហិរ",
                created_at: "Jan 30 2023",
                expire_date: "Jul 30 2023",
            },
            product25: {
                name: "ត្រីងៀតពិសេសប្រៃ",
                created_at: "Feb 12 2023",
                expire_date: "Aug 12 2023",
            },
            product26: {
                name: "ត្រីប្រាហើម",
                created_at: "Feb 12 2023",
                expire_date: "Aug 12 2023",
            },
            product27: {
                name: "ត្រីកញ្ចុះប្រលាក់",
                created_at: "Jan 09 2023",
                expire_date: "Jul 09 2023",
            },
            product28: {
                name: "ប្រហុកសាច់ត្រីរ៉ស់",
                created_at: "Oct 28 2022",
                expire_date: "Jun 28 2023",
            },
            product29: {
                name: "ប្រហុកឆ្អឹងត្រីរៀល",
                created_at: "Nov 05 2022",
                expire_date: "Aug 05 2023",
            },
            product30: {
                name: "ត្រីងៀតពិសេស",
                created_at: "Jan 30 2023",
                expire_date: "Jul 30 2023",
            },
            // new
            product31: {
                name: "Spicy Fish Jerky",
                created_at: "April 05 2023",
                expire_date: "October 05 2023",
            },
            producer31: {
                name: "UNICA enterprise",
                address: "#01 & 03 E2, Borey New World Km 6, St 06, Boeung Chhouk, Km6 Russey Keo, Phnom Penh",
                phone: "+855-17 900 970",
            },
            source31: {
                type: "Samnang Sothea",
                farmer: "Yun Samnang",
                address: "Aranh Village Riem Reap Commune Siem Reap City",
            },

            product32: {
                name: "Premium Dried Fish",
                created_at: "May 12 2023",
                expire_date: "November 12 2023",
            },
            producer32: {
                name: "UNICA enterprise",
                address: "#01 & 03 E2, Borey New World Km 6, St 06, Boeung Chhouk, Km6 Russey Keo, Phnom Penh",
                phone: "+855-17 900 970",
            },
            source32: {
                type: "Samnang Sothea",
                farmer: "Yun Samnang",
                address: "Aranh Village Riem Reap Commune Siem Reap City",
            },

            product33: {
                name: "ងៀតត្រីអណ្តែង",
                created_at: "May 15 2023",
                expire_date: "Juy 15 2023",
            },
            producer33: {
                name: "HT FOOD",
                address: "ភូមិតារៀន សង្កាត់សាលាកំរើក ក្រុងសៀមរាប",
                phone: "+855-15 344 663",
            },
            source33: {
                type: "អ្នកផលិត",
                farmer: "Made For This Fabrication",
                address: "ភូមិដី ឃុំត្រពាំងធំ សង្កាត់ប្រាសាទបាគង ខេត្តសៀមរាប",
            },

            product34: {
                name: "ងៀតត្រីរ៉ស",
                created_at: "May 15 2023",
                expire_date: "Juy 15 2023",
            },
            producer34: {
                name: "HT FOOD",
                address: "ភូមិតារៀន សង្កាត់សាលាកំរើក ក្រុងសៀមរាប",
                phone: "+855-15 344 663",
            },
            source34: {
                type: "អ្នកផលិត",
                farmer: "រិន&ស្រីលាង",
                address: "ភូមិចុងឃ្នាស សង្កាត់សៀមរាប ស្រុកសៀមរាប ខេត្តសៀមរាប",
            },
            // new
        };
    },
    created() {
        this.productId = this.$route.params.productCode;
        this.$emit("onAppRun", true);
        this.moment = moment;
    },
}
</script>

<style scoped>
.detail-card {
    padding: 30px;
    background-color: #fff;
    border-radius: 22px;
    margin: 10px;
}

.vertical-line {
    width: 250px;
    border-top: 4px solid #95c125;
    border-radius: 12px;
}

.short-vertical-line {
    width: 75px;
    border-top: 4px solid #95c125;
    border-radius: 12px;
}

.detail-card p:not(.title) {
    font-size: 18px;
}

.title {
    font-size: 22px;
    margin-bottom: 10px;
}

.image-container {
    width: 35%;
    margin-right: 15px;
}

.detail-text {
    width: 65%;
}

.product-img {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 12px;
    object-fit: cover;
}

.label-text {
    color: grey;
    font-size: 14px;
    margin-right: 10px;
}

.body {
    display: flex;
    margin-top: 20px;
    align-content: center;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-title {
    margin-left: 15px;
    font-size: 28px;
    color: #95c125;
}

.logo-img {
    aspect-ratio: 1/1;
    width: 85px;
}

.producer-profile {
    aspect-ratio: 1/1;
    width: 100%;
    border-radius: 50%;
    margin-left: 15px;
    object-fit: cover;
}

/* .text-row {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
} */

.text-row p {
    margin-bottom: 0;
}

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    align-content: center;
    background-color: white;
}

.logo-text-container {
    display: flex;
    align-items: center;
}
</style>
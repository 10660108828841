<template>
  <div class="container-fluid">
    <div class="row">
      <LogoContainer class="col-md-6" />
      <div class="col-md-6 body-container">
        <div class="form-container">
          <div class="title">
            <h1>{{ $t("oneMoreStepText") }}</h1>
            <h1>{{ $t("otpVerificationText") }}</h1>
          </div>
          <p class="opt-desc-text">
            {{ $t("sendOTPCodeDescText", { phoneNumber: this.phoneNumber }) }}
          </p>
          <BaseInput
            @keyup.enter="verifyCode()"
            v-model="otpCode"
            name="otpCode"
            label="otpCodelabelText"
            inputPlaceHolder="otpCodePlaceHolder"
            labelIcon="dialpad-alt"
            isAutoComplete="off"
            :isRequired="true"
          />
          <!-- Alert message -->
          <BaseErrorMessage v-if="isCodeInvalid" :message="message" />

          <div class="resend-otp-link">
            <button
              @click="resendOTP(this.phoneNumber)"
              :disabled="isDisabled"
              class="resend-otp-btn"
            >
              {{ $t("sendOTPAgainButton") }}
            </button>
            <p class="counter">in {{ counter }}s</p>
          </div>
          <div :ref="(ref) => (this.recaptchaWrapperRef = ref)">
            <div id="recaptcha-container"></div>
          </div>
          <Button
            @click="verifyCode()"
            :disabled="!isOtpCodeNotNull || loading"
            id="sing-in-button"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            {{ $t("continueButton") }}
          </Button>
          <button @click="changeLocale()" class="lang-btn">
            {{ $t("changeLangButton") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import LogoContainer from "../components/LogoContainer.vue";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import BaseInput from "../components/BaseInput.vue";
import BaseErrorMessage from "../components/BaseErrorMessage.vue";

export default {
  name: "OtpVerification",
  components: {
    LogoContainer,
    Button,
    BaseInput,
    BaseErrorMessage,
  },
  data() {
    return {
      otpCode: "",
      successful: false,
      loading: false,
      message: "",
      counter: 60,
      isDisabled: true,
      hideNavBar: true,
      phoneNumber: "",
      isCodeInvalid: false,
      appVerifier: "",
    };
  },
  mounted() {
    this.$Progress.start();
    if (localStorage.user_number) {
      this.phoneNumber = localStorage.user_number;
      if (localStorage.send_otp) {
        this.sendOtp(this.phoneNumber);
        localStorage.removeItem("send_otp");
      } else {
        this.countDownTimer();
      }
    } else {
      this.$router.push("/signin");
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
    this.$emit("onAppRun", this.hideNavBar);
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isOtpCodeNotNull() {
      return !!this.otpCode;
    },
  },
  beforeUnmount() {
    localStorage.removeItem("user_number");
    localStorage.removeItem("send_otp");
  },
  methods: {
    sendOtp(phoneNumber) {
      const countryCode = "+855";
      const inputPhoneNumber = countryCode + phoneNumber.substring(1);
      const auth = getAuth();
      // Initialize new reCaptcha verifier
      this.appVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        auth
      );

      signInWithPhoneNumber(auth, inputPhoneNumber, this.appVerifier).then(
        (confirmationResult) => {
          window.confirmationResult = confirmationResult;
          this.countDownTimer();
          this.$Progress.finish();
        }
      );
    },

    verifyCode() {
      this.$Progress.start();
      this.loading = true;
      confirmationResult
        .confirm(this.otpCode)
        .then(() => {
          this.isCodeInvalid = false;
          this.message = "";
          this.setLoginState();
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isCodeInvalid = true;
          this.loading = false;
          this.message = "otpInvalidMsgText";
          this.otpCode = "";
          this.$Progress.fail();
        });
    },

    setLoginState(user) {
      this.message = "";
      this.successful = false;

      this.$store
        .dispatch("auth/verifyOtp", user)
        .then(() => {
          this.successful = true;
          this.loading = false;
          this.$router.push("/home");
        })
        .catch((error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        });
    },

    resendOTP(phoneNumber) {
      if (this.appVerifier && this.recaptchaWrapperRef) {
        this.appVerifier.clear();
        this.recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;
      }
      this.sendOtp(phoneNumber);
      this.counter = 60;
      this.countDownTimer();
    },

    countDownTimer() {
      if (this.counter > 0) {
        this.isDisabled = true;
        setTimeout(() => {
          this.counter -= 1;
          this.countDownTimer();
        }, 1000);
      }
      if (this.counter === 0) {
        this.isDisabled = false;
      }
    },

    changeLocale() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "kh";
        localStorage.setItem("lang", "kh");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("lang", "en");
      }
    },
  },
};
</script>

<style scoped>
.body-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
p {
  font-size: 18px;
}
.otp-desc-text {
  margin-top: 10px;
}
.otp-input-field {
  margin: 10px 0;
}
.form-container {
  display: inline-flex;
  flex-direction: column;
  padding: 20px;
  width: 500px;
}
.form-container form {
  display: flex;
  flex-direction: column;
  margin: 30px 0 10px 0;
}
.form-container .title {
  color: #006633;
  line-height: 0.5;
  text-align: start;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}
.form-group {
  display: inline-flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
}
.form-group label {
  text-align: left;
}
.form-group input {
  height: 45px;
  margin-top: 5px;
  padding-left: 55px;
  width: 100%;
  background-color: #eeeeee;
  border: 0;
  border-radius: 16px;
}
.form-container p {
  text-align: center;
}
.input-group {
  display: flex;
  flex-direction: column;
}
.input-container {
  position: relative;
}
.input-icon {
  position: absolute;
  margin: 15px;
}
.main-btn {
  padding: 10px 50px 10px 50px;
  margin-bottom: 20px;
  border: 0;
  border-radius: 24px;
  background-color: #95C125;
  color: #ffffff;
  font-size: 18px;
}
h1 {
  font-weight: bold;
}
.resend-otp-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.resend-otp-link a {
  margin-right: 10px;
}
.disabled {
  color: grey;
  pointer-events: none;
}
.resend-otp-btn {
  font-size: 18px;
  margin: 10px;
  background-color: none;
  border: none;
  background: none;
}
.resend-otp-btn:not(.resend-otp-btn:disabled) {
  color: #95C125;
}
.resend-otp-btn:hover:not(.resend-otp-btn:disabled) {
  color: #95C125;
  text-decoration: underline;
}
.counter {
  margin: 0;
}
.lang-btn {
  background-color: transparent;
  border: none;
  color: #95C125;
  font-size: 18px;
}
.lang-btn:hover {
  color: #95C125;
  text-decoration: underline;
}
.opt-desc-text {
  text-align: left !important;
  margin-top: 5px;
  margin-bottom: 20px;
}
</style>
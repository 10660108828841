<template>
  <div>
    <table>
      <!-- tabel header -->
      <tr class="table-header">
        <td class="id-col">{{ $t("idLabelText") }}</td>
        <td class="date-col">{{ $t("dateLabelText") }}</td>
        <td class="restaurant-col">{{ $t("restauranLabelText") }}</td>
        <td class="payment-col">{{ $t("paymentLabelText") }}</td>
        <td class="order-type-col">{{ $t("orderTypeLabelText") }}</td>
        <td class="total-col">{{ $t("totalText") }}</td>
        <td class="status-col">{{ $t("statusLabelText") }}</td>
      </tr>
      <!-- tabel body -->
      <tr
        v-for="orderList in orderListData"
        :key="orderList.id"
        class="table-body"
        @click="$emit('cardClick', orderList.id)"
      >
        <td class="id-col">{{ orderList.order_id }}</td>
        <td class="date-col">
          {{ moment(orderList.datetime).format("MMM DD, YYYY h:mm A") }}
        </td>
        <td class="restaurant-col">{{ orderList.restaurant.name }}</td>
        <td class="payment-col">{{ orderList.payment_method }}</td>
        <td class="order-type-col">{{ $t(orderList.order_type) }}</td>
        <td class="total-col">
          ${{ parseFloat(orderList.total_price).toFixed(2) }}
        </td>
        <td class="status-col">
          <p
            v-if="orderList.status === 'Pending'"
            class="status-badge pending-badge"
          >
            {{ orderList.status }}
          </p>
          <p
            v-if="orderList.status === 'Ongoing'"
            class="status-badge preparing-badge"
          >
            {{ orderList.status }}
          </p>
          <p
            v-if="orderList.status === 'Served'"
            class="status-badge served-badge"
          >
            {{ orderList.status }}
          </p>
          <p
            v-if="orderList.status === 'Cancel'"
            class="status-badge cancel-badge"
          >
            {{ orderList.status }}
          </p>
          <p
            v-if="orderList.status === 'Done'"
            class="status-badge done-badge"
          >
            {{ orderList.status }}
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "OrderListCard",
  data() {
    return {
      orderList: {
        status: "Preparing",
      },
    };
  },
  props: {
    orderListData: {
      type: Object,
      default: null,
    },
  },
  emits: ["cardClick"],
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  text-align: center;
}
table .id-col,
.payment-col,
.order-type-col,
.total-col,
.status-col {
  width: 10%;
  padding: 10px;
}
table .date-col {
  width: 20%;
  padding: 10px;
}
table .restaurant-col {
  width: 30%;
  padding: 10px;
}
.table-header {
  font-size: 18px;
  font-weight: 500;
  color: #006633;
  border-bottom: 1pt solid #eeeeee;
}
/* Set border-radius on the top-left and bottom-left of the first table data on the table row */
td:first-child,
th:first-child {
  border-radius: 12px 0 0 12px;
}

/* Set border-radius on the top-right and bottom-right of the last table data on the table row */
td:last-child,
th:last-child {
  border-radius: 0 12px 12px 0;
}
tr.table-body {
  background-color: rgba(238, 238, 238, 0.35);
}
table tr:hover:not(tr.table-header) {
  cursor: pointer;
  background-color: #eeeeee;
}
p.status-badge {
  margin: 0;
  padding: 5px 20px;
}
.status-badge {
  border-radius: 22px;
  color: #ffffff;
}
.pending-badge {
  background-color: #ffba00;
}
.cancel-badge {
  background-color: #FF5252;
}
.preparing-badge {
  background-color: #d198d0;
}
.served-badge {
  background-color: #35B2AE;
}
.done-badge {
  background-color:  #4CAF50;
}
</style>